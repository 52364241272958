import responseHandler from "../system/server-response-handler";
import {
  postRequest,
  getRequest,
  putRequest,
} from "../system/server-call";

const addPartnerCode = (data) => {
  return postRequest(`/api/v1/admin/marketing`, data).then(
    (response) => responseHandler.handleServerMessageResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const updatePartnerCode = (id, data) => {
  return putRequest(`/api/v1/admin/marketing/${id}`, data).then(
    (response) => responseHandler.handleServerMessageResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

// get promo codes list
const getPartnerCodes = () => {
  return getRequest(
    `/api/v1/admin/marketing`,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const getPartnerCodeById = (id) => {
  return getRequest(`/api/v1/admin/marketing/GetById/${id}`, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const toggleLinkActivation = (id) => {
  return putRequest(`/api/v1/admin/marketing/ToggleActivation/${id}`).then(
    (response) => responseHandler.handleServerMessageResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const getReports = (pageSize, pageIndex, filterExpression) => {
  return getRequest(
    `/api/v1/admin/marketing/GetReports?PageSize=${pageSize}&PageNumber=${pageIndex}&FilterExpression=${filterExpression}`,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};
// exporting functions for other files
export const marketingServices = {
  addPartnerCode,
  getPartnerCodes,
  updatePartnerCode,
  getPartnerCodeById,
  toggleLinkActivation,
  getReports,
};
