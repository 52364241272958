import React from "react";
import { NavLink } from "react-router-dom";
import ResetPasswordForm from "./ResetPasswordForm";

const ResetPassword = (props) => (
  <div className="account account--not-photo">
    <div className="account__wrapper">
      <div className="account__card b-clr-grey">
        <div className="account__head d-flex justify-content-center">
          <h3 className="account__title">
            <span className="account__logo d-flex justify-content-center">
              <span className="account__logo-accent">Forgot Password?</span>
            </span>
          </h3>
        </div>
        <ResetPasswordForm {...props} />
        <div className="account__have-account">
          <p>
            Remember your password? <NavLink to="/log_in">Login</NavLink>
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default ResetPassword;
