import { toast } from "react-toastify";
import { customToast } from "../../shared";
import { custom, failure } from "./utilities";
import { draftEventsServices } from "../../services";
import { globalSystemConstants, draftEventsConstants } from "../../constants";
import { configurationsActions } from "./configurations.actions";
import i18next from "i18next";

const {
  GET_DRAFT_EVENTS,
  CANCEL_DRAFT_EVENT,
  VIEW_DRAFT_EVENT_SHUTTERBS,
  ASSIGN_SHUTTERBS_TO_DRAFT_EVENT,
  UNASSIGN_SHUTTERBS_TO_DRAFT_EVENT,
  SEND_CART_LINK_TO_CUSTOMER,
} = draftEventsConstants;

const getDraftEvents = (pageSize, pageNumber, filterExpression) => {
  return (dispatch) => {
    dispatch(custom(GET_DRAFT_EVENTS, globalSystemConstants.SPINNER_ACTIVATE));
    draftEventsServices
      .getDraftEvents(pageSize, pageNumber, filterExpression)
      .then(
        (response) => {
          dispatch(custom(response, GET_DRAFT_EVENTS));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_DRAFT_EVENTS));
        }
      )
      .finally(() => {
        dispatch(
          custom(GET_DRAFT_EVENTS, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const cancelDraftEvent = (id, pageSize, pageNumber, filterExpression) => {
  return (dispatch) => {
    dispatch(
      custom(CANCEL_DRAFT_EVENT, globalSystemConstants.SPINNER_ACTIVATE)
    );
    draftEventsServices
      .cancelDraftEvent(id)
      .then(
        (response) => {
          if (response) {
            dispatch(custom(response, CANCEL_DRAFT_EVENT));
            dispatch(getDraftEvents(pageSize, pageNumber, filterExpression));
          }
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, CANCEL_DRAFT_EVENT));
        }
      )
      .finally(() => {
        dispatch(
          custom(CANCEL_DRAFT_EVENT, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const viewDraftEventShutterbs = (id) => {
  return (dispatch) => {
    dispatch(
      custom(VIEW_DRAFT_EVENT_SHUTTERBS, globalSystemConstants.SPINNER_ACTIVATE)
    );
    draftEventsServices
      .viewDraftEventShutterbs(id)
      .then(
        (response) => {
          if (response) {
            dispatch(custom(response, VIEW_DRAFT_EVENT_SHUTTERBS));
            dispatch(configurationsActions.getAllShutterBsForReels());
          }
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, VIEW_DRAFT_EVENT_SHUTTERBS));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            VIEW_DRAFT_EVENT_SHUTTERBS,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
};

const assignShutterbsToDraftEvent = (
  eventId,
  data,
  pageSize,
  pageNumber,
  filterExpression,
  closeModal
) => {
  return (dispatch) => {
    dispatch(
      custom(
        ASSIGN_SHUTTERBS_TO_DRAFT_EVENT,
        globalSystemConstants.SPINNER_ACTIVATE
      )
    );
    draftEventsServices
      .assignShutterbsToDraftEvent(eventId, data)
      .then(
        (response) => {
          if (data.suggestedSBz && data.suggestedSBz.length > 0) {
            customToast(
              i18next.t("shutterb_assigned_successfully"),
              toast.TYPE.SUCCESS
            );
          }
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, ASSIGN_SHUTTERBS_TO_DRAFT_EVENT));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            ASSIGN_SHUTTERBS_TO_DRAFT_EVENT,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
        dispatch(getDraftEvents(pageSize, pageNumber, filterExpression));
        closeModal();
      });
  };
};

const unAssignShutterbToDraftEvent = (
  id,
  pageSize,
  pageNumber,
  filterExpression,
  numRows,
  setNumRows
) => {
  return (dispatch) => {
    dispatch(
      custom(
        UNASSIGN_SHUTTERBS_TO_DRAFT_EVENT,
        globalSystemConstants.SPINNER_ACTIVATE
      )
    );
    draftEventsServices
      .unAssignShutterbToDraftEvent(id)
      .then(
        (response) => {
          if (response) {
            dispatch(custom(response, UNASSIGN_SHUTTERBS_TO_DRAFT_EVENT));
            if (response.items && response.items !== "") {
              customToast(i18next.t(response.items), toast.TYPE.SUCCESS);
            }
            if (numRows > 1) {
              setNumRows(numRows - 1);
            }
            dispatch(getDraftEvents(pageSize, pageNumber, filterExpression));
          }
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, UNASSIGN_SHUTTERBS_TO_DRAFT_EVENT));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            UNASSIGN_SHUTTERBS_TO_DRAFT_EVENT,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
};

const sendCartLinkToCustomer = (id, pageSize, pageNumber, filterExpression) => {
  return (dispatch) => {
    dispatch(
      custom(SEND_CART_LINK_TO_CUSTOMER, globalSystemConstants.SPINNER_ACTIVATE)
    );
    draftEventsServices
      .sendCartLinkToCustomer(id)
      .then(
        (response) => {
          if (response) {
            dispatch(custom(response, SEND_CART_LINK_TO_CUSTOMER));
            dispatch(getDraftEvents(pageSize, pageNumber, filterExpression));
          }
          customToast("Email sent to Customer.", toast.TYPE.SUCCESS);
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, SEND_CART_LINK_TO_CUSTOMER));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            SEND_CART_LINK_TO_CUSTOMER,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
};

export const draftEventsActions = {
  getDraftEvents,
  cancelDraftEvent,
  viewDraftEventShutterbs,
  assignShutterbsToDraftEvent,
  unAssignShutterbToDraftEvent,
  sendCartLinkToCustomer,
};
