import { updateObject } from "../../shared/utility";
import { shutterBsConstants } from "../../constants";

// getting the default actions name from defined constants via decomposition
const { GET_SHUTTER_BS, GET_SHUTTER_B_BY_ID, GET_SHUTTER_B_PORTFOLIO } =
  shutterBsConstants;

// default state
const initialState = {
  data: {},
  shutterbDetails: {},
  gallery: [],
  workingSchedules: null,
  blackoutDays: {
    displayDates: [],
    holidayDetails: [{ date: 0, dateTo: null, startTime: 0, endTime: 0 }],
  },
  profileImage: null,
};

// manupilating the state based on actions and returning the state
export const shutterBs = (state = initialState, action) => {
  switch (action.type) {
    case GET_SHUTTER_BS:
      return updateObject(state, {
        data: action.data,
      });
    case GET_SHUTTER_B_BY_ID:
      return updateObject(state, {
        shutterbDetails: action?.data?.items,
        gallery: action.data.items?.sbRegistrationImages,
        profileImage: action?.data?.items?.sbPortfolio?.profileImageUrl,
      });
    case GET_SHUTTER_B_PORTFOLIO:
      return updateObject(state, {
        workingSchedules: action?.data?.workingSchedules,
        blackoutDays: {
          displayDates: action?.data?.holidayProfile.map((holiday) => {
            return holiday.date * 1000;
          }),
          holidayDetails: action?.data?.holidayProfile.map((holiday) => {
            return {
              date: holiday.date,
              dateTo: holiday.dateTo,
              startTime: holiday.startTime,
              endTime: holiday.endTime,
            };
          }),
        },
        // blackoutDays: action?.data?.holidayProfile.map((holiday) => {
        //   return holiday.date * 1000;
        // }),
      });
    default:
      return state;
  }
};
