import React, { useEffect, useRef } from "react";
import { messageBoardActions } from "../../../redux/actions";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import { connect } from "react-redux";
import { startConnection } from "../../../system/signalRHOC";
import { useDispatch } from "react-redux";
import { custom } from "../../../redux/actions/utilities";
import { messageBoardConstants } from "../../../constants";

const MessageBoardView = (props) => {
  const messageBoardRef = useRef(null);
  const connection = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    const setupConnection = async () => {
      try {
        // Clean up previous connection and event listener
        if (connection.current) {
          connection.current.off("ReceiveMessage");
          await connection.current.stop();
        }

        // Establish the connection
        connection.current = await startConnection(
          props.formState.userId,
          props.formState.sbUserId,
          props.eventId
        );

        // Listen for incoming messages
        connection.current.on("ReceiveMessage", (message) => {
          // Dispatch an action to update your Redux state with the received message
          dispatch(custom(message, messageBoardConstants.RECEIVE_MESSAGES));
        });
      } catch (error) {
        console.error("Error setting up connection:", error);
      }
    };

    setupConnection();

    // Cleanup function
    return () => {
      if (connection.current) {
        connection.current.off("ReceiveMessage");
        connection.current.stop();
      }
    };
    // eslint-disable-next-line
  }, [dispatch, props.currentActiveId]);
  const scrollToBottom = () => {
    if (messageBoardRef.current) {
      const scrollHeight = messageBoardRef.current.scrollHeight;
      messageBoardRef.current.scrollTop = scrollHeight;
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [props.messages]);
  return (
    <>
      <div className="message-board">
        <div className="p-3 messaged-board-height" ref={messageBoardRef}>
          {props.messages && props.messages.length > 0 ? (
            props.messages.map((data) => {
              return data.sbuserId !== props.currentActiveId ||
                data.eventId !== props.eventId ? (
                ""
              ) : (
                <div
                  className={`${
                    data.sender === messageBoardConstants.HOST
                      ? "d-flex justify-content-end"
                      : "d-flex justify-content-start"
                  }`}
                  key={data.messageId} // Add a unique key for each message
                >
                  <p
                    className={`${
                      data.sender === messageBoardConstants.HOST
                        ? "sender-messages"
                        : "receiver-messages text-dark"
                    }`}
                  >
                    {data.message}
                  </p>
                </div>
              );
            })
          ) : (
            <h6 className="d-flex justify-content-center my-5 pt-5">
              No Conversation Yet ....
            </h6>
          )}
        </div>
      </div>
    </>
  );
};
// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  formState: state.messageBoard.formState,
  messages: state.messageBoard.messages,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  setFormState: (data) => dispatch(messageBoardActions.setFormState(data)),
  sendMessage: (data) => dispatch(messageBoardActions.sendMessage(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MessageBoardView));
