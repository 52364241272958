import React from "react";
import DatePicker from "react-multi-date-picker";

const DatePickerWrapper = (props) => {
  const {
    maxDate,
    minDate,
    isDateValid,
    selectedDate,
    setSelectedDate,
    setIsDateValid,
  } = props;
  return (
    <div
      className={`react-datepicker-wrapper ${!isDateValid ? " invalid" : ""}`}
    >
      <DatePicker
        value={selectedDate}
        onChange={(date) => {
          setSelectedDate(date);
          if (date) {
            setIsDateValid(true);
          }
        }}
        maxDate={maxDate ? maxDate : null}
        minDate={minDate ? minDate : null}
      />
    </div>
  );
};

export default DatePickerWrapper;
