import responseHandler from "../system/server-response-handler";
import { getRequest, postRequest, putRequest } from "../system/server-call";

// get payments list
const getPayments = (pageSize, pageIndex, searchExpression) => {
  // eslint-disable-next-line
  let queryParam = "?";
  if (pageSize) {
    queryParam += `PageSize=${pageSize}&`;
  }
  if (pageIndex) {
    queryParam += `PageNumber=${pageIndex}&`;
  }
  // eslint-disable-next-line no-param-reassign
  searchExpression =
    searchExpression || btoa("isActive==true || isActive==false");

  if (searchExpression) {
    queryParam += `filterExpression=${searchExpression}&`;
  }
  return getRequest(`/api/v1/admin/payment${queryParam}`, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const payShutterb = (orderId, id) => {
  // eslint-disable-next-line
  // integration is completed till this step, once confirmed the call status will move from here.
  return postRequest(`/api/Stripe/PayToShutterB/${orderId}/${id}`, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const getTaxAndFee = () => {
  return getRequest("/api/v1/admin/configuration", true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const updateTaxAndFee = (data) => {
  return putRequest(`/api/v1/admin/configuration/1`, data).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const getPaymentDetails = (orderId, shutterBId) => {
  return getRequest(
    `/Api/Stripe/GetSBOrderDetails/${orderId}/${shutterBId}`,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

// exporting functions for other files
export const paymentsServices = {
  getPayments,
  payShutterb,
  getTaxAndFee,
  updateTaxAndFee,
  getPaymentDetails,
};
