import React from "react";
import { withRouter } from "react-router";
import ChangePasswordForm from "./ChangePasswordForm";

const Register = () => (
  <div className="account d-block">
    <div className="account__wrapper d-flex justify-content-center">
      <div className="account__card b-clr-grey">
        <div className="account__head">
          <h2 className="account__title">
            <span className="account__logo">
              <span className="account__logo-accent"/>
            </span>
          </h2>
        </div>
        <ChangePasswordForm />
      </div>
    </div>
  </div>
);

export default withRouter(Register);
