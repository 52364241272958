import { toast } from "react-toastify";
import {
  customToast,
  calculateTotalFileSizeInMbs,
  convertBytesToMbs,
} from "../../shared";
import { custom, failure } from "./utilities";
import { eventsServices } from "../../services";
import { globalSystemConstants, eventsConstants } from "../../constants";
import { configurationsActions } from "./configurations.actions";
import i18next from "i18next";

const {
  GET_EVENTS,
  CANCEL_EVENT,
  VIEW_EVENT_SHUTTERBS,
  REASSIGN_SHUTTERBS,
  GET_EVENT_GALLERY,
  DOWNLOAD_GALLERY_DATA,
  DELETE_FROM_GALLERY,
  ADD_TO_GALLERY,
  GET_EVENT_DETAIL_BY_ID,
} = eventsConstants;

const getCompletedEvents = (pageSize, pageNumber, filterExpression) => {
  return (dispatch) => {
    dispatch(custom(GET_EVENTS, globalSystemConstants.SPINNER_ACTIVATE));
    eventsServices
      .getCompletedEvents(pageSize, pageNumber, filterExpression)
      .then(
        (response) => {
          dispatch(custom(response, GET_EVENTS));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_EVENTS));
        }
      )
      .finally(() => {
        dispatch(custom(GET_EVENTS, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
};
const getUpcomingEvents = (pageSize, pageNumber, filterExpression) => {
  return (dispatch) => {
    dispatch(custom(GET_EVENTS, globalSystemConstants.SPINNER_ACTIVATE));
    eventsServices
      .getUpcomingEvents(pageSize, pageNumber, filterExpression)
      .then(
        (response) => {
          dispatch(custom(response, GET_EVENTS));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_EVENTS));
        }
      )
      .finally(() => {
        dispatch(custom(GET_EVENTS, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
};
const getCanceledEvents = (pageSize, pageNumber, filterExpression) => {
  return (dispatch) => {
    dispatch(custom(GET_EVENTS, globalSystemConstants.SPINNER_ACTIVATE));
    eventsServices
      .getCanceledEvents(pageSize, pageNumber, filterExpression)
      .then(
        (response) => {
          dispatch(custom(response, GET_EVENTS));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_EVENTS));
        }
      )
      .finally(() => {
        dispatch(custom(GET_EVENTS, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
};

const cancelEvent = (id, pageSize, pageNumber, filterExpression) => {
  return (dispatch) => {
    dispatch(custom(CANCEL_EVENT, globalSystemConstants.SPINNER_ACTIVATE));
    eventsServices
      .cancelEvent(id)
      .then(
        (response) => {
          if (response && response.items !== "") {
            customToast(i18next.t(response.items), toast.TYPE.SUCCESS);
          }
          dispatch(getUpcomingEvents(pageSize, pageNumber, filterExpression));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, CANCEL_EVENT));
        }
      )
      .finally(() => {
        dispatch(
          custom(CANCEL_EVENT, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const viewEventShutterbs = (id) => {
  return (dispatch) => {
    dispatch(
      custom(VIEW_EVENT_SHUTTERBS, globalSystemConstants.SPINNER_ACTIVATE)
    );
    eventsServices
      .viewEventShutterbs(id)
      .then(
        (response) => {
          if (response) {
            dispatch(custom(response, VIEW_EVENT_SHUTTERBS));
            dispatch(configurationsActions.getAllShutterBsForReels());
          }
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, VIEW_EVENT_SHUTTERBS));
        }
      )
      .finally(() => {
        dispatch(
          custom(VIEW_EVENT_SHUTTERBS, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const resAssignShutterbs = (
  eventId,
  data,
  pageSize,
  pageNumber,
  filterExpression,
  closeModal
) => {
  return (dispatch) => {
    dispatch(
      custom(REASSIGN_SHUTTERBS, globalSystemConstants.SPINNER_ACTIVATE)
    );
    eventsServices
      .resAssignShutterbs(eventId, data)
      .then(
        (response) => {
          if (data.reassignSb && data.reassignSb.length > 0) {
            customToast(i18next.t(response), toast.TYPE.SUCCESS);
          }
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, REASSIGN_SHUTTERBS));
        }
      )
      .finally(() => {
        dispatch(
          custom(REASSIGN_SHUTTERBS, globalSystemConstants.SPINNER_DIACTIVATE)
        );
        dispatch(getUpcomingEvents(pageSize, pageNumber, filterExpression));
        closeModal();
      });
  };
};

const getEventGallery = (eventId, isActive) => {
  return (dispatch) => {
    dispatch(custom(GET_EVENT_GALLERY, globalSystemConstants.SPINNER_ACTIVATE));
    eventsServices
      .getEventGallery(eventId, isActive)
      .then(
        (response) => {
          dispatch(custom(response, GET_EVENT_GALLERY));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_EVENT_GALLERY));
        }
      )
      .finally(() => {
        dispatch(
          custom(GET_EVENT_GALLERY, globalSystemConstants.SPINNER_DIACTIVATE)
        );
        dispatch(
          custom(GET_EVENT_GALLERY, globalSystemConstants.OVERLAY_DIACTIVATE)
        );
      });
  };
};

const downloadSelectedFiles = (eventIdsArr, setDownloadedFiles) => {
  return (dispatch) => {
    dispatch(
      custom(DOWNLOAD_GALLERY_DATA, globalSystemConstants.SPINNER_ACTIVATE)
    );
    eventsServices
      .downloadSelectedFiles(eventIdsArr)
      .then(
        (message) => {
          toast.success(i18next.t("download_files_email"), {
            autoClose: 5000,
            hideProgressBar: true,
          });
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
        }
      )
      .finally(() => {
        dispatch(
          custom(
            DOWNLOAD_GALLERY_DATA,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
        dispatch(
          custom(
            DOWNLOAD_GALLERY_DATA,
            globalSystemConstants.DOWNLOAD_SPINNER_DIACTIVATE
          )
        );
      });
  };
};

const deleteFromGallery = (
  data,
  eventId,
  files,
  setFiles,
  selectedFiles,
  setSelectedFiles,
  isActive
) => {
  return (dispatch) => {
    dispatch(
      custom(DELETE_FROM_GALLERY, globalSystemConstants.SPINNER_ACTIVATE)
    );
    eventsServices
      .deleteFromGallery(data)
      .then(
        (response) => {
          dispatch(getEventGallery(eventId, isActive));
          setSelectedFiles([]);
          customToast(
            isActive === false
              ? "File Restored Successfully"
              : i18next.t("file_successfully_deleted"),
            toast.TYPE.SUCCESS
          );
        },
        (error) => {
          customToast(
            i18next.t(error[0] === undefined ? "undefined" : error[0]),
            toast.TYPE.ERROR
          );
          dispatch(failure(error, DELETE_FROM_GALLERY));
        }
      )
      .finally(() => {
        dispatch(
          custom(DELETE_FROM_GALLERY, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const addToGallery = (
  data,
  options,
  sbUserId = 0,
  counterFilesUploaded,
  setCounterFilesUploaded = null,
  totalUploadedFileSize = null,
  setTotalFileSizeToUpload = null,
  currentFilePrevUploadedSizeRef = null,
  setCurrentFileName = "",
  setCurrentFileSize = "",
  isActive
) => {
  const files = Array.from(data.entries()).filter(
    (entry) => entry[0] === "file" && entry[1] instanceof File
  );
  const extractedEventId = data.get("eventId");
  const extractedSbUserId = data.get("SbUserId");
  const extractedUserId = data.get("UserId");
  const contentType = data.get("contentType");

  const images = [];
  const videos = [];

  files.forEach((file) => {
    const fileObject = file[1];
    if (fileObject.type && fileObject.type.startsWith("video/")) {
      videos.push(fileObject);
    } else {
      images.push(fileObject);
    }
  });

  setTotalFileSizeToUpload(
    calculateTotalFileSizeInMbs(videos) + calculateTotalFileSizeInMbs(images)
  );

  return async (dispatch) => {
    try {
      // Make API calls for videos sequentially
      for (const video of videos) {
        currentFilePrevUploadedSizeRef.current = 0;
        setCurrentFileName(video.name);
        setCurrentFileSize(convertBytesToMbs(video.size).toFixed(2));
        const videoFormData = new FormData();
        videoFormData.append(`file`, video);
        videoFormData.append("eventId", extractedEventId);
        videoFormData.append("SbUserId", extractedSbUserId);
        videoFormData.append("UserId", extractedUserId);
        videoFormData.append("contentType", contentType);

        await eventsServices.addToGallery(videoFormData, options);

        if (counterFilesUploaded) {
          setCounterFilesUploaded((prevCount) => prevCount + 1);
        }
      }

      // Make API calls for images sequentially
      for (const image of images) {
        currentFilePrevUploadedSizeRef.current = 0;
        setCurrentFileName(image.name);
        setCurrentFileSize(convertBytesToMbs(image.size).toFixed(2));
        const imageFormData = new FormData();
        imageFormData.append(`file`, image);
        imageFormData.append("eventId", extractedEventId);
        imageFormData.append("SbUserId", extractedSbUserId);
        imageFormData.append("UserId", extractedUserId);
        imageFormData.append("contentType", contentType);

        await eventsServices.addToGallery(imageFormData, options);
        if (counterFilesUploaded) {
          setCounterFilesUploaded((prevCount) => prevCount + 1);
          totalUploadedFileSize += convertBytesToMbs(image.size);
        }
      }

      // Handle success for images and videos
      customToast("Added to gallery successfully", toast.TYPE.SUCCESS);
      // dispatch(success(imagesResponse, ADD_TO_GALLERY));

      const object = {};
      for (let [key, value] of data.entries()) {
        if (key === "SbUserId") {
          key = "sbUserId";
          value = parseInt(value);
        }
        if (key === "eventId") {
        }
        object[key] = value;
      }
      dispatch(getEventGallery(object.eventId, isActive));
    } catch (error) {
      console.error("Error in addToGallery:", error);
      Array.isArray(error) &&
        customToast(i18next.t(error[0]), toast.TYPE.ERROR);
      dispatch(failure(error, ADD_TO_GALLERY));

      const object = {};
      for (let [key, value] of data.entries()) {
        if (key === "SbUserId") {
          key = "sbUserId";
          value = parseInt(value);
        }
        if (key === "eventId") {
        }
        object[key] = value;
      }
      dispatch(getEventGallery(object.eventId, null, null));

      throw error;
    }
  };
};

const getEventDetailByEventId = (eventId) => {
  return (dispatch) => {
    dispatch(
      custom(GET_EVENT_DETAIL_BY_ID, globalSystemConstants.SPINNER_ACTIVATE)
    );
    eventsServices
      .getEventDetailByEventId(eventId)
      .then(
        (response) => {
          dispatch(custom(response, GET_EVENT_DETAIL_BY_ID));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_EVENT_DETAIL_BY_ID));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            GET_EVENT_DETAIL_BY_ID,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
};

export const eventsActions = {
  getCompletedEvents,
  getUpcomingEvents,
  getCanceledEvents,
  cancelEvent,
  viewEventShutterbs,
  resAssignShutterbs,
  getEventGallery,
  downloadSelectedFiles,
  deleteFromGallery,
  addToGallery,
  getEventDetailByEventId,
};
