import { system } from "./system.reducer";
import { authentication } from "./authentication.reducer";
import { shutterBs } from "./shutterBs.reducer";
import { events } from "./events.reducer";
import { payments } from "./payments.reducer";
import { configurations } from "./configurations.reducer";
import { customers } from "./customers.reducer";
import { reels } from "./reels.reducer";
import { draftEvents } from "./draftEvents.reducer";
import { marketing } from "./marketing.reducer";
import { messageBoard } from "./messageBoard.reducer";
import { monitoringReport } from "./monitoringReport.reducer";

export default {
  system,
  authentication,
  shutterBs,
  events,
  payments,
  configurations,
  customers,
  reels,
  draftEvents,
  marketing,
  messageBoard,
  monitoringReport,
};
