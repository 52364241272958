import axios from "axios";
import { authHeader } from "./auth-header";

const buildURL = (url) => {
  let BaseUrl = window.config.serverBaseURL;

  if (BaseUrl.slice(-1) === "/") {
    BaseUrl = window.config.serverBaseURL.slice(0, -1);
  }

  return BaseUrl + url;
};

const buildHeaders = (auth) => {
  let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  if (auth === true) {
    headers = { ...headers, ...authHeader() };
  }

  return headers;
};

const getRequest = (url, auth = true) => {
  const completeUrl = buildURL(url);

  const requestOptions = {
    method: "GET",
    headers: buildHeaders(auth),
  };

  axios.defaults.withCredentials = false;
  return axios(completeUrl, requestOptions);
};

const deleteRequest = (url, auth = true) => {
  const completeUrl = buildURL(url);

  const requestOptions = {
    method: "DELETE",
    headers: buildHeaders(auth),
  };

  axios.defaults.withCredentials = false;
  return axios(completeUrl, requestOptions);
};

const postRequest = (url, dataObj, auth = true) => {
  const completeUrl = buildURL(url);
  const requestOptions = {
    method: "POST",
    headers: buildHeaders(auth),
    data: dataObj,
  };
  axios.defaults.withCredentials = false;
  return axios(completeUrl, requestOptions);
};

const putRequest = (url, dataObj, auth = true) => {
  const completeUrl = buildURL(url);

  const requestOptions = {
    method: "PUT",
    headers: buildHeaders(auth),
    data: JSON.stringify(dataObj),
  };

  axios.defaults.withCredentials = false;
  return axios(completeUrl, requestOptions);
};

const deleteRequestWithPayload = (url, dataObj, auth = true) => {
  const completeUrl = buildURL(url);

  const requestOptions = {
    method: "DELETE",
    headers: buildHeaders(auth),
    data: dataObj,
  };

  axios.defaults.withCredentials = false;
  return axios(completeUrl, requestOptions);
};

const postRequestWithProgress = (url, dataObj, auth = true, options) => {
  const completeUrl = buildURL(url);
  const requestOptions = {
    method: "POST",
    headers: buildHeaders(auth),
    data: dataObj,
    onUploadProgress: options,
  };
  axios.defaults.withCredentials = false;

  return axios(completeUrl, requestOptions);
};

export {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
  deleteRequestWithPayload,
  postRequestWithProgress,
};
