import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";

const Spinner = (props) => {
    return props.isDownloadSpinnerActivate ? (
      <div className="spinner d-flex justify-content-center align-items-center ml-n3">
        <TailSpin
          color="#3e8993"
          ariaLabel="tail-spin-loading"
          radius="2"
          wrapperStyle={{}}
          wrapperClass="col-5 flex-zero"
          visible={true}
        />
        <div className="p-2 col-5">Preparing Download</div>
      </div>
    ) : null;
  };
  

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  isDownloadSpinnerActivate: state.system.isDownloadSpinnerActivate,
});

// mapping action and store the function via props
export default connect(mapStateToProps)(withRouter(Spinner));
