import { updateObject } from "../../shared/utility";
import { eventsConstants, galleryConstants } from "../../constants";

// getting the default actions name from defined constants via decomposition
const {
  GET_EVENTS,
  VIEW_EVENT_SHUTTERBS,
  GET_EVENT_GALLERY,
  GET_EVENT_DETAIL_BY_ID,
} = eventsConstants;

// default state
const initialState = {
  data: [],
  eventShutterbs: [],
  eventGalleryData: [],
  eventDetailById: []
};

// manupilating the state based on actions and returning the state
export const events = (state = initialState, action) => {
  switch (action.type) {
    case GET_EVENTS:
      return updateObject(state, {
        data: action.data,
      });
    case VIEW_EVENT_SHUTTERBS:
      return updateObject(state, {
        eventShutterbs: action.data.items,
      });
    case GET_EVENT_GALLERY:
      const data = action.data.items?.gallery;
      let updatedFileData;
      if (data) {
        const updatedFiles = data.map((file) => {
          return {
            ...file,
            name: file.imageUrl && file.imageUrl.slice(0, -4),
            imageUrl: `${process.env.REACT_APP_S3_BASE_URL_EVENTS_GALLERY}/${file.imageUrl}`,
            thumbnailUrl: `${process.env.REACT_APP_S3_BASE_URL_EVENTS_GALLERY}/${file.thumbnailUrl}`,
          };
        });
        // Create a new constant to hold the updated files
        updatedFileData = updatedFiles.map((file) => {
          let fileType;
          let galleryType;
          if (
            file.imageUrl.endsWith(".mp4") ||
            file.imageUrl.endsWith(".MP4")
          ) {
            fileType = "video/mp4";
            galleryType = galleryConstants.GALLERY_VIDEOS;
          } else if (
            file.imageUrl.endsWith(".mov") ||
            file.imageUrl.endsWith(".MOV")
          ) {
            fileType = "video/mov";
            galleryType = galleryConstants.GALLERY_VIDEOS;
          } else if (
            file.imageUrl.endsWith(".m4v") ||
            file.imageUrl.endsWith(".M4V")
          ) {
            fileType = "video/m4v";
            galleryType = galleryConstants.GALLERY_VIDEOS;
          } else {
            fileType = "image/jpeg";
            galleryType = galleryConstants.GALLERY_IMAGES;
          }
          return {
            file: new File([file], file.name, {
              type: fileType,
              lastModified: file.lastModified,
              lastModifiedDate: file.lastModifiedDate,
            }),
            url: file.imageUrl,
            thumbnailUrl: file.thumbnailUrl,
            selected: false,
            id: file?.id,
            isLocked: file?.isLocked,
            contentType: file?.contentType,
            galleryType:
              file?.contentType === galleryConstants.CONTENT_TYPE_REELS
                ? galleryConstants.GALLERY_REELS
                : galleryType,
          };
        });
      }
      return updateObject(state, {
        eventGalleryData: updatedFileData,
      });
    case GET_EVENT_DETAIL_BY_ID:
      return updateObject(state, {
        eventDetailById: action.data.items,
      });
    default:
      return state;
  }
};
