import React, { useState, useEffect, useCallback } from "react";
import { withRouter, useLocation } from "react-router";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import LoadingIcon from "mdi-react/LoadingIcon";
import { eventsActions, configurationsActions } from "../../redux/actions";
import {
  configurationsConstants,
  eventsConstants,
  eventsStatusConstants,
  menuUrlConstants,
  reelsConstants,
} from "../../constants";
import Modal from "react-modal";
import Select from "react-select";
import DataTable from "react-data-table-component";
import PaginationComponent from "../../shared/components/PaginationComponent/PaginationComponent";
import { toast } from "react-toastify";
import {
  customToast,
  getFilterExpression,
  convertDateToUTC,
} from "../../shared";
import ViewEventDetail from "./ViewEventDetail";
import MessageBoardModal from "./Modal/MessageBoardModal";
import { messageBoardActions } from "../../redux/actions";

const Events = (props) => {
  const spinner = useCallback(
    (type) => props.spinnerActions.findIndex((f) => f === type),
    [props.spinnerActions]
  );
  const { GET_EVENTS, CANCEL_EVENT, VIEW_EVENT_SHUTTERBS, REASSIGN_SHUTTERBS } =
    eventsConstants;

  const { t } = useTranslation("common");
  const location = useLocation();

  const [expandedCard, setExpandedCard] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isEventDetailModalOpen, setIsEventDetailModalOpen] = useState(false);
  const [eventId, setEventId] = useState(null);
  const [pagination, setPagination] = useState({
    pageSize: configurationsConstants.DEFAULT_PAGINATION_PAGE_SIZE,
    pageNumber: 1,
    totalRows: 0,
  });
  const [filterOptionValue, setFilterOptionValue] = useState("");
  const filterOptions = ["Id", "EventName", "EventDate", "ShutterbNames"];
  const [date, setDate] = useState();
  const [currentEvent, setCurrentEvent] = useState(null);
  const [isViewDetail, setIsViewDetail] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);

  const handleCollapseToggle = (cardId) => {
    setExpandedCard(cardId === expandedCard ? null : cardId);
  };

  const token = sessionStorage.getItem("payload");
  const encodedToken = encodeURIComponent(token);
  const url = `/event_booking?token=${encodedToken}`;

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    // Check if the hash in the URL is "#sharedGallery"
    if (location.hash !== "#sharedGallery") {
      setIsViewDetail(false);
    }
  }, [location.hash]);

  const handleViewDetail = (id) => {
    setCurrentEvent(id);
    setIsViewDetail(true);
  };

  const handleCloseViewDetail = () => {
    setIsViewDetail(false);
  };

  const handleCancelEvent = (id, pageSize, pageNumber, filterExpression) => {
    if (window.confirm("Are you sure ? "))
      props.cancelEvent(id, pageSize, pageNumber, filterExpression);
  };

  const handleMessageBoard = (eventId) => {
    setCurrentEvent(eventId);
    setIsMessageModalOpen(true);
  };
  const columns = [
    {
      width: "100%",
      cell: (row) => (
        <React.Fragment key={row.id}>
          <div className="card card-style pagination-card-width mb-2">
            <div className="card-header back-color">
              <p className="mb-0">
                <a
                  href={`#${row.id}`}
                  className="collapsed d-flex justify-content-between a-style"
                  onClick={() => handleCollapseToggle(row.id)}
                >
                  <div className="p-0 m-0">
                    <strong>{`${t("event_name")}:`} </strong> {row.eventName}
                    <strong>{` | ${t("event_host")}`} </strong> {row.eventHost}{" "}
                    <strong>({row.eventHostEmail})</strong>
                    <strong>{` | ${t("event_date")}`} </strong>
                    {convertDateToUTC(row.eventDate)}
                  </div>
                  <strong> {`${t("id")} ${row.id}`}</strong>
                </a>
              </p>
            </div>
            <div
              id={`collapse${row.id}`}
              className={`collapse ${expandedCard === row.id ? "show" : ""}`}
            >
              <div className="card-body">
                {[
                  { label: t("event_host"), value: row.eventHost },
                  { label: t("event_host_email"), value: row.eventHostEmail },
                  {
                    label: t("event_date"),
                    value: (() => {
                      const epochTimestamp = row.eventDate;
                      const date = new Date(epochTimestamp * 1000);

                      const year = date.getUTCFullYear();
                      const month = date.getUTCMonth() + 1; // Adding 1 since months are 0-indexed
                      const day = date.getUTCDate();

                      return `${month}/${day}/${year}`;
                    })(),
                  },
                  {
                    label: t("event_start_time"),
                    value: new Date(
                      row.eventStartTime * 1000
                    ).toLocaleTimeString(),
                  },
                  {
                    label: t("event_location"),
                    value: row.currentLocation,
                  },
                  {
                    label: t("duration"),
                    value: row.duration,
                  },
                  {
                    label: t("sb_start_time"),
                    value: new Date(
                      row.sbstartTime * 1000
                    ).toLocaleTimeString(),
                  },
                  {
                    label: t("event_type"),
                    value: row.eventTypeName,
                  },
                  {
                    label: t("appartment"),
                    value: row.apartment,
                  },
                  {
                    label: t("additional_notes"),
                    value: row.additionalNotes,
                  },
                  row.contactName &&
                    row.contactName !== "" && {
                      label: t("contact_name"),
                      value: row.contactName,
                    },
                  row.phoneNumber &&
                    row.phoneNumber !== "" && {
                      label: t("contact_number"),
                      value: row.phoneNumber,
                    },
                  {
                    label: t("total_gallery_views"),
                    value: row.totalGalleryViews,
                  },
                  {
                    label: t("consent_for_marketing"),
                    value: row.isConsentForMarketing ? "True" : "False",
                  },
                  {
                    label: t("gallery_shared_permission"),
                    value: row.isGallerySharedPermission ? "True" : "False",
                  },
                  {
                    label: t("Assignee"),
                    value: row.shutterbNames,
                  },
                ]
                  .filter(Boolean)
                  .map(({ label, value }) => (
                    <div className="d-flex" key={label}>
                      <p className="text-left w-25 fw-bold">{label}</p>
                      <p className="w-75">{value}</p>
                    </div>
                  ))}
                <button
                  disabled={
                    !row.isActive ||
                    !location.pathname.includes(
                      menuUrlConstants.UPCOMING_EVENTS_TAB
                    )
                  }
                  type="submit"
                  className="btn btn-info"
                  onClick={() =>
                    handleCancelEvent(
                      row.id,
                      pagination.pageSize,
                      pagination.pageNumber,
                      getFilterExpression(searchText)
                    )
                  }
                >
                  {t("cancel_event")}
                </button>
                <button
                  disabled={!row.isActive}
                  type="submit"
                  className="btn btn-info"
                  onClick={() => {
                    openModal();
                    props.viewEventShutterbs(row.id);
                    setEventId(row.id);
                  }}
                >
                  {t("Reassign shutterb")}
                </button>
                <button
                  type="submit"
                  className="btn btn-info"
                  onClick={() => {
                    props.getEventDetailByEventId(row.id);
                    setIsEventDetailModalOpen(true);
                    setEventId(row.id);
                  }}
                >
                  {t("Event Details")}
                </button>
                <button
                  disabled={!row.isActive}
                  type="submit"
                  className="btn btn-info"
                  onClick={() => {
                    handleViewDetail(row.id);
                    window.location.hash = "sharedGallery";
                  }}
                >
                  {t("view_gallery")}
                </button>
                {row.isReelExist === 1 && (
                  <button
                    disabled={!row.isActive}
                    type="submit"
                    className="btn btn-info"
                    onClick={() => {
                      props.setIsReelDetailModalOpen();
                      props.setReelDetailModalEventId(row.id);
                    }}
                  >
                    {t("View Recap Details")}
                  </button>
                )}
                <button
                  type="submit"
                  className="btn btn-info"
                  onClick={() => {
                    handleMessageBoard(row.id);
                  }}
                >
                  {t("see_messages")}
                </button>
              </div>
            </div>
          </div>
        </React.Fragment>
      ),
      allowOverflow: true,
      button: true,
    },
  ];
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
      },
    },
    rows: {
      style: {
        "&:not(:last-of-type)": {
          borderBottomWidth: "0px",
        },
      },
    },
  };

  useEffect(() => {
    if (location.pathname.includes(menuUrlConstants.COMPLETED_EVENTS_TAB)) {
      props.getCompletedEvents(
        pagination.pageSize,
        pagination.pageNumber,
        getFilterExpression(searchText)
      );
    } else if (
      location.pathname.includes(menuUrlConstants.UPCOMING_EVENTS_TAB)
    ) {
      props.getUpcomingEvents(
        pagination.pageSize,
        pagination.pageNumber,
        getFilterExpression(searchText)
      );
    } else if (
      location.pathname.includes(menuUrlConstants.CANCELED_EVENTS_TAB)
    ) {
      props.getCanceledEvents(
        pagination.pageSize,
        pagination.pageNumber,
        getFilterExpression(searchText)
      );
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPagination({
      pageSize: props.data.pageSize,
      pageNumber: props.data.currentPage,
      totalRows: props.data.total,
    });
  }, [props.data]);

  const userOptions = props.allShutterbs?.map((user) => ({
    value: user.id,
    label: `${user.firstName} ${user.lastName} - ${user.email}`,
  }));

  const handleUserSelection = (selectedOptions, index) => {
    setSelectedUsers((prevSelectedUsers) => {
      const updatedSelectedUsers = [...prevSelectedUsers];
      updatedSelectedUsers[index] = selectedOptions;
      return updatedSelectedUsers;
    });
  };

  useEffect(() => {
    const defaultSelectedShutterb = props.eventShutterbs.flatMap(
      (shutterb) => ({
        value: shutterb.oldSBUserId,
        label: `${shutterb.sbName} - ${shutterb.email}`,
      })
    );
    setSelectedUsers(defaultSelectedShutterb);
    // eslint-disable-next-line
  }, [props.eventShutterbs]);

  const handleAssignShutterB = () => {
    const dataToSend = props.eventShutterbs.map((shutterb, index) => {
      const selectedUser = selectedUsers[index];

      return {
        orderItemId: shutterb.orderItemId,
        oldSBUserId: shutterb.oldSBUserId,
        newSBUserId: selectedUser ? selectedUser.value : shutterb.newSBUserId,
        status: "created",
      };
    });

    const filteredDataToSend = dataToSend.filter(
      (item) => item.oldSBUserId !== item.newSBUserId
    );
    if (filteredDataToSend.length > 0) {
      props.resAssignShutterbs(
        eventId,
        { reassignSb: filteredDataToSend },
        pagination.pageSize,
        pagination.pageNumber,
        getFilterExpression(searchText),
        closeModal
      );
    } else {
      closeModal();
    }
  };
  return (
    <div className="row mr-0">
      <div className="col-md-12">
        <div className="col-lg-12">
          <div className="tab-content">
            {isViewDetail ? (
              <ViewEventDetail
                onClose={handleCloseViewDetail}
                currentEvent={currentEvent}
              />
            ) : (
              <div className="tab-pane fade show active">
                <button
                  type="button"
                  className="btn btn-info"
                  onClick={() => {
                    window.location.href = `${process.env.REACT_APP_HOST_URL}${url}`;
                  }}
                >
                  {t("Book now")}
                </button>
                {spinner(GET_EVENTS) && spinner(CANCEL_EVENT) ? (
                  <div>
                    <PaginationComponent
                      pagination={pagination}
                      setPagination={setPagination}
                      searchText={searchText}
                      setSearchText={setSearchText}
                      getTableData={
                        location.pathname.includes(
                          menuUrlConstants.COMPLETED_EVENTS_TAB
                        )
                          ? props.getCompletedEvents
                          : location.pathname.includes(
                              menuUrlConstants.UPCOMING_EVENTS_TAB
                            )
                          ? props.getUpcomingEvents
                          : props.getCanceledEvents
                      }
                      filterOptionValue={filterOptionValue}
                      setFilterOptionValue={setFilterOptionValue}
                      filterOptions={filterOptions}
                      isDateIncluded
                      date={date}
                      setDate={setDate}
                    />
                    <DataTable
                      columns={columns}
                      data={props.data.items}
                      responsive
                      customStyles={customStyles}
                      className="no-scroll"
                    />
                  </div>
                ) : (
                  <div className="text-center mt-4">
                    <LoadingIcon className="loading-spinner" />
                  </div>
                )}
                <Modal
                  isOpen={modalIsOpen}
                  onRequestClose={closeModal}
                  contentLabel="Set Shutterbs Modal"
                  className="image-modal"
                  style={{
                    content: {
                      width: "auto",
                      height: "auto",
                      margin: "auto",
                    },
                  }}
                >
                  <div className="modal-content">
                    <h3 className="d-flex justify-content-center">
                      {t("Reassign shutterbs")}
                    </h3>
                    {spinner(VIEW_EVENT_SHUTTERBS) &&
                    spinner(REASSIGN_SHUTTERBS) ? (
                      <>
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">Price</th>
                              <th scope="col">Status</th>
                              <th scope="col">Assignee</th>
                            </tr>
                          </thead>
                          <tbody>
                            {props.eventShutterbs &&
                              props.eventShutterbs?.map((orderItem, index) => (
                                <tr key={index}>
                                  <td className="pt-custom">
                                    ${orderItem.price}
                                  </td>
                                  <td className="pt-custom">
                                    {orderItem.status ===
                                    eventsStatusConstants.CREATED
                                      ? eventsStatusConstants.PENDING
                                      : orderItem.presenceStatus ===
                                        eventsStatusConstants.CHECKOUT
                                      ? orderItem.presenceStatus
                                      : orderItem.status}
                                  </td>
                                  <td>
                                    <Select
                                      options={userOptions}
                                      value={
                                        selectedUsers &&
                                        selectedUsers.length > 0
                                          ? selectedUsers[index]
                                          : null
                                      }
                                      isDisabled={
                                        orderItem.presenceStatus ===
                                        eventsStatusConstants.CHECKOUT
                                      }
                                      onChange={(selectedOptions) => {
                                        const selectedValue =
                                          selectedOptions?.value;
                                        const matchingUserIndex =
                                          selectedUsers.findIndex(
                                            (user) =>
                                              user.value === selectedValue
                                          );
                                        if (matchingUserIndex === -1) {
                                          handleUserSelection(
                                            selectedOptions,
                                            index
                                          );
                                        } else if (
                                          matchingUserIndex !== index
                                        ) {
                                          customToast(
                                            t(
                                              "shutterb already assigned for this event"
                                            ),
                                            toast.TYPE.ERROR
                                          );
                                        }
                                      }}
                                    />
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        <button
                          type="submit"
                          className="btn btn-primary w-100"
                          onClick={handleAssignShutterB}
                        >
                          {t("Assign")}
                        </button>
                        <button onClick={closeModal} className="modal-close">
                          <img
                            src="/images/simpleCrossWhite.svg"
                            alt="crossIcon"
                            className="modal-close-img"
                          />
                        </button>
                      </>
                    ) : (
                      <div className="text-center mt-4 h-150">
                        <LoadingIcon className="loading-spinner" />
                      </div>
                    )}
                  </div>
                </Modal>

                <Modal
                  isOpen={isEventDetailModalOpen}
                  onRequestClose={setIsEventDetailModalOpen}
                  contentLabel="Event Details"
                  className="reel-detail-con"
                >
                  <div className="main-con">
                    <div className="row w-100">
                      {props.eventDetailById.length <= 0 ? (
                        <h4 className="d-flex justify-content-center align-items-center pl-5">
                          No Detail Exists ...
                        </h4>
                      ) : (
                        <table>
                          <thead>
                            <tr>
                              <td colSpan="12">Event Details</td>
                            </tr>
                            <tr>
                              <td colSpan="12"> {eventId}</td>
                            </tr>
                          </thead>
                          <tbody>
                            {props.eventDetailById?.map((item) => {
                              return (
                                <>
                                  <tr>
                                    <th>{item.questionType.description}:</th>
                                    <td>{item.answer}</td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                        </table>
                      )}
                    </div>
                    <button
                      onClick={() => setIsEventDetailModalOpen(false)}
                      className="modal-close"
                    >
                      <img
                        src="/images/simpleCrossWhite.svg"
                        alt="crossIcon"
                        className="modal-close-img"
                      />
                    </button>
                  </div>
                </Modal>
              </div>
            )}
          </div>
        </div>
      </div>
      {isMessageModalOpen ? (
        <MessageBoardModal
          isModalOpen={isMessageModalOpen}
          setIsModalOpen={setIsMessageModalOpen}
          className="message-board-modal"
          currentEvent={currentEvent}
        />
      ) : (
        ""
      )}
    </div>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  data: state.events.data,
  spinnerActions: state.system.spinnerActions,
  allShutterbs: state.configurations.allShutterbs,
  eventShutterbs: state.events.eventShutterbs,
  eventDetailById: state.events.eventDetailById,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getCompletedEvents: (pageSize, pageNumber, filterExpression) =>
    dispatch(
      eventsActions.getCompletedEvents(pageSize, pageNumber, filterExpression)
    ),
  getUpcomingEvents: (pageSize, pageNumber, filterExpression) =>
    dispatch(
      eventsActions.getUpcomingEvents(pageSize, pageNumber, filterExpression)
    ),
  getCanceledEvents: (pageSize, pageNumber, filterExpression) =>
    dispatch(
      eventsActions.getCanceledEvents(pageSize, pageNumber, filterExpression)
    ),
  cancelEvent: (id, pageSize, pageNumber, filterExpression) =>
    dispatch(
      eventsActions.cancelEvent(id, pageSize, pageNumber, filterExpression)
    ),
  getAllShutterBsForReels: () =>
    dispatch(configurationsActions.getAllShutterBsForReels()),
  viewEventShutterbs: (id) => dispatch(eventsActions.viewEventShutterbs(id)),
  resAssignShutterbs: (
    eventId,
    data,
    pageSize,
    pageNumber,
    filterExpression,
    closeModal
  ) =>
    dispatch(
      eventsActions.resAssignShutterbs(
        eventId,
        data,
        pageSize,
        pageNumber,
        filterExpression,
        closeModal
      )
    ),
  setIsReelDetailModalOpen: () =>
    dispatch({
      type: reelsConstants.SET_IS_REEL_DETAIL_MODAL_OPEN,
    }),
  setReelDetailModalEventId: (eventId) =>
    dispatch({
      type: reelsConstants.SET_REEL_DETAIL_MODAL_EVENT_ID,
      data: eventId,
    }),
  getEventDetailByEventId: (eventId) =>
    dispatch(eventsActions.getEventDetailByEventId(eventId)),
  getshutterbzByEventId: (eventId) =>
    dispatch(messageBoardActions.getshutterbByEventId(eventId)),
});

// Mapping action and store the function via props
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Events));
