import resources from "./resources";
import { getItemFromStorage } from "../system";

export const config = {
    interpolation: { escapeValue: false }, // React already does escaping
    lng: getItemFromStorage("locale") || window.config.defaultLanguage.value,
    defaultNS: "common",
    resources,
};

export { resources };
