import { connect } from "react-redux";
import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import LoadingIcon from "mdi-react/LoadingIcon";
import { configurationsConstants } from "../../../constants";
import { configurationsActions } from "../../../redux/actions";
import PromoCodeForm from "../Forms/PromoCodeForm";
// eslint-disable-next-line
import { getFilterExpression } from "../../../shared";
import PaginationComponent from "../../../shared/components/PaginationComponent/PaginationComponent";

const { GET_PROMO_CODES_DATA, ACTIVATE_DEACTIVATE_PROMOCODE } =
  configurationsConstants;

const PromoCodesPage = (props) => {
  // eslint-disable-next-line
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({
    pageSize: configurationsActions.DEFAULT_PAGINATION_PAGE_SIZE,
    currentPage: 1,
    totalRows: 0,
  });
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [filterOptionValue, setFilterOptionValue] = useState("");
  const [rowId, setRowId] = useState(0);
  const filterOptions = ["code", "type"];

  const spinner = useCallback(
    (type) => props.spinnerActions.findIndex((f) => f === type),
    [props.spinnerActions]
  );

  const handleCloseForm = () => {
    setIsFormOpen(false);
    props.getPromoCode(
      pagination.pageSize,
      pagination.currentPage,
      getFilterExpression(searchText)
    );
  };
  const { t } = useTranslation("common");
  const columns = [
    {
      name: t("code"),
      selector: "code",
      sortable: true,
      width: "150px",
      cell: (row) => <div className="word-wrap">{row.code}</div>,
    },
    {
      name: t("description"),
      selector: "description",
      sortable: true,
      width: "200px",
      cell: (row) => <div className="word-wrap">{row.description}</div>,
    },
    {
      name: t("value"),
      selector: "value",
      sortable: true,
      width: "150px",
    },
    {
      name: t("type"),
      selector: "type",
      sortable: true,
      width: "80px",
    },
    {
      name: t("start_date"),
      selector: "startDate",
      sortable: true,
      width: "130px",
      cell: (row) => {
        const epoch = row?.startDate * 1000;
        return (
          new Date(epoch).toLocaleDateString() +
          " " +
          new Date(epoch).toLocaleTimeString()
        );
      },
    },
    {
      name: t("end_date"),
      selector: "endDate",
      sortable: true,
      width: "130px",
      cell: (row) => {
        const epoch = row?.endDate * 1000;
        return (
          new Date(epoch).toLocaleDateString() +
          " " +
          new Date(epoch).toLocaleTimeString()
        );
      },
    },
    {
      name: t("number_of_redemptions"),
      selector: "numberOfRedemptions",
      sortable: true,
      width: "190px",
    },
    {
      name: t("actions"),
      width: "170px",
      cell: (row) => (
        <div>
          <button
            type="button"
            className="payment-button mr-2"
            onClick={() => {
              props.activateDeactivateCode(row.id, row?.isActive);
            }}
          >
            {row?.isActive ? t("Deactivate") : t("Activate")}
          </button>

          <button
            type="button"
            className="payment-button mr-2"
            onClick={() => {
              props.getPromoCodeById(row.id);
              setRowId(row.id);
              setIsFormOpen(true);
            }}
          >
            Edit
          </button>
        </div>
      ),
      allowOverflow: true,
      button: true,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
      },
    },
  };

  useEffect(() => {
    props.getPromoCode(
      pagination.pageSize,
      pagination.pageNumber,
      getFilterExpression(searchText)
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPagination({
      pageSize: props.promoCodes?.pageSize,
      pageNumber: props.promoCodes?.currentPage,
      totalRows: props.promoCodes?.total,
    });
  }, [props.promoCodes]);

  return (
    <div className="row mr-0">
      <div className="col-md-12">
        <div className="col-md-12 mx-0">
          <div className="tab-content">
            {spinner(GET_PROMO_CODES_DATA) &&
            spinner(ACTIVATE_DEACTIVATE_PROMOCODE) ? (
              <div className="tab-pane fade show active">
                {isFormOpen ? (
                  <PromoCodeForm rowId={rowId} onClose={handleCloseForm} />
                ) : (
                  <>
                    <button
                      type="submit"
                      className="btn btn-info ml-2"
                      onClick={() => {
                        setRowId(0);
                        setIsFormOpen(true);
                      }}
                    >
                      Add Promo Code
                    </button>
                    <div className="pagination-controls">
                      <PaginationComponent
                        pagination={pagination}
                        setPagination={setPagination}
                        searchText={searchText}
                        setSearchText={setSearchText}
                        getTableData={props.getPromoCode}
                        filterOptionValue={filterOptionValue}
                        setFilterOptionValue={setFilterOptionValue}
                        filterOptions={filterOptions}
                        isTable
                      />
                    </div>
                    <DataTable
                      columns={columns}
                      data={props.promoCodes.items}
                      highlightOnHover
                      responsive
                      customStyles={customStyles}
                      className="sticky-header-class"
                    />
                  </>
                )}
              </div>
            ) : (
              <div className="text-center mt-4">
                <LoadingIcon className="loading-spinner" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  promoCodes: state.configurations.data,
  spinnerActions: state.system.spinnerActions,
  promoCodeById: state.configurations.availablePostalCodeById,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  addPromoCode: (data) => dispatch(configurationsActions.addPromoCode(data)),
  getPromoCode: (pageSize, pageIndex, filterExpression) =>
    dispatch(
      configurationsActions.getPromoCode(pageSize, pageIndex, filterExpression)
    ),
  getPromoCodeById: (id) =>
    dispatch(configurationsActions.getPromoCodeById(id)),
  activateDeactivateCode: (id, isActive) =>
    dispatch(configurationsActions.activateDeactivateCode(id, isActive)),
});

// Mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PromoCodesPage));
