// eslint-disable-next-line import/no-cycle
import responseHandler from "../system/server-response-handler";
import { getRequest, putRequest } from "../system/server-call";

// get shutter Bs list
const getShutterBs = (pageSize, pageNumber, filteredExpressionAsPerTab) => {
  return getRequest(
    `/api/v1/admin/ShutterB?PageSize=${pageSize}&PageNumber=${pageNumber}&FilterExpression=${filteredExpressionAsPerTab}`,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

// get shutter Bs list
const getAllShutterBsForReels = () => {
  return getRequest("/api/v1/admin/reel/GetShutterBForReels", true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const approveShutterB = (data) => {
  return putRequest(
    `/api/v1/admin/Shutterb/UpdateShuttterBStatus/${data.id}`,
    data
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const enableDisableShutterB = (data) => {
  return putRequest(`/api/v1/admin/Shutterb/${data.id}`, data).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const updateHourlyRate = (data) => {
  return putRequest(
    `/api/v1/admin/ShutterB/sethourlyrate/${data.sbUserId}`,
    data
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const getShutterbById = (id) => {
  return getRequest(`/api/v1/admin/shutterb/${id}`, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const getShutterbPortfolio = (id) => {
  return getRequest(`/api/v1/admin/ShutterB/GetSBScheduleById/${id}`, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

// exporting functions for other files
export const shutterBsServices = {
  getShutterBs,
  approveShutterB,
  updateHourlyRate,
  enableDisableShutterB,
  getShutterbById,
  getAllShutterBsForReels,
  getShutterbPortfolio
};
