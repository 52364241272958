import { connect } from "react-redux";
import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router";
import { useTranslation } from "react-i18next";
import LoadingIcon from "mdi-react/LoadingIcon";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { configurationsConstants } from "../../../constants";
import { configurationsActions } from "../../../redux/actions";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const {
  GET_SMARTPHONE_DEVICES,
  ADD_SMARTPHONE_DEVICE,
  REMOVE_SMARTPHONE_DEVICE,
} = configurationsConstants;

const SmartPhoneDevices = (props) => {
  // eslint-disable-next-line
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({
    rowsPerPage: configurationsConstants.DEFAULT_PAGINATION_PAGE_SIZE,
    currentPage: 1,
    totalRows: 0,
  });
  const [data, setData] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const { t } = useTranslation("common");

  const spinner = useCallback(
    (type) => props.spinnerActions.findIndex((f) => f === type),
    [props.spinnerActions]
  );

  // eslint-disable-next-line
  const [draggedIndex, setDraggedIndex] = useState(null);

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedData = [...data];
    const [draggedItem] = reorderedData.splice(result.source.index, 1);
    reorderedData.splice(result.destination.index, 0, draggedItem);

    setData(reorderedData);
    setDraggedIndex(result.destination.index);

    // Now you can send the reordered data to the server if needed
    const dataToSendArray = reorderedData.map((item, index) => {
      return {
        ...item,
        displayOrder: index + 1,
      };
    });

    const dataToSend = {
      devicesOrder: dataToSendArray,
    };
    props.reorderSmartPhoneDevices(
      pagination.rowsPerPage,
      pagination.currentPage,
      searchText,
      setData,
      dataToSend,
      setDraggedIndex
    );
  };

  useEffect(() => {
    props.getSmartPhoneDevices(
      pagination.rowsPerPage,
      pagination.currentPage,
      searchText,
      setData
    );
    setPagination((prevPagination) => ({
      ...prevPagination,
      totalRows: props.configurations.total,
    }));
    // eslint-disable-next-line
  }, [pagination.currentPage, pagination.rowsPerPage]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleButtonClick = () => {
    const dataToSend = {
      deviceName: inputValue,
    };
    props.addSmartPhoneDevice(
      pagination.rowsPerPage,
      pagination.currentPage,
      searchText,
      setData,
      dataToSend
    );
    setInputValue("");
  };

  return (
    <div className="row mr-0">
      <div className="col-md-12">
        <div className="col-lg-12">
          <div className="tab-content">
            {spinner(GET_SMARTPHONE_DEVICES) &&
            spinner(REMOVE_SMARTPHONE_DEVICE) &&
            spinner(ADD_SMARTPHONE_DEVICE) ? (
              <div className="tab-pane fade show active">
                <AvForm onValidSubmit={handleButtonClick}>
                  <div className="custom-label-color">
                    <label htmlFor="taxValue">{t("device_name")}</label>
                    <div className="input-group">
                      <AvField
                        name="smartphone"
                        type="text"
                        maxLength="50"
                        className="form-control mr-2"
                        value={inputValue}
                        onChange={handleInputChange}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Value is required",
                          },
                        }}
                      />
                      <div className="input-group-append ml-3">
                        <button
                          type="submit"
                          className="btn btn-info d-flex align-items-center justify-content-center border-radius-5"
                        >
                          {t("add")}
                        </button>
                      </div>
                    </div>
                  </div>
                </AvForm>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable
                    droppableId="smartphone-devices"
                    direction="vertical"
                  >
                    {(provided) => (
                      <table
                        className="drag-drop-table-class"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <thead
                          className="drag-drop-table-head"
                          style={{ minWidth: "1060px" }}
                        >
                          <tr
                            className="d-flex align-items-center"
                            style={{ height: "60px" }}
                          >
                            <th
                              className="drag-drop-head-cell d-flex justify-content-start"
                              style={{
                                minWidth: "50px",
                                maxWidth: "50px",
                              }}
                            ></th>
                            <th
                              className="drag-drop-head-cell d-flex justify-content-start"
                              style={{
                                minWidth: "400px",
                                maxWidth: "400px",
                              }}
                            >
                              {t("device_name")}
                            </th>
                            <th
                              className="drag-drop-head-cell d-flex justify-content-center"
                              style={{
                                minWidth: "610px",
                                maxWidth: "610px",
                              }}
                            >
                              {t("actions")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.map((row, index) => (
                            <Draggable
                              key={row.id.toString()}
                              draggableId={row.id.toString()}
                              index={index}
                            >
                              {(provided) => (
                                <tr
                                  className="d-flex align-items-center table-tr-style"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <td className="td-style justify-content-start">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      width="50"
                                      height="20"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    >
                                      <path d="M12 2v20M7 7l5-5 5 5M7 17l5 5 5-5" />
                                    </svg>
                                  </td>
                                  <td className="td-device-style">
                                    <div
                                      draggable="true"
                                      style={{
                                        cursor: "grab",
                                        fontSize: "13px",
                                        fontWeight: "400",
                                      }}
                                    >
                                      {row.deviceName}
                                    </div>
                                  </td>
                                  <td className="td-btn-style">
                                    <button
                                      type="button"
                                      className="payment-button"
                                      onClick={() => {
                                        props.removeSmartPhoneDevice(
                                          pagination.rowsPerPage,
                                          pagination.currentPage,
                                          searchText,
                                          setData,
                                          row.id
                                        );
                                      }}
                                    >
                                      Remove
                                    </button>
                                  </td>
                                </tr>
                              )}
                            </Draggable>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            ) : (
              <div className="text-center mt-4">
                <LoadingIcon className="loading-spinner" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  configurations: state.configurations,
  spinnerActions: state.system.spinnerActions,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getSmartPhoneDevices: (pageSize, pageIndex, searchExpression, setData) =>
    dispatch(
      configurationsActions.getSmartPhoneDevices(
        pageSize,
        pageIndex,
        searchExpression,
        setData
      )
    ),
  addSmartPhoneDevice: (
    pageSize,
    pageIndex,
    searchExpression,
    setData,
    dataToSend
  ) =>
    dispatch(
      configurationsActions.addSmartPhoneDevice(
        pageSize,
        pageIndex,
        searchExpression,
        setData,
        dataToSend
      )
    ),
  removeSmartPhoneDevice: (
    pageSize,
    pageIndex,
    searchExpression,
    setData,
    dataToSend
  ) =>
    dispatch(
      configurationsActions.removeSmartPhoneDevice(
        pageSize,
        pageIndex,
        searchExpression,
        setData,
        dataToSend
      )
    ),
  reorderSmartPhoneDevices: (
    pageSize,
    pageIndex,
    searchExpression,
    setData,
    dataToSend,
    setDraggedIndex
  ) =>
    dispatch(
      configurationsActions.reorderSmartPhoneDevices(
        pageSize,
        pageIndex,
        searchExpression,
        setData,
        dataToSend,
        setDraggedIndex
      )
    ),
});

// Mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SmartPhoneDevices));
