import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

export const NavigateToPath = (history, path, name, id) => {
  // but you can use a location instead
  const location = {
    pathname: path,
    state: {
      name: name ? name : history.location.state?.name,
    },
    id: id,
  };

  //if (history.location.pathname !== path) {
  history.push(location);
  //}
};

export const NavigateToDefault = (history) => {
  history.push("/Dashboard/UpcomingEvents");
  history.replace("/Dashboard/UpcomingEvents");
};

export const navigateToLogin = () => {
  history.replace("/log_in", { name: "LogIn" });
  history.go(-history);
};

export const NavigateToPathWithGo = (history, path, name, id) => {
  // but you can use a location instead
  const location = {
    pathname: path,
    state: {
      name: name ? name : history.location.state?.name,
    },
    id: id,
  };

  if (history.location.pathname !== path) {
    history.push(location);
    history.go(-history);
  }
};
