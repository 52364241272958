export const marketingConstants = {
  ADD_PARTNER_CODES: "ADD_PARTNER_CODES",
  GET_PARTNER_CODES_DATA: "GET_PARTNER_CODES_DATA",
  SET_SUBMIT_BUTTON_STATE: "SET_SUBMIT_BUTTON_STATE",
  GET_PARTNER_CODES: "GET_PARTNER_CODES",
  REMOVE_PARTNER_CODE: "REMOVE_PARTNER_CODE",
  GET_PARTNER_CODE_BY_ID: "GET_PARTNER_CODE_BY_ID",
  UPDATE_PARTNER_CODE: "UPDATE_PARTNER_CODE",
  TOGGLE_LINK_ACTIVATION : "TOGGLE_LINK_ACTIVATION",
  GET_REPORTS_DATA:"GET_REPORTS_DATA",
  DEFAULT_PAGINATION_PAGE_SIZE: 20,
};
