import React, { Component, Fragment } from "react";
import * as Sentry from "@sentry/react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
    Sentry.captureException(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Fragment>
          <div className="not-found">
            <div className="not-found__content">
              <h3 className="not-found__info">
                Ooops! The action you would like to perform might have some
                issue please report so that we can fix it.
              </h3>
            </div>
          </div>
        </Fragment>
      );
    }

    return this.props.children;
  }
}
export default ErrorBoundary;
