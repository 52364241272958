import { updateObject } from "../../shared/utility";
import { marketingConstants } from "../../constants";

// getting the default actions name from defined constants via decomposition
const { GET_PARTNER_CODES, GET_PARTNER_CODE_BY_ID, GET_REPORTS_DATA } =
  marketingConstants;

const initialState = {
  data: {},
  numberOfPages: 2,
  total: 7,
  currentPage: 1,
  pageSize: 5,
  isSubmitButtonDisabled: false,
  partnerCodes: [],
  partnerCodeById: {},
  reportsData:[]
};

// manupilating the state based on actions and returning the state
export const marketing = (state = initialState, action) => {
  switch (action.type) {
    case GET_PARTNER_CODES:
      return updateObject(state, {
        partnerCodes: action?.data?.items,
      });
    case GET_PARTNER_CODE_BY_ID:
      return updateObject(state, {
        partnerCodeById: action?.data?.items,
      });
    case GET_REPORTS_DATA:
      return updateObject(state, {
        reportsData: action?.data,
      });
    default:
      return state;
  }
};