import React, { useState, useEffect, useMemo } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import EyeIcon from "mdi-react/EyeIcon";
import { useTranslation } from "react-i18next";
import LoadingIcon from "mdi-react/LoadingIcon";
import { useHistory, withRouter, NavLink } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { NavigateToDefault } from "../../../system";
import { loginConstants, userType } from "../../../constants";
import { loginActions } from "../../../redux/actions";

const LogInForm = (props) => {
  const history = useHistory();
  const { t } = useTranslation("common");

  const loginSpinner = useMemo(
    () =>
      props.spinnerActions.findIndex((f) => f === loginConstants.USER_LOGIN),
    [props.spinnerActions]
  );

  //You can Extract any data OR Function that this component receive as PROPS
  const {
    authentication: { isAuthenticated },
  } = props;

  const [showPassword, setShowPassword] = useState(false);
  const showPasswordToggle = () => setShowPassword(!showPassword);

  // when user clicks on Login button
  const handleSubmit = (event, values) => props.onLoginClick({ ...values });

  // navigate to screen after login
  useEffect(() => {
    if (isAuthenticated && props.userType===userType.ADMIN){NavigateToDefault(history)};
    // eslint-disable-next-line
  }, [props.authentication]);

  return (
    <AvForm
      className="form form_Display_Flex login-form"
      onValidSubmit={handleSubmit}
    >
      <div className="form__form-group">
        <span className="form__form-group-label">{t("user_email")}</span>
        <div className="form__form-group-field">    
          <div className="input-without-border-radius">
            <AvField
              required
              type="email"
              name="userName"
              className="input-without-border-radius"
            />
          </div>
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">{t("password")}</span>
        <div className="form__form-group-field">
          <div className="input-without-border-radius">
            <AvField
              type={showPassword ? "text" : "password"}
              name="password"
              required
              className="input-without-border-radius"
            />
          </div>
          <button
            type="button"
            className={`form__form-group-button ${
              showPassword ? " active" : ""
            }`}
            onClick={showPasswordToggle}
          >
            <EyeIcon />
          </button>
          <div className="account__forgot-password">
            <NavLink to="/reset_password">{t("forgot_password")}</NavLink>
          </div>
        </div>
      </div>
      <div className="form__form-group mt-4">
        <div className="form__form-group-field">
          <div className="account__btns ml-0">
            {loginSpinner > -1 ? (
              <div className=" account__btn text-center login_spinner">
                <LoadingIcon className="loading-spinner" />
              </div>
            ) : (
              <Button
                className="account__btn mb-0"
                type="submit"
                color="primary"
              >
                {t("login")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </AvForm>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  authentication: state.authentication,
  userType: state.authentication.userType,
  spinnerActions: state.system.spinnerActions,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  onLoginClick: (data) => dispatch(loginActions.login(data)),
});

// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LogInForm));
