import React, { useState, useEffect, useCallback } from "react";
import { withRouter, useParams, useLocation } from "react-router";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import LoadingIcon from "mdi-react/LoadingIcon";
import { shutterBsActions } from "../../redux/actions";
import {
  configurationsConstants,
  shutterBsConstants,
  shutterBsStatusConstants,
} from "../../constants";
import { AvForm, AvField } from "availity-reactstrap-validation";
import ViewShutterBDetail from "./ViewShutterBDetail";
import DataTable from "react-data-table-component";
import PaginationComponent from "../../shared/components/PaginationComponent/PaginationComponent";

const {
  GET_SHUTTER_BS,
  APPROVE_SHUTTER_B,
  ENABLE_DISABLE_SHUTTER_B,
  UPDATE_HOURLY_RATE,
} = shutterBsConstants;

const ShutterBs = (props) => {
  const { t } = useTranslation("common");
  const { tab } = useParams();
  const location = useLocation();

  const [expandedCard, setExpandedCard] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [hourlyRate, setHourlyRate] = useState(null);
  const [currentSb, setCurrentSb] = useState(null);
  const [idForHourlyRate, setIdForHourlyRate] = useState(null);
  const [isViewDetail, setIsViewDetail] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({
    pageSize: configurationsConstants.DEFAULT_PAGINATION_PAGE_SIZE,
    pageNumber: 1,
    totalRows: 0,
  });
  const [filterOptionValue, setFilterOptionValue] = useState("");
  const filterOptions = ["firstName", "lastName", "email"];

  const spinner = useCallback(
    (type) => props.spinnerActions.findIndex((f) => f === type),
    [props.spinnerActions]
  );

  const handleCollapseToggle = (cardId) => {
    setExpandedCard(cardId === expandedCard ? null : cardId);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleSubmit = (event, values, errors) => {
    //const url = window.location.href;
    //const id = url.substring(url.indexOf("#") + 1);
    const dataToSend = {
      sbUserId: idForHourlyRate,
      hourlyRate: parseInt(values.hourlyRate),
    };
    props.updateHourlyRate(dataToSend, setModalIsOpen, tab);
  };

  const findHourlyRateById = (id) => {
    const user = props.data?.items?.find((user) => user.id === parseInt(id));
    setIdForHourlyRate(user ? user.id : null);
    return user ? user.hourlyRate : null;
  };

  const setHourlyRateById = (id) => {
    setHourlyRate(findHourlyRateById(id));
  };

  const validateNonNegativeValue = (value) => {
    const regex = /[e+-]/i;

    if (regex.test(value)) {
      return "This field is invalid";
    }
    if (value <= 0) {
      return "Value should be greater than 0";
    }
    if (value > 10000) {
      return "Value should not be greater then 10000";
    }
    return true;
  };

  const handleViewDetail = (id) => {
    setCurrentSb(id);
    setIsViewDetail(true);
  };

  const handleCloseViewDetail = () => {
    setIsViewDetail(false);
  };

  useEffect(() => {
    // Check if the hash in the URL is "#shutterbDetails"
    if (location.hash !== "#shutterbDetails") {
      setIsViewDetail(false);
    }
  }, [location.hash]);

  const columns = [
    {
      width: "100%",
      cell: (row) => (
        <React.Fragment key={row.id}>
          <div className="card card-style pagination-card-width mb-2">
            <div className="card-header back-color">
              <p className="mb-0">
                <a
                  href={`#${row.id}`}
                  className="collapsed d-flex justify-content-between a-style"
                  onClick={() => handleCollapseToggle(row.id)}
                >
                  {`${t("name")} - ${row.firstName} ${
                    row.lastName
                  } - ${new Date(row.createdAt * 1000).toLocaleDateString()}`}
                  <strong> {`${t("id")} ${row.id}`}</strong>
                </a>
              </p>
            </div>
            <div
              id={`collapse${row.id}`}
              className={`collapse ${expandedCard === row.id ? "show" : ""}`}
            >
              <div className="card-body">
                {[
                  {
                    label: t("name"),
                    value: `${row.firstName} ${row.lastName}`,
                  },
                  {
                    label: t("date"),
                    value: `${new Date(
                      row.createdAt * 1000
                    ).toLocaleDateString()}`,
                  },
                  {
                    label: t("email"),
                    value: row.email,
                  },
                  {
                    label: t("address"),
                    value: row.address[0]?.currentLocation,
                  },
                  {
                    label: t("smart_phone"),
                    value: row.device?.deviceName,
                  },
                  {
                    label: t("instagram_handle"),
                    value: row.instagramHandle,
                  },
                  {
                    label: t("phone_number"),
                    value: row.phoneNumber,
                  },
                  {
                    label: t("distance"),
                    value:
                      row.distance[0]?.minDistanceToTravel +
                      " " +
                      row.distance[0]?.unit,
                  },
                  {
                    label: t("rejected_events"),
                    value: row.totalRejectedEvent,
                  },
                  row.status === shutterBsStatusConstants.APPROVED &&
                    row.isActive && {
                      label: t("Profile link"),
                      value: (
                        <a
                          href={`${process.env.REACT_APP_HOST_URL}/ShutterbProfile/${row.id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ textDecoration: "underline" }}
                        >
                          {t("View Profile")}
                        </a>
                      ),
                    },
                ]
                  .filter((item) => item)
                  .map(({ label, value }) => (
                    <div className="d-flex" key={label}>
                      <p className="text-left w-25 fw-bold">{label}</p>
                      <p className="w-75">{value}</p>
                    </div>
                  ))}
                <button
                  type="submit"
                  className="btn btn-info"
                  disabled={
                    row.status === shutterBsStatusConstants.APPROVED &&
                    !row.isActive
                  }
                  onClick={() =>
                    props.approveShutterB(
                      {
                        id: row.id,
                        status: shutterBsStatusConstants.APPROVED,
                      },
                      tab,
                      row.status
                    )
                  }
                >
                  {row.status === shutterBsStatusConstants.APPROVED
                    ? t("reset_password")
                    : t("approve")}
                </button>

                {(row.status === shutterBsStatusConstants.CREATED ||
                  row.status === shutterBsStatusConstants.HOLD) && (
                  <button
                    type="submit"
                    className="btn btn-info"
                    onClick={() =>
                      props.approveShutterB(
                        {
                          id: row.id,
                          status: shutterBsStatusConstants.REJECTED,
                        },
                        tab,
                        shutterBsStatusConstants.REJECTED
                      )
                    }
                  >
                    {t("reject")}
                  </button>
                )}

                {row.status === shutterBsStatusConstants.CREATED && (
                  <button
                    type="submit"
                    className="btn btn-info"
                    onClick={() =>
                      props.approveShutterB(
                        {
                          id: row.id,
                          status: shutterBsStatusConstants.HOLD,
                        },
                        tab,
                        shutterBsStatusConstants.HOLD
                      )
                    }
                  >
                    {t("hold")}
                  </button>
                )}

                {row.status === shutterBsStatusConstants.APPROVED && (
                  <button
                    disabled={!row.status === shutterBsStatusConstants.APPROVED}
                    type="submit"
                    className="btn btn-info"
                    onClick={() =>
                      props.enableDisableShutterB(
                        {
                          id: row.id,
                          isActive: !row.isActive,
                        },
                        tab
                      )
                    }
                  >
                    {row.isActive ? t("deactivate") : t("activate")}
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-info"
                  onClick={() => {
                    openModal();
                    setHourlyRateById(row.id);
                  }}
                >
                  {t("set_hourly_rate")}
                </button>
                <button
                  type="button"
                  className="btn btn-info"
                  onClick={() => {
                    handleViewDetail(row.id);
                    window.location.hash = "shutterbDetails";
                  }}
                >
                  {t("view_details")}
                </button>
              </div>
            </div>
          </div>
        </React.Fragment>
      ),
      allowOverflow: true,
      button: true,
    },
  ];
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
      },
    },
    rows: {
      style: {
        "&:not(:last-of-type)": {
          borderBottomWidth: "0px",
        },
      },
    },
  };

  const handleGetData = (pageSize, pageNumber, temps) => {
    props.getShutterBs(
      tab,
      pageSize,
      pageNumber,
      searchText,
      filterOptionValue
    );
  };
  useEffect(() => {
    props.getShutterBs(
      tab,
      pagination.pageSize,
      pagination.pageNumber,
      searchText,
      filterOptionValue
    );
    setIsViewDetail(false);

    // eslint-disable-next-line
  }, [tab]);

  useEffect(() => {
    setPagination({
      pageSize: props.data?.pageSize,
      pageNumber: props.data?.currentPage,
      totalRows: props.data?.total,
    });
  }, [props.data]);

  return (
    <div className="row mr-0">
      <div className="col-md-12">
        <div className="col-lg-12">
          <div className="tab-content">
            {isViewDetail ? (
              <ViewShutterBDetail
                onClose={handleCloseViewDetail}
                currentSb={currentSb}
              />
            ) : (
              <div className="tab-pane fade show active">
                {spinner(GET_SHUTTER_BS) &&
                spinner(APPROVE_SHUTTER_B) &&
                spinner(ENABLE_DISABLE_SHUTTER_B) ? (
                  <div>
                    <PaginationComponent
                      pagination={pagination}
                      setPagination={setPagination}
                      searchText={searchText}
                      setSearchText={setSearchText}
                      getTableData={handleGetData}
                      filterOptionValue={filterOptionValue}
                      setFilterOptionValue={setFilterOptionValue}
                      filterOptions={filterOptions}
                    />
                    <DataTable
                      columns={columns}
                      data={props.data?.items}
                      responsive
                      customStyles={customStyles}
                      className="no-scroll"
                    />
                  </div>
                ) : (
                  <div className="text-center mt-4">
                    <LoadingIcon className="loading-spinner" />
                  </div>
                )}

                <Modal
                  isOpen={modalIsOpen}
                  onRequestClose={closeModal}
                  contentLabel="Set Hourly Rate Modal"
                  className="image-modal"
                  style={{
                    content: {
                      width: "300px",
                      height: "300px",
                      margin: "auto",
                    },
                  }}
                >
                  <div className="modal-content">
                    {spinner(UPDATE_HOURLY_RATE) ? (
                      <>
                        <h2>{t("set_hourly_rate")}</h2>
                        <AvForm onValidSubmit={handleSubmit}>
                          <AvField
                            name="hourlyRate"
                            type="number"
                            value={hourlyRate}
                            label={t("hourly_rate")}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: t("hourly_rate_required"),
                              },
                              custom: validateNonNegativeValue,
                            }}
                          />
                          <button type="submit" className="btn btn-primary">
                            {t("Save")}
                          </button>
                        </AvForm>
                        <button onClick={closeModal} className="modal-close">
                          <img
                            src="/images/simpleCrossWhite.svg"
                            alt="crossIcon"
                            className="modal-close-img"
                          />
                        </button>
                      </>
                    ) : (
                      <div className="text-center mt-4 h-150">
                        <LoadingIcon className="loading-spinner" />
                      </div>
                    )}
                  </div>
                </Modal>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  spinnerActions: state.system.spinnerActions,
  data: state.shutterBs.data,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getShutterBs: (tab, pageSize, pageNumber, searchText, filterOptionValue) =>
    dispatch(
      shutterBsActions.getShutterBs(
        tab,
        pageSize,
        pageNumber,
        searchText,
        filterOptionValue
      )
    ),
  approveShutterB: (data, tab, status) =>
    dispatch(shutterBsActions.approveShutterB(data, tab, status)),
  enableDisableShutterB: (data, tab) =>
    dispatch(shutterBsActions.enableDisableShutterB(data, tab)),
  updateHourlyRate: (data, setModalIsOpen, tab) =>
    dispatch(shutterBsActions.updateHourlyRate(data, setModalIsOpen, tab)),
});

// Mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ShutterBs));
