import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import { customToast } from "../../shared";
import { useTranslation } from "react-i18next";
import LoadingIcon from "mdi-react/LoadingIcon";
import DataTable from "react-data-table-component";
import Modal from "react-modal";
import { configurationsActions, reelsActions } from "../../redux/actions";
import { configurationsConstants, reelsConstants } from "../../constants";
import { getFilterExpression } from "../../shared";
import PaginationComponent from "../../shared/components/PaginationComponent/PaginationComponent";

const { GET_ALL_SHUTTERBS } = configurationsConstants;

const {
  GET_ASSIGNED_REEL_EVENTS,
  GET_UNASSIGNED_REEL_EVENTS,
  ASSIGN_REEL_EVENT,
  REMOVE_ASSIGNED_REEL_SHUTTERB,
} = reelsConstants;

const Reels = (props) => {
  const { t } = useTranslation("common");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState([]);
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [removeShutterbModalIsOpen, setRemoveShutterbModalIsOpen] =
    useState(false);
  const [unAssignEventId, setUnAssignEventId] = useState(null);
  const [unAssignSbName, setUnAssignSbName] = useState(null);
  const [unAssignOrderItemId, setUnAssignOrderItemId] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({
    pageSize: configurationsConstants.DEFAULT_PAGINATION_PAGE_SIZE,
    pageNumber: 1,
    totalRows: 0,
  });
  const [filterOptionValue, setFilterOptionValue] = useState("");
  const filterOptions = ["updatedAt"];
  const [date, setDate] = useState();

  const spinner = useCallback(
    (type) => props.spinnerActions.findIndex((f) => f === type),
    [props.spinnerActions]
  );

  const columns = [
    {
      name: "Event Name",
      selector: "eventName",
      sortable: true,
      width: "350px",
    },
    {
      name: "Assignee",
      selector: "shutterBName",
      sortable: true,
      width: "300px",
    },
    {
      name: "Event Date",
      selector: "updatedAt",
      sortable: true,
      width: "300px",
      cell: (row) => {
        return new Date(row?.updatedAt * 1000).toLocaleDateString();
      },
    },
    {
      name: t("actions"),
      width: "300px",
      cell: (row) => (
        <div>
          <button
            type="button"
            disabled={row.isReelUploaded}
            className="payment-button"
            onClick={() => {
              setUnAssignEventId(row.eventId);
              setUnAssignSbName(row.shutterBName);
              setUnAssignOrderItemId(row.orderItemId);
              openRemoveShutterbModal();
            }}
          >
            {t("Remove")}
          </button>
        </div>
      ),
      allowOverflow: true,
      button: true,
    },
  ];
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
      },
    },
  };

  // Function to handle the selection of shutterb
  const handleUserSelection = (selectedOptions) => {
    setSelectedUsers(selectedOptions);
  };

  // Function to handle the selection of shutterb
  const handleEventSelection = (selectedOptions) => {
    setSelectedEvent(selectedOptions);
  };

  // Function to show/hide the modal
  const openConfirmModal = () => {
    setConfirmModalIsOpen(true);
  };

  const closeConfirmModal = () => {
    setConfirmModalIsOpen(false);
  };

  const openRemoveShutterbModal = () => {
    setRemoveShutterbModalIsOpen(true);
  };

  const closeRemoveShutterbModal = () => {
    setRemoveShutterbModalIsOpen(false);
  };

  // Function to handle the insertion action after confirmation
  const handleConfirmAction = () => {
    performInsertionAction();
    closeConfirmModal();
  };

  // Function to handle the insertion action
  const performInsertionAction = () => {
    if (selectedUsers.length <= 0 || selectedEvent.length <= 0) {
      customToast(t("field_is_empty"), toast.TYPE.ERROR);
      return;
    }
    const dataToSend = {
      sbUserId: selectedUsers.value,
      id: selectedEvent.value,
    };
    props.assignReelEventToShutterb(dataToSend);
    setSelectedUsers([]);
    setSelectedEvent([]);
  };

  const handleConfirmUnassignAction = () => {
    props.removeAssignedReelShutterb(
      unAssignOrderItemId,
      pagination.pageSize,
      pagination.pageNumber,
      getFilterExpression(searchText)
    );
    closeRemoveShutterbModal();
  };

  // Convert data array to options for the select dropdown
  const userOptions = props.allShutterbs?.map((user) => ({
    value: user.id,
    label: `${user.firstName} ${user.lastName} - ${user.email}`,
  }));
  const eventOptions = props.unAssignedReelEvents?.map((user) => ({
    value: user.orderItemId,
    label: `${user.eventName} - ${user.eventId} - ${new Date(
      user.updatedAt * 1000
    ).toLocaleDateString()}`,
  }));

  useEffect(() => {
    props.getUnassignedReelEvents();
    props.getShutterBs();
    props.getAssignedReelEvents(
      pagination.pageSize,
      pagination.pageNumber,
      getFilterExpression(searchText)
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPagination({
      pageSize: props.assignedReelEvents?.pageSize,
      pageNumber: props.assignedReelEvents?.currentPage,
      totalRows: props.assignedReelEvents?.total,
    });
  }, [props.assignedReelEvents]);

  return (
    <>
      <div className="row mr-0">
        <div className="col-md-12">
          <div className="col-lg-12">
            <div className="tab-content">
              {spinner(GET_ALL_SHUTTERBS) &&
              spinner(ASSIGN_REEL_EVENT) &&
              spinner(GET_ASSIGNED_REEL_EVENTS) &&
              spinner(REMOVE_ASSIGNED_REEL_SHUTTERB) &&
              spinner(GET_UNASSIGNED_REEL_EVENTS) ? (
                <div className="tab-pane fade show active">
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1 pr-2">
                      <p className="mb-1 fw-bold custom-p-color">
                        {t("events")}
                      </p>
                      <Select
                        options={eventOptions}
                        value={selectedEvent}
                        onChange={handleEventSelection}
                      />
                    </div>
                    <div className="flex-grow-1 pr-2">
                      <p className="mb-1 fw-bold custom-p-color">
                        {t("shutterbs")}
                      </p>
                      <Select
                        options={userOptions}
                        value={selectedUsers}
                        onChange={handleUserSelection}
                      />
                    </div>
                    <button
                      className="btn btn-primary mb-0 btn-pos"
                      onClick={() => {
                        if (selectedUsers.value && selectedEvent.value) {
                          openConfirmModal();
                        } else {
                          customToast(t("field_is_empty"), toast.TYPE.ERROR);
                        }
                      }}
                    >
                      Assign
                    </button>
                    <button
                      className="btn btn-primary mb-0 btn-pos"
                      disabled={!selectedEvent.value}
                      onClick={() => {
                        props.setIsReelDetailModalOpen();
                        props.setReelDetailModalEventId(
                          selectedEvent.label.split(" - ")[
                            selectedEvent.label.split(" - ").length - 2
                          ]
                        );
                      }}
                    >
                      View Recap Details
                    </button>
                  </div>
                  <div className="mt-4">
                    <div className="pagination-controls">
                      <PaginationComponent
                        pagination={pagination}
                        setPagination={setPagination}
                        searchText={searchText}
                        setSearchText={setSearchText}
                        getTableData={props.getAssignedReelEvents}
                        filterOptionValue={filterOptionValue}
                        setFilterOptionValue={setFilterOptionValue}
                        filterOptions={filterOptions}
                        isDateIncluded
                        date={date}
                        setDate={setDate}
                        isTable
                      />
                    </div>
                    <DataTable
                      columns={columns}
                      data={props.assignedReelEvents?.items}
                      highlightOnHover
                      responsive
                      customStyles={customStyles}
                      className="sticky-header-class"
                    />
                  </div>
                </div>
              ) : (
                <div className="text-center mt-4">
                  <LoadingIcon className="loading-spinner" />
                </div>
              )}
            </div>
          </div>
        </div>
        {/*Confirmation modal */}
        <Modal
          isOpen={confirmModalIsOpen}
          onRequestClose={closeConfirmModal}
          contentLabel="Confirm Modal"
          className="custom-confirmation-modaal"
          style={{
            content: {
              width: "300px",
              height: "200px",
              margin: "auto",
            },
          }}
        >
          <div className="modal-content">
            <p>{`Are you sure you want to assign ${selectedEvent.label} to ${selectedUsers.label}`}</p>
            <div className="d-flex justify-content-between mt-2">
              <button onClick={handleConfirmAction} className="btn btn-primary">
                {t("yes")}
              </button>
              <button onClick={closeConfirmModal} className="btn btn-secondary">
                {t("cancel")}
              </button>
            </div>
            <button onClick={closeConfirmModal} className="modal-close">
              <img
                src="/images/simpleCrossWhite.svg"
                alt="crossIcon"
                className="modal-close-img"
              />
            </button>
          </div>
        </Modal>
        {/*Remove Shutterb Modal */}
        <Modal
          isOpen={removeShutterbModalIsOpen}
          onRequestClose={closeRemoveShutterbModal}
          contentLabel="Confirm Modal"
          className="custom-confirmation-modaal"
          style={{
            content: {
              width: "300px",
              height: "200px",
              margin: "auto",
            },
          }}
        >
          <div className="modal-content">
            <p>{`Are you sure you want to unassign event "${unAssignEventId}" from ${unAssignSbName}?`}</p>
            <div className="d-flex justify-content-between mt-2">
              <button
                onClick={handleConfirmUnassignAction}
                className="btn btn-primary"
              >
                {t("yes")}
              </button>
              <button
                onClick={closeRemoveShutterbModal}
                className="btn btn-secondary"
              >
                {t("cancel")}
              </button>
            </div>
            <button onClick={closeRemoveShutterbModal} className="modal-close">
              <img
                src="/images/simpleCrossWhite.svg"
                alt="crossIcon"
                className="modal-close-img"
              />
            </button>
          </div>
        </Modal>
      </div>
    </>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  spinnerActions: state.system.spinnerActions,
  allShutterbs: state.configurations.allShutterbs,
  assignedReelEvents: state.reels.assignedReelEvents,
  unAssignedReelEvents: state.reels.unAssignedReelEvents,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getShutterBs: () => dispatch(configurationsActions.getAllShutterBsForReels()),
  assignReelEventToShutterb: (data) =>
    dispatch(reelsActions.assignReelEventToShutterb(data)),
  getUnassignedReelEvents: () =>
    dispatch(reelsActions.getUnassignedReelEvents()),
  getAssignedReelEvents: (pageSize, pageIndex, filterExpression) =>
    dispatch(
      reelsActions.getAssignedReelEvents(pageSize, pageIndex, filterExpression)
    ),
  removeAssignedReelShutterb: (id, pageSize, pageIndex, filterExpression) =>
    dispatch(
      reelsActions.removeAssignedReelShutterb(
        id,
        pageSize,
        pageIndex,
        filterExpression
      )
    ),
  setIsReelDetailModalOpen: () =>
    dispatch({
      type: reelsConstants.SET_IS_REEL_DETAIL_MODAL_OPEN,
    }),
  setReelDetailModalEventId: (eventId) =>
    dispatch({
      type: reelsConstants.SET_REEL_DETAIL_MODAL_EVENT_ID,
      data: eventId,
    }),
});

// Mapping action and store the function via props
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Reels));
