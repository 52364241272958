import { updateObject } from "../../shared/utility";
import { reelsConstants } from "../../constants";

// getting the default actions name from defined constants via decomposition
const {
  GET_ASSIGNED_REEL_EVENTS,
  GET_UNASSIGNED_REEL_EVENTS,
  SET_IS_REEL_DETAIL_MODAL_OPEN,
  SET_REEL_DETAIL_MODAL_EVENT_ID,
  GET_REEL_DETAILS,
  GET_REEL_TYPE_DETAILS,
  GET_REEL_TYPE_BY_ID,
} = reelsConstants;

// default state
const initialState = {
  assignedReelEvents: {},
  unAssignedReelEvents: [],
  isReelDetailModalOpen: false,
  reelDetailModalEventId: null,
  reelTypes: [],
  reelTypeById: {},
  reelDetail: {
    reelDetails: [],
    reelQuestionAnswers: [],
  },
  setReelTypeValues: {},
};

// manupilating the state based on actions and returning the state
export const reels = (state = initialState, action) => {
  switch (action.type) {
    case GET_ASSIGNED_REEL_EVENTS:
      return updateObject(state, {
        assignedReelEvents: action.data,
      });
    case GET_UNASSIGNED_REEL_EVENTS:
      return updateObject(state, {
        unAssignedReelEvents: action.data.items,
      });
    case SET_IS_REEL_DETAIL_MODAL_OPEN:
      return updateObject(state, {
        isReelDetailModalOpen: !state.isReelDetailModalOpen,
      });
    case SET_REEL_DETAIL_MODAL_EVENT_ID:
      return updateObject(state, {
        reelDetailModalEventId: action.data,
      });
    case GET_REEL_DETAILS:
      return updateObject(state, {
        reelDetail: action.data,
      });
    case GET_REEL_TYPE_DETAILS:
      return updateObject(state, {
        reelTypes: action.data.items,
      });
    case GET_REEL_TYPE_BY_ID:
      return updateObject(state, {
        reelType: action.data.items,
      });
    default:
      return state;
  }
};
