export function setItemToStorage(keyname, value) {
  return sessionStorage.setItem(keyname, value);
}

export function getItemFromStorage(keyname) {
  return sessionStorage.getItem(keyname);
}

export function removeItemFromStorage(keyname) {
  return sessionStorage.removeItem(keyname);
}

export function clearStorage() {
  return sessionStorage.clear();
}
