import React, { useState, useMemo } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import EyeIcon from "mdi-react/EyeIcon";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoadingIcon from "mdi-react/LoadingIcon";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { loginActions } from "../../../redux/actions";
import { loginConstants } from "../../../constants";
import { getDecryptPayload } from "../../../system";

const ChangePasswordForm = (props) => {
  const { t } = useTranslation("common");

  const loginSpinner = useMemo(
    () => props.spinnerActions.findIndex((f) => f === loginConstants.LOGIN),
    [props.spinnerActions]
  );

  const payload = getDecryptPayload();

  const userName = (payload.user && payload.user.userName) || "";

  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const showPassword = () => {
    setIsPasswordShown(!isPasswordShown);
  };

  const [isConfirmPasswordShown, setIsConfirmPasswordShown] = useState(false);
  const showConfirmPassword = () => {
    setIsConfirmPasswordShown(!isConfirmPasswordShown);
  };

  const passwordValidation = (event) => {
    const password = event.target.value;

    const letter = document.getElementById("letter");
    const capital = document.getElementById("capital");
    const number = document.getElementById("number");
    const special = document.getElementById("special");
    const length = document.getElementById("length");
    const lowerCaseLetters = /[a-z]/g;
    if (password.match(lowerCaseLetters)) {
      letter.classList.remove("text-danger");
      letter.classList.add("text-success");
    } else {
      letter.classList.remove("text-success");
      letter.classList.add("text-danger");
    }
    const upperCaseLetters = /[A-Z]/g;
    if (password.match(upperCaseLetters)) {
      capital.classList.remove("text-danger");
      capital.classList.add("text-success");
    } else {
      capital.classList.remove("text-success");
      capital.classList.add("text-danger");
    }
    const numbers = /[0-9]/g;
    if (password.match(numbers)) {
      number.classList.remove("text-danger");
      number.classList.add("text-success");
    } else {
      number.classList.remove("text-success");
      number.classList.add("text-danger");
    }
    const specialCharacter = /[!@#$%^&*)(+=._-]/g;
    if (password.match(specialCharacter)) {
      special.classList.remove("text-danger");
      special.classList.add("text-success");
    } else {
      special.classList.remove("text-success");
      special.classList.add("text-danger");
    }
    if (password.length >= 6) {
      length.classList.remove("text-danger");
      length.classList.add("text-success");
    } else {
      length.classList.remove("text-success");
      length.classList.add("text-danger");
    }
  };

  // when user clicks on change password button.
  const handleSubmit = (event, values) => {
    const id = props.authentication.userId;
    props.changePassword({ id, ...values });
  };

  return (
    <AvForm className="form" onValidSubmit={handleSubmit}>
      <div className="form__form-group pr-0">
        <span className="form__form-group-label">Email</span>
        <div className="form__form-group-field">
          <div className="form__form-group-input-wrap">
            <AvField
              type="text"
              name="userName"
              value={userName}
              className="input-without-border-radius"
              disabled
            />
          </div>
        </div>
      </div>
      <div className="form__form-group pr-0">
        <span className="form__form-group-label">{t("password")}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-input-wrap">
            <AvField
              type={isPasswordShown ? "text" : "password"}
              className="input-without-border-radius"
              name="password"
              errorMessage=" "
              validate={{
                required: { value: true, errorMessage: "" },
                pattern: {
                  value:
                    "(?=.*[_!@#$%^&*-/^{#%'*/<()>}:`;,!& .?_$+-])(?!.*[.\n])(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])^.{6,}$",
                  errorMessage: "",
                },
                minLength: { value: 6, errorMessage: "" },
              }}
              onChange={passwordValidation}
            />
            <div id="message">
              <p id="number" className="text-danger m-0">
                A number
              </p>
              <p id="letter" className="text-danger m-0">
                A lowercase letter
              </p>
              <p id="capital" className="text-danger m-0">
                A uppercase letter
              </p>
              <p id="special" className="text-danger m-0">
                A Special Character
              </p>
              <p id="length" className="text-danger m-0">
                Minimum 6 characters
              </p>
            </div>
          </div>
          <div>
            <button
              type="button"
              className={`form__form-group-button${
                isPasswordShown ? " active" : ""
              }`}
              tabIndex="-1"
              onClick={() => showPassword()}
            >
              <EyeIcon />
            </button>
          </div>
        </div>
      </div>
      <div className="form__form-group pr-0 mb-4">
        <span className="form__form-group-label">{t("confirm_password")}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-input-wrap">
            <AvField
              type={isConfirmPasswordShown ? "text" : "password"}
              name="confirmPassword"
              className="input-without-border-radius"
              validate={{ match: { value: "password" } }}
              required
            />
          </div>
          <div>
            <button
              type="button"
              className={`form__form-group-button${
                isConfirmPasswordShown ? " active" : ""
              }`}
              tabIndex="-1"
              onClick={() => showConfirmPassword()}
            >
              <EyeIcon />
            </button>
          </div>
        </div>
      </div>
      <div className="form__form-group pr-0">
        <div className="account__btns margin-submit">
          {loginSpinner > -1 ? (
            <div className=" account__btn text-center login_spinner">
              <LoadingIcon className="loading-spinner" />
            </div>
          ) : (
            <Button className="account__btn" type="submit" color="primary">
              {t("Submit")}
            </Button>
          )}
        </div>
      </div>
    </AvForm>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  authentication: state.authentication,
  spinnerActions: state.system.spinnerActions,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(loginActions.logout()),
  changePassword: (data) => dispatch(loginActions.changePassword(data)),
});

// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ChangePasswordForm));
