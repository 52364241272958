import { updateObject } from "../../shared/utility";
import { paymentsConstants } from "../../constants";

// getting the default actions name from defined constants via decomposition
const {
  GET_PAYMENTS_DATA,
  SET_PAY_BUTTON_STATE,
  GET_TAX_AND_FEE,
  GET_PAYMENT_DETAILS,
} = paymentsConstants;

// default state
const initialState = {
  data: {},
  isPayButtonDisabled: false,
  tax: null,
  serviceFee: null,
  platFormFee: null,
  reelPlatFormFee: null,
  taxAndFeeId: null,
  paymentDetailsData: {},
};

// manupilating the state based on actions and returning the state
export const payments = (state = initialState, action) => {
  switch (action.type) {
    case GET_PAYMENTS_DATA:
      return updateObject(state, {
        data: action?.data,
      });
    case SET_PAY_BUTTON_STATE:
      return updateObject(state, {
        isPayButtonDisabled: action?.data,
      });
    case GET_TAX_AND_FEE:
      return updateObject(state, {
        tax: action?.data?.items?.tax,
        serviceFee: action?.data?.items?.serviceFee,
        platFormFee: action?.data?.items?.platformFee,
        reelPlatFormFee: action?.data?.items?.reelPlatformFee,
        taxAndFeeId: action?.data?.items?.id,
      });
    case GET_PAYMENT_DETAILS:
      return updateObject(state, {
        paymentDetailsData: action?.data,
      });
    default:
      return state;
  }
};
