import { custom } from "./utilities";
import { globalSystemConstants } from "../../constants";

const activateSpinner = (data) => {
  return (dispatch) => {
    dispatch(custom(data, globalSystemConstants.SPINNER_ACTIVATE));
  };
};

const deactivateSpinner = (data) => {
  return (dispatch) => {
    dispatch(custom(data, globalSystemConstants.SPINNER_DIACTIVATE));
  };
};

export const systemActions = {
  activateSpinner,
  deactivateSpinner,
};
