import { connect } from "react-redux";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { withRouter } from "react-router";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import LoadingIcon from "mdi-react/LoadingIcon";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { marketingConstants } from "../../../constants";
import { marketingActions } from "../../../redux/actions/marketing.actions";

const {
  GET_PARTNER_CODES,
  ADD_PARTNER_CODES,
  GET_PARTNER_CODE_BY_ID,
  TOGGLE_LINK_ACTIVATION,
  Update_PARTNER_CODES,
} = marketingConstants;

const AffiliatePage = (props) => {
  // eslint-disable-next-line
  const galleryUrlRef = useRef(null);
  const [code, setCode] = useState("");
  const [partnerName, setPartnerName] = useState("");
  const [partnerEmail, setPartnerEmail] = useState("");
  const [partnerId, setPartnerId] = useState(0);
  const isEdit = useRef(false);
  const { t } = useTranslation("common");

  const spinner = useCallback(
    (type) => props.spinnerActions.findIndex((f) => f === type),
    [props.spinnerActions]
  );

  const columns = [
    {
      name: "Partner Code",
      selector: "code",
      sortable: true,
      width: "400px",
      cell: (row) => {
        return window.config.partnerBookingUrl + row.code;
      },
    },
    {
      name: "Partner Name",
      selector: "partnerName",
      sortable: true,
      width: "200px",
    },
    {
      name: "Partner Email",
      selector: "partnerEmail",
      sortable: true,
      width: "220px",
      cell: (row) => <div className="word-wrap">{row.partnerEmail}</div>,
    },

    {
      name: t("actions"),
      width: "400px",
      cell: (row) => (
        <div>
          <button
            type="button"
            className="payment-button"
            onClick={() => {
              props.getPartnerCodeById(row.id);
              isEdit.current = true;
            }}
          >
            {t("Edit")}
          </button>{" "}
          &nbsp;
          <button
            type="button"
            className="payment-button"
            onClick={(e) => {
              copyToClipboard(`${window.config.partnerBookingUrl}${row.code}`);
            }}
          >
            {t("Copy Link")}
          </button>{" "}
          &nbsp;
          <button
            type="button"
            className="payment-button"
            onClick={() => {
              props.toggleActivationLink(row.id);
            }}
          >
            {row.isActive ? t("Deactive") : t("Active")}
          </button>
        </div>
      ),
      allowOverflow: true,
      button: true,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
      },
    },
  };

  function copyToClipboard(text) {
    galleryUrlRef.current.value = text;
    galleryUrlRef.current.select();
    document.execCommand("copy");
    alert("Link Copied");
  }

  useEffect(() => {
    props.getPartnerCodes();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setCode(props.partnerCodeById.code);
    setPartnerName(props.partnerCodeById.partnerName);
    setPartnerEmail(props.partnerCodeById.partnerEmail);
    setPartnerId(props.partnerCodeById.id);
    // eslint-disable-next-line
  }, [props.partnerCodeById]);

  const handleInputCode = (event) => {
    setCode(event.target.value);
  };
  const handlePartnerName = (event) => {
    setPartnerName(event.target.value);
  };

  const handleDataSubmit = (e) => {
    e.preventDefault();
    var dataToSend = {
      id: partnerId,
      code: code,
      partnerName: partnerName,
      partnerEmail: partnerEmail,
    };
    if (partnerId > 0) {
      props.updatePartnerCodes(partnerId, dataToSend);
    } else {
      props.addPartnerCodes(dataToSend);
    }
    isEdit.current = false;
    setCode("");
    setPartnerName("");
    setPartnerEmail("");
    setPartnerId(0);
  };

  return (
    <div className="row mr-0">
      <div className="col-md-12">
        <div className="col-lg-12">
          <div className="tab-content">
            {spinner(GET_PARTNER_CODES) &&
            spinner(Update_PARTNER_CODES) &&
            spinner(ADD_PARTNER_CODES) &&
            spinner(GET_PARTNER_CODE_BY_ID) &&
            spinner(TOGGLE_LINK_ACTIVATION) ? (
              <div className="tab-pane fade show active">
                <AvForm onValidSubmit={handleDataSubmit}>
                  <div className="custom-label-color">
                    <div className="input-group col-12 px-0">
                      <div className="col-md-3 px-0">
                        {/* <label htmlFor="taxValue">Partner Code</label> */}
                        <br />
                        <AvField
                          name="partnerCode"
                          label="Partner Code"
                          type="text"
                          maxLength="50"
                          className="form-control mr-2"
                          placeholder="Enter partner code"
                          value={code}
                          onChange={handleInputCode}
                          disabled={isEdit.current}
                          errorMessage="This field is invalid"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "This Field is required",
                            },
                            pattern: {
                              value: /^[^\s]+$/,
                              errorMessage: "White spaces are not allowed",
                            },
                          }}
                        />
                      </div>
                      <div className="col-md-3 px-0 ml-2">
                        <br />
                        <AvField
                          name="partnerName"
                          label="Partner Name"
                          type="text"
                          required
                          maxLength="2000"
                          className="form-control mr-2"
                          placeholder="Enter partner name"
                          value={partnerName}
                          onChange={handlePartnerName}
                          errorMessage="This field is invalid"
                        />
                      </div>
                      <div className="col-md-3 px-0 ml-2">
                        <br />
                        <AvField
                          name="partnerEmail"
                          label="Partner Email"
                          type="email"
                          required
                          maxLength="50"
                          className="form-control mr-2"
                          placeholder="Enter partner email"
                          value={partnerEmail}
                          onChange={(e) => setPartnerEmail(e.target.value)}
                          errorMessage="This field is invalid"
                        />
                      </div>
                      <div className="input-group-append ml-2 col-md-2 px-0">
                        <button
                          type="submit"
                          style={{ marginTop: "50px" }}
                          className="btn btn-info d-flex align-items-center justify-content-center border-radius-5"
                        >
                          {partnerId > 0 ? t("Update") : t("Add")}
                        </button>
                      </div>
                    </div>
                  </div>
                </AvForm>
                <DataTable
                  columns={columns}
                  data={props?.partnerCodes}
                  highlightOnHover
                  responsive
                  customStyles={customStyles}
                  className="sticky-header-class"
                />
                <input
                  ref={galleryUrlRef}
                  type="text"
                  style={{ position: "absolute", left: "-9999px" }}
                  readOnly
                />
              </div>
            ) : (
              <div className="text-center mt-4">
                <LoadingIcon className="loading-spinner" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  partnerCodes: state.marketing.partnerCodes,
  partnerCodeById: state.marketing.partnerCodeById,
  spinnerActions: state.system.spinnerActions,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getPartnerCodes: () => dispatch(marketingActions.getPartnerCodes()),
  addPartnerCodes: (data) => dispatch(marketingActions.addPartnerCode(data)),
  getPartnerCodeById: (id) => dispatch(marketingActions.getPartnerCodeById(id)),
  updatePartnerCodes: (id, data) =>
    dispatch(marketingActions.updatePartnerCode(id, data)),
  toggleActivationLink: (id) =>
    dispatch(marketingActions.toggleLinkActivation(id)),
});

// Mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AffiliatePage));
