export const paymentsConstants = {
  GET_PAYMENTS_DATA: "GET_PAYMENTS_DATA",
  SET_PAY_BUTTON_STATE: "SET_PAY_BUTTON_STATE",
  GET_TAX_AND_FEE: "GET_TAX_AND_FEE",
  UPDATE_TAX_AND_FEE: "UPDATE_TAX_AND_FEE",
  GET_PAYMENT_DETAILS: "GET_PAYMENT_DETAILS",
};

export const paymentDetails = {
  TIP: "Tip",
  PRICE: "Price",
  EXTENDEDPRICE: "ExtendedPrice",
  PLATFORMFEE: "PlatformFee",
  TOTAL: "Total",
  NET_TOTAL: "NetTotal"
};
