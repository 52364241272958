export const shutterBsConstants = {
  GET_SHUTTER_BS: "GET_SHUTTER_BS",
  APPROVE_SHUTTER_B: "APPROVE_SHUTTER_B",
  ENABLE_DISABLE_SHUTTER_B: "ENABLE_DISABLE_SHUTTER_B",
  UPDATE_HOURLY_RATE: "UPDATE_HOURLY_RATE",
  GET_SHUTTER_B_BY_ID: "GET_SHUTTER_B_BY_ID",
  GET_SHUTTER_B_PORTFOLIO: "GET_SHUTTER_B_PORTFOLIO",
};

export const shutterBsStatusConstants = {
  STATUS: "status",
  CREATED: "created",
  APPROVED: "approved",
  HOLD: "hold",
  REJECTED: "rejected",
};

export const slotsInDay = [
  "08:00 AM - 10:59 AM",
  "11:00 AM - 01:59 PM",
  "02:00 PM - 04:59 PM",
  "05:00 PM - 06:59 PM",
  "07:00 PM - 09:59 PM",
  "10:00 PM - 11:59 PM",
  "12:00 AM - 02:00 AM",
];

export const timesOfTheDay = [
  "Morning:",
  "Afternoon:",
  "Evening:",
  "Night:",
  "Late Night:",
];

export const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];