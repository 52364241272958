// eslint-disable-next-line import/no-cycle
import responseHandler from "../system/server-response-handler";
import {
  postRequest,
  getRequest,
  deleteRequest,
  putRequest,
} from "../system/server-call";

const addPromoCode = (data) => {
  return postRequest(`/api/v1/admin/PromoCodes`, data).then(
    (response) => responseHandler.handleServerMessageResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const updatePromoCode = (id, data) => {
  return putRequest(`/api/v1/admin/PromoCodes/${id}`, data).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

// get promo codes list
const getPromoCodes = (pageSize, pageIndex, filterExpression) => {
  return getRequest(
    `/api/v1/admin/PromoCodes?PageSize=${pageSize}&PageNumber=${pageIndex}&FilterExpression=${filterExpression}`,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const getPromoBalance = (pageSize, pageIndex, filterExpression) => {
  return getRequest(
    `/api/v1/admin/PromoCodes/getPromoBalance?PageSize=${pageSize}&PageNumber=${pageIndex}&FilterExpression=${filterExpression}`,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

// get promo codes list
const getPromoCodeById = (id) => {
  return getRequest(`/api/v1/admin/PromoCodes/${id}`, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

// get devices list
const getSmartPhoneDevices = (pageSize, pageIndex, searchExpression) => {
  // eslint-disable-next-line
  let queryParam = "?";
  if (pageSize) {
    queryParam += `PageSize=${pageSize}&`;
  }
  if (pageIndex) {
    queryParam += `PageNumber=${pageIndex}&`;
  }
  // eslint-disable-next-line no-param-reassign
  searchExpression = searchExpression || btoa("isActive==true");

  if (searchExpression) {
    queryParam += `SearchExpression=${searchExpression}&`;
  }

  // once pagination is added in backend then,
  // return getRequest(`/api/v1/admin/payment${queryParam}`, true).then(
  return getRequest(`/api/v1/admin/ShutterB/GetDevices`, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const addSmartPhoneDevice = (data) => {
  return postRequest(`/api/v1/admin/ShutterB/AddDevice`, data).then(
    (response) => responseHandler.handleServerMessageResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const removeSmartPhoneDevice = (data) => {
  return deleteRequest(
    `/api/v1/admin/ShutterB/RemoveDevice/${data}`,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const reorderSmartPhoneDevices = (data) => {
  return putRequest(`/api/v1/admin/ShutterB/ReorderDevices`, data).then(
    (response) => responseHandler.handleServerMessageResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const getHomepageShutterbs = () => {
  return getRequest("/api/v1/admin/ShutterB/GetSBInfo", true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const addShutterBsToHomePage = (data) => {
  return putRequest(`/api/v1/admin/ShutterB/AddSBOnWebsite`, data).then(
    (response) => responseHandler.handleServerMessageResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const removeShutterBFromHomePage = (id) => {
  return deleteRequest(
    `/api/v1/admin/ShutterB/RemoveSBFromWebsite/${id}`,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const activateDeactivateCode = (id) => {
  return putRequest(`/api/v1/admin/PromoCodes/ToggleActivation/${id}`).then(
    (response) => responseHandler.handleServerMessageResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

// get postal codes list
const getPostalCodes = (pageSize, pageIndex, searchExpression) => {
  // eslint-disable-next-line
  let queryParam = "?";
  if (pageSize) {
    queryParam += `PageSize=${pageSize}&`;
  }
  if (pageIndex) {
    queryParam += `PageNumber=${pageIndex}&`;
  }
  // eslint-disable-next-line no-param-reassign
  searchExpression = searchExpression || btoa("isActive==true");

  if (searchExpression) {
    queryParam += `SearchExpression=${searchExpression}&`;
  }

  return getRequest(`/api/v1/Admin/PostalCode`, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const addPostalCodes = (data) => {
  return postRequest(`/api/v1/Admin/PostalCode`, data).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const removePostalCode = (data) => {
  return deleteRequest(`/api/v1/Admin/PostalCode/${data}`, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const getGiftCards = (pageSize, pageIndex, filterExpression) => {
  return getRequest(
    `/api/v1/admin/GiftCard?PageSize=${pageSize}&PageNumber=${pageIndex}&FilterExpression=${filterExpression}`,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const giftCardToggleActivation = (id) => {
  return putRequest(`/api/v1/admin/GiftCard/ToggleActivation/${id}`).then(
    (response) => responseHandler.handleServerMessageResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const addGiftCard = (data) => {
  return postRequest(`/api/v1/admin/GiftCard`, data).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

// exporting functions for other files
export const configurationsServices = {
  addPromoCode,
  getPromoCodes,
  getSmartPhoneDevices,
  addSmartPhoneDevice,
  removeSmartPhoneDevice,
  reorderSmartPhoneDevices,
  getHomepageShutterbs,
  addShutterBsToHomePage,
  removeShutterBFromHomePage,
  activateDeactivateCode,
  getPostalCodes,
  addPostalCodes,
  removePostalCode,
  getPromoCodeById,
  updatePromoCode,
  getPromoBalance,
  getGiftCards,
  giftCardToggleActivation,
  addGiftCard,
};
