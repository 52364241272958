export const reelsConstants = {
  ASSIGN_REEL_EVENT: "ASSIGN_REEL_EVENT",
  GET_UNASSIGNED_REEL_EVENTS: "GET_UNASSIGNED_REEL_EVENTS",
  GET_ASSIGNED_REEL_EVENTS: "GET_ASSIGNED_REEL_EVENTS",
  REMOVE_ASSIGNED_REEL_SHUTTERB: "REMOVE_ASSIGNED_REEL_SHUTTERB",
  SET_IS_REEL_DETAIL_MODAL_OPEN: "SET_IS_REEL_DETAIL_MODAL_OPEN",
  SET_REEL_DETAIL_MODAL_EVENT_ID: "SET_REEL_DETAIL_MODAL_EVENT_ID",
  GET_REEL_DETAILS: "GET_REEL_DETAILS",
  GET_REEL_TYPE_DETAILS: "GET_REEL_TYPE_DETAILS",
  GET_REEL_TYPE_BY_ID : "GET_REEL_TYPE_BY_ID",
  ADD_REEL_TYPE : "ADD_REEL_TYPE",
  UPDATE_REEL_TYPE : "UPDATE_REEL_TYPE",
  REMOVE_REEL_TYPE : "REMOVE_REEL_TYPE",
  SET_REEL_TYPE_VALUES : "SET_REEL_TYPE_VALUES",
  DEFAULT_PAGINATION_PAGE_SIZE : 20,
};
