import { connect } from "react-redux";
import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import LoadingIcon from "mdi-react/LoadingIcon";
import DataTable from "react-data-table-component";
import { paymentsActions } from "../../redux/actions";
import {
  paymentsConstants,
  paymentDetails,
  configurationsConstants,
} from "../../constants";
// eslint-disable-next-line
import PaginationComponent from "../../shared/components/PaginationComponent/PaginationComponent";

const { GET_PAYMENT_DETAILS, GET_PAYMENTS_DATA } = paymentsConstants;
const { TIP, PRICE, EXTENDEDPRICE, PLATFORMFEE, TOTAL, NET_TOTAL } =
  paymentDetails;

const Payments = (props) => {
  const spinner = useCallback(
    (type) => props.spinnerActions.findIndex((f) => f === type),
    [props.spinnerActions]
  );
  // eslint-disable-next-line
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({
    pageSize: configurationsConstants.DEFAULT_PAGINATION_PAGE_SIZE,
    pageNumber: 1,
    totalRows: 0,
  });
  const [filterOptionValue, setFilterOptionValue] = useState("");
  const filterOptions = ["EventName", "ShutterBFullName"];
  const [, setData] = useState([]);
  const [isViewDetailsModalOpen, setIsViewDetailsModalOpen] = useState(false);

  const { t } = useTranslation("common");

  // Function to show/hide the modal
  // eslint-disable-next-line
  const openViewDetailsModal = () => {
    setIsViewDetailsModalOpen(true);
  };

  const closeViewDetailsModal = () => {
    setIsViewDetailsModalOpen(false);
  };

  const columns = [
    {
      name: "Event Name",
      selector: "eventName",
      sortable: true,
      width: "250px",
    },
    {
      name: t("shutterb_name"),
      selector: "shutterBFullName",
      sortable: true,
      width: "180px",
    },
    {
      name: t("amount"),
      selector: "totalAmount",
      sortable: true,
      width: "180px",
    },
    {
      name: t("platformFee"),
      selector: "platformFee",
      sortable: true,
      width: "180px",
    },
    {
      name: t("transferableAmount"),
      selector: "transferableAmount",
      sortable: true,
      width: "180px",
    },
    {
      name: t("account_status"),
      selector: "stripId",
      sortable: true,
      width: "250px",
      cell: (row) => (
        <div
          className="p-1"
          style={{
            background: "green",
            color: "white",
          }}
        >
          Connected
        </div>
      ),
    },
    {
      name: t("actions"),
      width: "220px",
      cell: (row) => (
        <div className="d-flex justify-content-end w-75">
          <button
            type="button"
            className="payment-button mr-2"
            // disabled={row?.paymentStatus || props.payments.isPayButtonDisabled}
            disabled={true}
            onClick={() => {
              props.payShutterb(row.orderId, row.id, setData);
            }}
            style={
              row?.paymentStatus
                ? {
                    background: "green",
                    color: "white",
                  }
                : null
            }
          >
            {row?.paymentStatus ? t("paid") : t("pending")}
          </button>
          <button
            type="button"
            className="payment-button"
            onClick={() => {
              props.getPaymentDetails(
                row.orderId,
                row.shutterBId,
                closeViewDetailsModal
              );
              openViewDetailsModal();
            }}
          >
            {t("view_details")}
          </button>
        </div>
      ),
      allowOverflow: true,
      button: true,
    },
  ];
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
      },
    },
  };

  useEffect(() => {
    props.getPayments(pagination.pageSize, pagination.pageNumber, searchText);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPagination({
      pageSize: props.payments.data.pageSize,
      pageNumber: props.payments.data.currentPage,
      totalRows: props.payments.data.total,
    });
  }, [props.payments.data]);

  return (
    <div className="row mr-0">
      <div className="col-md-12">
        <div className="col-lg-12 mx-0">
          <div className="tab-content">
            <div className="tab-pane fade show active">
              {spinner(GET_PAYMENT_DETAILS) && spinner(GET_PAYMENTS_DATA) ? (
                <>
                  <div className="pagination-controls">
                    <PaginationComponent
                      pagination={pagination}
                      setPagination={setPagination}
                      searchText={searchText}
                      setSearchText={setSearchText}
                      getTableData={props.getPayments}
                      filterOptionValue={filterOptionValue}
                      setFilterOptionValue={setFilterOptionValue}
                      filterOptions={filterOptions}
                      isTable
                    />
                  </div>
                  <DataTable
                    columns={columns}
                    data={props.payments.data?.items}
                    highlightOnHover
                    responsive
                    customStyles={customStyles}
                    className="sticky-header-class"
                  />
                </>
              ) : (
                <div className="text-center mt-4">
                  <LoadingIcon className="loading-spinner" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isViewDetailsModalOpen}
        onRequestClose={closeViewDetailsModal}
        contentLabel="Confirm Modal"
        className="custom-confirmation-modaal"
        style={{
          content: {
            width: "300px",
            height: "200px",
            margin: "auto",
          },
        }}
      >
        <div className="modal-content">
          <h3 className="d-flex justify-content-center">{`Details`}</h3>
          {spinner(GET_PAYMENT_DETAILS) ? (
            <div className=" justify-content-between mt-2">
              {Object.entries(props?.payments?.paymentDetailsData).map(
                ([key, value]) => (
                  <div key={key} className="d-flex justify-content-between">
                    <span>{key}</span>
                    {key === TIP ||
                    key === PRICE ||
                    key === EXTENDEDPRICE ||
                    key === PLATFORMFEE ||
                    key === TOTAL ||
                    key === NET_TOTAL
                      ? `$${value.toFixed(2)}`
                      : value}
                  </div>
                )
              )}
            </div>
          ) : (
            <div className="text-center mt-4">
              <LoadingIcon className="loading-spinner" />
            </div>
          )}
          <button onClick={closeViewDetailsModal} className="modal-close">
            <img
              src="/images/simpleCrossWhite.svg"
              alt="crossIcon"
              className="modal-close-img"
            />
          </button>
        </div>
      </Modal>
    </div>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  payments: state.payments,
  spinnerActions: state.system.spinnerActions,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getPayments: (pageSize, pageIndex, searchText) =>
    dispatch(paymentsActions.getPayments(pageSize, pageIndex, searchText)),
  payShutterb: (orderId, id, setData) =>
    dispatch(paymentsActions.payShutterb(orderId, id, setData)),
  getPaymentDetails: (orderId, shutterBId, closeViewDetailsModal) =>
    dispatch(
      paymentsActions.getPaymentDetails(
        orderId,
        shutterBId,
        closeViewDetailsModal
      )
    ),
});

// Mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Payments));
