import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isUserLoggedIn = (isAuthenticated) => isAuthenticated;

  return (
    <Route
      {...rest}
      exact
      // eslint-disable-next-line no-nested-ternary
      render={(props) =>
        isUserLoggedIn(rest.isAuthenticated) ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
};

export default withRouter(PrivateRoute);
