import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { configurationsActions } from "../../../redux/actions";
import { AvForm, AvField } from "availity-reactstrap-validation";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import LoadingIcon from "mdi-react/LoadingIcon";
import { configurationsConstants } from "../../../constants";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import {
  customToast,
  filterPastTimes,
  getNextValidTime,
} from "../../../shared";

const { ADD_GIFT_CARD } = configurationsConstants;
const AddGiftCard = (props) => {
  const { t } = useTranslation("common");

  const spinner = useCallback(
    (type) => props.spinnerActions.findIndex((f) => f === type),
    [props.spinnerActions]
  );

  const [discountTitle, setDiscountTitle] = useState("");
  const [description, setDescription] = useState("");
  const [value, setValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isStartDateValid, setIsStartDateValid] = useState(true);
  const [isEndDateValid, setIsEndDateValid] = useState(true);

  const handleSubmit = (event, values, errors) => {
    if (props.rowId === 0) {
      if (
        (startDate === "" || startDate === null) &&
        (endDate === "" || endDate === null)
      ) {
        setIsStartDateValid(false);
        setIsEndDateValid(false);
        return;
      }
      if (startDate === "" || startDate === null) {
        setIsStartDateValid(false);
        return;
      }
      if (endDate === "" || endDate === null) {
        setIsEndDateValid(false);
        return;
      }
    }
    if (
      Math.floor(new Date(startDate).getTime() / 1000) >=
      Math.floor(new Date(endDate).getTime() / 1000)
    ) {
      customToast(
        t("End time should be greater than start time"),
        toast.TYPE.ERROR
      );
      return;
    }
    const dataToSend = {
      code: values.code,
      description: values.description,
      value: values.codeValue,
      balanceAmount: values.codeValue,
      startDate: new Date(startDate).getTime() / 1000,
      endDate: new Date(endDate).getTime() / 1000,
    };
    props.addGiftCard(dataToSend, props.onClose);
  };
  const validateNonNegativeValue = (value) => {
    if (value <= 0) {
      return "Value should be greater than 0";
    }
    return true;
  };

  //function to filter past times based on the minimum date (startDate)
  const filterEndDateTimes = (time, minDate) => {
    if (minDate) {
      const minTimestamp = minDate.getTime();
      const timeTimestamp = time.getTime();
      // Compare the time being filtered with the `minDate`
      if (timeTimestamp > minTimestamp) {
        return true;
      } else if (timeTimestamp === minTimestamp) {
        // Disable the exact `minDate` itself
        const currentMinutes = time.getMinutes();
        const minDateMinutes = minDate.getMinutes();
        return currentMinutes > minDateMinutes;
      } else {
        return false;
      }
    } else {
      // If `startDate` doesn't exist, disable all past times
      return time.getTime() >= new Date().getTime();
    }
  };

  useEffect(() => {
    if (startDate === "" && !isStartDateValid) setIsStartDateValid(false);
    else setIsStartDateValid(true);

    if (endDate === "" && !isEndDateValid) setIsEndDateValid(false);
    else setIsEndDateValid(true);
    // eslint-disable-next-line
  }, [props.endDate, props.startDate]);

  const addMinutesToDate = (date, minutes) => {
    return new Date(date.getTime() + minutes * 60000);
  };

  return (
    <div className="row mr-0">
      <div className="col-md-12">
        <div className="col-lg-11">
          <div className="tab-content">
            {spinner(ADD_GIFT_CARD) ? (
              <div className="tab-pane fade show active">
                <div>
                  <AvForm onValidSubmit={handleSubmit}>
                    <div className="custom-label-color">
                      <label htmlFor="code">Code</label>
                      <AvField
                        name="code"
                        type="text"
                        maxLength="30"
                        value={discountTitle}
                        onChange={(e) => setDiscountTitle(e.target.value)}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Code is required",
                          },
                          pattern: {
                            value: /^[^\s]+$/,
                            errorMessage: "White spaces are not allowed",
                          },
                        }}
                      />
                    </div>
                    <div className="custom-label-color">
                      <label htmlFor="description">Description</label>
                      <AvField
                        name="description"
                        type="textarea"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>
                    <div className="custom-label-color">
                      <label htmlFor="codeValue">Value</label>
                      <div className="input-group">
                        <AvField
                          name="codeValue"
                          type="number"
                          value={value}
                          onChange={(e) => setValue(e.target.value)}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Value is required",
                            },
                            custom: validateNonNegativeValue,
                            pattern: {
                              value: /^[0-9]+$/,
                              errorMessage: "Only numbers are allowed",
                            },
                            max: {
                              value: 50000,
                              errorMessage: "Maximum allowed value is 50000",
                            },
                          }}
                        />
                        <h5 className="m-2">$</h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="custom-label-color">
                          <label htmlFor="startDate">Start Date and Time</label>
                          <DatePicker
                            name="startDate"
                            selected={startDate}
                            onChange={(date) => {
                              const now = new Date();
                              if (date < now) {
                                // If it's in the past, set the selected time to the next valid time
                                const nextValidTime = getNextValidTime(now, 15);
                                setStartDate(nextValidTime);
                                setIsStartDateValid(true);
                              } else {
                                // If it's in the future, set the selected date and time
                                setStartDate(date);
                                setIsStartDateValid(true);
                              }
                            }}
                            minDate={new Date()}
                            showTimeSelect
                            timeFormat="h:mm aa"
                            timeIntervals={15}
                            timeCaption="Time"
                            filterTime={filterPastTimes}
                            dateFormat="yyyy-MM-dd h:mm aa"
                            className={`form-control${
                              isStartDateValid ? ` ` : " red-border"
                            }`}
                          />
                          {!isStartDateValid && (
                            <div className="is-time-field-empty">
                              This field is required
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="custom-label-color">
                          <label htmlFor="endDate">End Date and Time</label>
                          <DatePicker
                            name="endDate"
                            selected={endDate}
                            onChange={(date) => {
                              if (date <= startDate) {
                                const nextValidTime = getNextValidTime(
                                  addMinutesToDate(startDate, 15),
                                  15
                                );
                                setEndDate(nextValidTime);
                                setIsEndDateValid(true);
                              } else if (startDate !== "" && date > startDate) {
                                setEndDate(date);
                                setIsEndDateValid(true);
                              } else {
                                const nextValidTime = getNextValidTime(
                                  new Date(),
                                  15
                                );
                                setEndDate(nextValidTime);
                                setIsEndDateValid(true);
                              }
                            }}
                            minDate={
                              startDate
                                ? addMinutesToDate(startDate, 15)
                                : new Date()
                            }
                            showTimeSelect
                            timeFormat="h:mm aa"
                            timeIntervals={15}
                            timeCaption="Time"
                            filterTime={(time) =>
                              filterEndDateTimes(time, startDate)
                            }
                            dateFormat="yyyy-MM-dd h:mm aa"
                            className={`form-control${
                              isEndDateValid ? ` ` : " red-border"
                            }`}
                          />
                          {!isEndDateValid && (
                            <div className="is-time-field-empty">
                              This field is required
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 justify-content-end d-flex mt-4">
                        <button
                          type="btn"
                          className="btn btn-info"
                          onClick={() => props.onClose()}
                        >
                          Back
                        </button>

                        <button
                          type="submit"
                          className="btn btn-info"
                          disabled={
                            props.configurations?.isSubmitButtonDisabled
                          }
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </AvForm>
                </div>
              </div>
            ) : (
              <div className="text-center mt-4">
                <LoadingIcon className="loading-spinner" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  configurations: state.configurations,
  spinnerActions: state.system.spinnerActions,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  addGiftCard: (data, setFormClose) =>
    dispatch(configurationsActions.addGiftCard(data, setFormClose)),
});

// Mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddGiftCard));
