import { reportConstants } from "../../constants";
import { updateObject } from "../../shared";

const { REPORT_DATA } = reportConstants;

const initialState = {
  reportsData: [],
};

export const monitoringReport = (state = initialState, action) => {
  switch (action.type) {
    case REPORT_DATA:
      return updateObject(state, {
        reportsData: action.data.items,
      });
    default:
      return state;
  }
};
