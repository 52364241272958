import React, { useEffect } from "react";

const customHttpsRedirect = (WrappedComponent) => {
  const HttpsRedirectComponent = (props) => {
    useEffect(() => {
      // Check if the current protocol is HTTP and not on localhost
      if (
        window.location.protocol === "http:" &&
        window.location.hostname !== "localhost"
      ) {
        // Redirect to HTTPS
        window.location.href = window.location.href.replace(
          /^http(?!s)/,
          "https"
        );
      }
    }, []); // Empty dependency array ensures this effect runs only once on component mount

    return <WrappedComponent {...props} />;
  };

  return HttpsRedirectComponent;
};

export default customHttpsRedirect;