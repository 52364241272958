// eslint-disable-next-line import/no-cycle
import responseHandler from "../system/server-response-handler";
import { getRequest, deleteRequest, postRequest } from "../system/server-call";

// get events list
const getDraftEvents = (pageSize, pageNumber, filterExpression) => {
  return getRequest(
    `/api/v1/admin/Event/GetDraftEvents/?PageSize=${pageSize}&PageNumber=${pageNumber}&FilterExpression=${filterExpression}&language=en&`,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const cancelDraftEvent = (id) => {
  return deleteRequest(`/api/v1/admin/Event/${id}`, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const viewDraftEventShutterbs = (id) => {
  return getRequest(`/api/v1/admin/Event/GetAllSuggestedSBz/${id}`, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const assignShutterbsToDraftEvent = (eventId, data) => {
  return postRequest(
    `/api/v1/admin/Event/SuggestSBzToUser`,
    data,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const unAssignShutterbToDraftEvent = (id) => {
  return deleteRequest(`/api/v1/admin/Event/DeleteSuggestedSB/${id}`, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const sendCartLinkToCustomer = (id) => {
  return postRequest(`/api/v1/admin/Event/InformCustomer/${id}`, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

// exporting functions for other files
export const draftEventsServices = {
  getDraftEvents,
  cancelDraftEvent,
  sendCartLinkToCustomer,
  viewDraftEventShutterbs,
  assignShutterbsToDraftEvent,
  unAssignShutterbToDraftEvent,
};
