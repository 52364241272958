const common_en = {
  user_email: "Email",
  user_name: "User Name",
  password: "Password",
  login: "Login",
  logout: "Logout",
  change_password: "Change password",
  login_form_title: "ShutterB",
  invalid_login: "Invalid Login",
  //sidebar---------------------------
  shutterbs: "shutterbs",
  events: "Events",
  completed_events: "Completed Events",
  upcoming_events: "Upcoming Events",
  canceled_events: "Canceled Events",
  reports: "Reports",
  payments: "Payments",
  promo_codes: "Promo Codes",
  tax_and_fee: "Tax and Fee",
  smartphone_devices: "Smartphone devices",
  customers: "Customers",
  new_applicants: "New applicants",
  active_shutterbs: "Active shutterbs",
  archive: "Archive",
  hold: "Hold",
  home_page_shutterbs: "Home page shutterb",
  reels: "Recaps",
  reel_types: "Recap Types",
  invalid_sample_file_type: "Invalid Sample File Type",
  assign_reels: "Assign Recap",
  postal_codes: "Postal Codes",
  billing: "Billing",
  configurations: "Configurations",
  marketing: "Marketing",
  affiliate_links: "Affiliate Links",
  //shutterbs approval
  name: "Name:",
  email: "Email:",
  address: "Address:",
  smart_phone: "Smart phone:",
  instagram_handle: "Instagram Handle:",
  phone_number: "Phone Number:",
  distance: "Distance willing to travel:",
  approve: "Approve",
  deactivate: "Deactivate",
  activate: "Activate",
  date: "Date",
  id: "ID:",
  confirmation_email_sent: "Confirmation email sent",
  shutterb_diactivated: "shutterb Deactivated",
  shutterb_activated: "shutterb Activated",
  shutterb_approved: "shutterb Approved",
  set_hourly_rate: "Set Hourly Rate",
  hourly_rate: "Hourly Rate",
  hourly_rate_required: "Hourly rate  is required",
  hourly_rate_updated: "Hourly Rate Updated",
  resend_email: "Resend email",
  shutterb_rejected: "shutterb Rejected",
  reviews: "REVIEWS",
  average_rating: "RATING",
  reject: "Reject",
  sb_details_title: "SHUTTERB DETAILS",
  //events
  event_date: "Event Date:",
  event_host: "Event Host:",
  event_name: "Event Name",
  event_start_time: "Event Start time:",
  event_location: "Event Location:",
  appartment: "Appartment:",
  event_type: "Event Type:",
  additional_notes: "Additional Notes:",
  total_gallery_views: "Total Gallery Views:",
  sb_start_time: "shutterb Start Time:",
  duration: "Duration:",
  cancel_event: "Cancel Event",
  contact_name: "On-Site Contact Name",
  contact_number: "On-Site Contact Phone Number",
  consent_for_marketing: "Consent for Marketing:",
  gallery_shared_permission: "shutterb can share gallery with guests: ",
  //payments
  view_details: "View Details",
  payment_successful: "Payment Successful",
  pay: "Pay",
  pending: "Pending",
  paid: "Paid",
  showing: "Showing",
  page: "Page",
  of: "of",
  show: "Show",
  amount: "Amount",
  account_status: "Account Status",
  shutterb_name: "shutterb",
  actions: "Actions",
  entries: "entries",
  something_went_wrong: "Something went wrong.",
  unable_to_access_server: "Unable to access server",
  undefined: "Something went wrong.",
  transferableAmount: "Transferable Amount",
  platformFee: "Platform Fee",
  sb_platform_fee: "Platform Fee For shutterb",
  reel_platform_fee: "Platform Fee For Reel",
  service_fee: "Service Fee",
  tax: "Tax",
  update: "Update",
  //forgot_password
  forgot_password: "Forgot password?",
  reset_password: "Reset Password",
  email_send: "Email Send",
  confirm_password: "Confirm Password",
  password_has_been_set_successfully: "Password has been set successfully",

  //PromoCodes
  code: "Code",
  description: "Description",
  value: "Value",
  type: "Type",
  start_date: "Start Date",
  end_date: "End Date",
  usage_per_customer: "Usage Per Customer",
  promocode_deactivated: "Promocode Deactivated",
  promocode_activated: "Promocode Activated",
  promo_code_already_exists: "Promo code already exists",
  promo_balance: "Promo Balance",
  discount: "Discount",
  balance: "Balance",
  promo_code_added_successfully: "Promo Code Added Successfully",
  number_of_users: "Number Of Users",
  gift_card: "Gift Card",
  number_of_redemptions: "Number Of Redemptions",
  gift_card_added_successfully: "Gift Card Added Successfully",
  //TaxAndFee
  form_updated: "Form updated",

  //shutterb detail form
  first_name: "First Name",
  last_name: "Last Name",
  current_address: "Current Address",
  distance_willing_to_travel: "Distance Willing to Travel",
  email_address: "Email Address",
  instagram_handle_form: "Instagram Handle",
  phone_number_form: "Phone Number",
  current_smartphone: "Current Smartphone",
  shutterb_question_1: "Why do you want to work for shutterb?",
  shutterb_question_1_description:
    "Tell us why you’d fit into our community of go-getting, creative and talented individuals. We’re not only looking for good smartphone content creators, we are also looking for people who are personable, professional and passionate about doing what they love!",
  shutterb_question_2:
    "Why do you think you’ll be a great smartphone content creator?",
  shutterb_question_2_description:
    "Tell us what you like about smartphone content creation.  What’s your style and how do you approach it?  Do you have any smartphone accessories that enhance your capabilities as a smartphone content creator?",
  shutterb_question_3:
    "Have you ever worked independently as a gig worker / freelancer before?",
  shutterb_question_3_description:
    "What did you think about it? What were the benefits? What were the drawbacks?",
  shutterb_question_4:
    "How many hours do you plan to devote to shutterb a week?",
  shutterb_question_4_description:
    "When you’re a shutterb you need to commit to a minimum 1 x 3 hour window of availability per week, but we encourage you to make yourself as available as you can be!",
  hst_number: "HST Number",
  working_schedule: "Working Schedule",
  stripe_connected: "Stripe connected",

  //smartphone devices
  device_already_exists: "Device already exists",
  device_added_successfully: "Device added successfully",
  device_is_associated_with_shutterb: "Device is associated with shutterb.",
  device_remove_success: "Device Removed Successfully",
  devices_reordered_successfully: "Device Reordered Successfully",
  device_name: "Device Name",
  add: "Add",

  //customer
  customer_activated: "Customer Activated",
  customer_deactivated: "Customer Deactivated",
  //feature-shutterbs-to-homepage
  update_success: "Inserted Successfully",
  remove_from_website: "Removed Successfully",
  field_is_empty: "Field is empty",
  max_7_shutterbs_can_be_selected: "Cannot add more than 7 shutterbs",
  //reels
  assigned_successfully: "Assigned Successfully",
  rejected_events: "Rejected Events",
  shutterb_assigned_successfully: "shutterb assigned successfully",
  removed_successfully: "Removed Successfully",
  email_sent_failure: "Email sent Failure",
  //postal code
  already_exists: "Already exists",
  remove_successfully: "Removed successfully",
  postal_code_remove_success: "Postal Code Removed Successfully",
  delete_success: "shutterb removed successfully",
  //view Event Gallery
  view_gallery: "View Gallery",
  no_images:
    "Sorry the event content is not ready yet, please check back within 24 hours of the event ending.",
  no_deleted_files: "The deleted items gallery is empty",
  event_cancel_successfully: "Event cancel successfully",
  updated_successfully: "Updated successfully",
  file_successfully_deleted: "File successfully deleted",
  you_will_receive_an_email_when_its_ready:
    "You will receive an email when download is ready",
  download_files_email:
    "Your request is being processed.  Please allow for up to 10 minutes for this email to be sent to your inbox.",
  //modal buttons
  yes: "Yes",
  cancel: "Cancel",
  //Affiliate Marketing
  done_successfully: "Done Successfully",
  activate_successfully: "Activated Successfully",
  deactivate_successfully: "Deactivate Successfully",
  marketing_event_date: "Event Date",
  partner_name: "Partner Name",
  report: "Reports",
  total_shutterb_hours: "shutterb Hours",
  reel_count: "Reels",
  event_host_email: "Event Host Email",

  // Message Board
  see_messages: "See Messages",
  monitoring_report: "Monitoring Report",
  generate_event_report: "Generate Event Report",
  generate_customer_report: "Generate Customer Report",
  generate_shutterB_report: "Generate ShutterB Report",
};

export default common_en;
