import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";

const Reports = () => {

  return (
    <div className="row mr-0">
      <div className="col-md-12">
          <div className="tab-content">
           <p className="d-flex justify-content-center">Not implemented yet</p>
          </div>
      </div>
    </div>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
 
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
 
});

// Mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Reports));
