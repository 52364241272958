import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ShutterBs from "../ShutterBs/ShutterBs";

const Dashboard = (props) => {
  return <ShutterBs />;
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  spinnerActions: state.system.spinnerActions,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({});

// Mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Dashboard));
