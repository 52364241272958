import { toast } from "react-toastify";
import i18next from "i18next";
import jwtDecode from "jwt-decode";
import { authService } from "../../services";
import { loginConstants } from "../../constants";
import { customToast } from "../../shared/utility";
import { success, failure, custom } from "./utilities";
import { globalSystemConstants } from "../../constants";

// this action is called when user click on login
function login(userData) {
  return (dispatch) => {
    dispatch(
      custom(loginConstants.USER_LOGIN, globalSystemConstants.SPINNER_ACTIVATE)
    );
    authService
      .login(userData)
      .then(
        (response) => {
          window.location.reload();
          dispatch(success(response, loginConstants.USER_LOGIN));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, loginConstants.USER_LOGIN));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            loginConstants.USER_LOGIN,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
}

function logout() {
  return (dispatch) => {
    dispatch(custom(null, loginConstants.LOGOUT));
    authService.logout().then(() => window.location.reload());
  };
}

function resetPassword(email) {
  return (dispatch) => {
    dispatch(
      custom(loginConstants.LOGIN, globalSystemConstants.SPINNER_ACTIVATE)
    );
    authService
      .resetPassword(email)
      .then(
        () => {
          const message = i18next.t("email_send");
          customToast(message, toast.TYPE.SUCCESS);
        },
        (error) => {
          customToast(error, toast.TYPE.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(loginConstants.LOGIN, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
}

function emailVerification(queryParam) {
  return (dispatch) => {
    dispatch(
      custom(loginConstants.USER_LOGIN, globalSystemConstants.SPINNER_ACTIVATE)
    );
    authService
      .emailVerification(queryParam)
      .then(
        (response) => {
          const decodedToken = jwtDecode(response?.token);
          if (decodedToken) {
            const userType = decodedToken?.UserType;
            localStorage.setItem("userType", userType);
          }
          dispatch(success(response, loginConstants.USER_LOGIN));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, loginConstants.USER_LOGIN));
          dispatch({ type: "EMAIL_VERIFIED", data: false });
        }
      )
      .finally(() => {
        dispatch(custom(null, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
}

function changePassword(Data) {
  return (dispatch) => {
    dispatch(
      custom(loginConstants.USER_LOGIN, globalSystemConstants.SPINNER_ACTIVATE)
    );
    authService
      .changePassword(Data)
      .then(
        () => {
          const message = i18next.t("password_has_been_set_successfully");
          customToast(message, toast.TYPE.SUCCESS);
          setTimeout(() => window.location.assign("/Dashboard/ShutterBs"), 300);
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, loginConstants.USER_LOGIN));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            loginConstants.USER_LOGIN,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
}

export const loginActions = {
  login,
  logout,
  resetPassword,
  changePassword,
  emailVerification,
};
