import { toast } from "react-toastify";
import i18next from "i18next";
import { customToast } from "../../shared";
import { custom, failure } from "./utilities";
import { paymentsServices } from "../../services";
import { globalSystemConstants, paymentsConstants } from "../../constants";

const {
  GET_PAYMENTS_DATA,
  SET_PAY_BUTTON_STATE,
  GET_TAX_AND_FEE,
  UPDATE_TAX_AND_FEE,
  GET_PAYMENT_DETAILS,
} = paymentsConstants;

const getPayments = (pageSize = 0, pageIndex = 0, searchText = "") => {
  return (dispatch) => {
    dispatch(custom(GET_PAYMENTS_DATA, globalSystemConstants.SPINNER_ACTIVATE));
    paymentsServices
      .getPayments(pageSize, pageIndex, searchText)
      .then(
        (response) => {
          dispatch(custom(response, GET_PAYMENTS_DATA));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_PAYMENTS_DATA));
        }
      )
      .finally(() => {
        dispatch(
          custom(GET_PAYMENTS_DATA, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const payShutterb = (orderId, id, setData) => {
  return (dispatch) => {
    dispatch(setPayButtonState(true));
    dispatch(custom(GET_PAYMENTS_DATA, globalSystemConstants.SPINNER_ACTIVATE));
    paymentsServices
      .payShutterb(orderId, id)
      .then(
        (response) => {
          dispatch(getPayments(0, 0, "", setData));
          customToast(i18next.t("payment_successful"), toast.TYPE.SUCCESS);
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_PAYMENTS_DATA));
        }
      )
      .finally(() => {
        dispatch(setPayButtonState(false));
        dispatch(
          custom(GET_PAYMENTS_DATA, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const setPayButtonState = (data) => {
  return (dispatch) => {
    dispatch(custom(data, SET_PAY_BUTTON_STATE));
  };
};

const getTaxAndFee = () => {
  return (dispatch) => {
    dispatch(custom(GET_TAX_AND_FEE, globalSystemConstants.SPINNER_ACTIVATE));
    paymentsServices
      .getTaxAndFee()
      .then(
        (response) => {
          dispatch(custom(response, GET_TAX_AND_FEE));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_TAX_AND_FEE));
        }
      )
      .finally(() => {
        dispatch(
          custom(GET_TAX_AND_FEE, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const updateTaxAndFee = (data) => {
  return (dispatch) => {
    dispatch(
      custom(UPDATE_TAX_AND_FEE, globalSystemConstants.SPINNER_ACTIVATE)
    );
    paymentsServices
      .updateTaxAndFee(data)
      .then(
        (response) => {
          const message = i18next.t("form_updated");
          customToast(message, toast.TYPE.SUCCESS);
          dispatch(custom(response, UPDATE_TAX_AND_FEE));
          //dispatch(getTaxAndFee());
        },
        (error) => {
          customToast(i18next.t(error[0]), toast.TYPE.ERROR);
          dispatch(failure(error, UPDATE_TAX_AND_FEE));
        }
      )
      .finally(() => {
        dispatch(
          custom(UPDATE_TAX_AND_FEE, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const getPaymentDetails = (orderId, shutterBId, closeViewDetailsModal) => {
  return (dispatch) => {
    dispatch(
      custom(GET_PAYMENT_DETAILS, globalSystemConstants.SPINNER_ACTIVATE)
    );
    paymentsServices
      .getPaymentDetails(orderId, shutterBId)
      .then(
        (response) => {
          dispatch(custom(response, GET_PAYMENT_DETAILS));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_PAYMENT_DETAILS));
          closeViewDetailsModal();
        }
      )
      .finally(() => {
        dispatch(
          custom(GET_PAYMENT_DETAILS, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

export const paymentsActions = {
  getPayments,
  payShutterb,
  getTaxAndFee,
  updateTaxAndFee,
  getPaymentDetails,
};
