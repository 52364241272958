import { postRequest, getRequest,putRequest } from "../system/server-call";
import responseHandler from "../system/server-response-handler";
import { loginConstants } from "../constants";
import {
  clearStorage,
  encryptObject,
  setItemToStorage,
  removeItemFromStorage,
} from "../system";

/**
 * The Auth Service
 *
 * This is used for getting data from server and handling the business logic on that data
 */
// logging out the user from the app and removing its information from browser
function logout() {
  removeItemFromStorage(process.env.REACT_APP_PAYLOAD);
  clearStorage();
  return Promise.resolve();
}

// handling the Login response, if user is authenticated then saving
// the jwt token in the local storage
function handleLoginResponse(response) {
  const { status } = response.data;

  if (status !== loginConstants.LOGIN_STATUS.SUCCESS) {
    logout();
    return responseHandler.handleServerResponse(response);
  }

  const ciphertext = encryptObject({
    ...response.data.payload,
  });

  setItemToStorage(process.env.REACT_APP_PAYLOAD, ciphertext);
  return response.data.payload;
}

// authenticating the user from server
function login(userData) {
  return postRequest("/api/v1/login/admin", userData, false).then(
    (response) => handleLoginResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

function resetPassword(email) {
  return postRequest("/api/v1/login/ForgotPassword", email, false).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

// authenticating the email from server
function emailVerification(queryParam) {
  return getRequest(`/api/v1/login/verifyemail?${queryParam}`, false).then(
    (response) => handleLoginResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

// updating the password
function changePassword(userData) {
  return putRequest(`/api/v1/shutterb/updatepassword`, userData, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

// exporting functions for other files
export const authService = {
  login,
  logout,
  resetPassword,
  changePassword,
  emailVerification,
};
