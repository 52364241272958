import i18next from "i18next";
import { toast } from "react-toastify";
import { customToast, getFilterExpression } from "../../shared";
import { custom, failure } from "./utilities";
import { configurationsServices, shutterBsServices } from "../../services";
import {
  globalSystemConstants,
  configurationsConstants,
  shutterBsStatusConstants,
} from "../../constants";

const {
  ADD_PROMO_CODES,
  GET_PROMO_CODES_DATA,
  SET_SUBMIT_BUTTON_STATE,
  GET_SMARTPHONE_DEVICES,
  ADD_SMARTPHONE_DEVICE,
  REMOVE_SMARTPHONE_DEVICE,
  REORDER_SMARTPHONE_DEVICES,
  GET_ALL_SHUTTERBS,
  GET_HOMEPAGE_SHUTTERBS,
  INSERT_HOMEPAGE_SHUTTERBS,
  REMOVE_HOMEPAGE_SHUTTERB,
  ACTIVATE_DEACTIVATE_PROMOCODE,
  GET_POSTAL_CODES,
  ADD_POSTAL_CODES,
  REMOVE_POSTAL_CODE,
  GET_PROMO_CODE_DATA_By_Id,
  UPDATE_PROMO_CODE,
  GET_PROMO_BALANCE_DATA,
  GET_GIFT_CARD_DATA,
  ACTIVATE_DEACTIVATE_GIFTCARD,
  ADD_GIFT_CARD,
} = configurationsConstants;

const addPromoCode = (data, setFormClose) => {
  return (dispatch) => {
    dispatch(setSubmitButtonState(true));
    dispatch(custom(ADD_PROMO_CODES, globalSystemConstants.SPINNER_ACTIVATE));
    configurationsServices
      .addPromoCode(data)
      .then(
        (response) => {
          if (response) {
            customToast(i18next.t(response), toast.TYPE.SUCCESS);
            dispatch(custom(response, ADD_PROMO_CODES));
            setFormClose();
          }
        },
        (error) => {
          customToast(i18next.t(error[0]), toast.TYPE.ERROR);
          dispatch(failure(error, ADD_PROMO_CODES));
        }
      )
      .finally(() => {
        dispatch(setSubmitButtonState(false));
        dispatch(
          custom(ADD_PROMO_CODES, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const updatePromoCode = (id, data, setFormClose) => {
  return (dispatch) => {
    dispatch(setSubmitButtonState(true));
    dispatch(custom(UPDATE_PROMO_CODE, globalSystemConstants.SPINNER_ACTIVATE));
    configurationsServices
      .updatePromoCode(id, data)
      .then(
        (response) => {
          if (response) {
            customToast("Promo Code Updated Successfully", toast.TYPE.SUCCESS);
            dispatch(custom(response, UPDATE_PROMO_CODE));
            setFormClose();
          }
        },
        (error) => {
          customToast(i18next.t(error[0]), toast.TYPE.ERROR);
          dispatch(failure(error, UPDATE_PROMO_CODE));
        }
      )
      .finally(() => {
        dispatch(setSubmitButtonState(false));
        dispatch(
          custom(UPDATE_PROMO_CODE, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const getPromoCode = (
  pageSize = configurationsConstants.DEFAULT_PAGINATION_PAGE_SIZE,
  pageIndex = 1,
  filterExpression = getFilterExpression("")
) => {
  return (dispatch) => {
    dispatch(
      custom(GET_PROMO_CODES_DATA, globalSystemConstants.SPINNER_ACTIVATE)
    );
    configurationsServices
      .getPromoCodes(pageSize, pageIndex, filterExpression)
      .then(
        (response) => {
          dispatch(custom(response, GET_PROMO_CODES_DATA));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_PROMO_CODES_DATA));
        }
      )
      .finally(() => {
        dispatch(
          custom(GET_PROMO_CODES_DATA, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const getGiftCards = (
  pageSize = configurationsConstants.DEFAULT_PAGINATION_PAGE_SIZE,
  pageIndex = 1,
  filterExpression = getFilterExpression("")
) => {
  return (dispatch) => {
    dispatch(
      custom(GET_GIFT_CARD_DATA, globalSystemConstants.SPINNER_ACTIVATE)
    );
    configurationsServices
      .getGiftCards(pageSize, pageIndex, filterExpression)
      .then(
        (response) => {
          dispatch(custom(response, GET_GIFT_CARD_DATA));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_GIFT_CARD_DATA));
        }
      )
      .finally(() => {
        dispatch(
          custom(GET_GIFT_CARD_DATA, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};


const getPromoBalance = (
  pageSize = configurationsConstants.DEFAULT_PAGINATION_PAGE_SIZE,
  pageIndex = 1,
  filterExpression = getFilterExpression("")
) => {
  return (dispatch) => {
    dispatch(
      custom(GET_PROMO_BALANCE_DATA, globalSystemConstants.SPINNER_ACTIVATE)
    );
    configurationsServices
      .getPromoBalance(pageSize, pageIndex, filterExpression)
      .then(
        (response) => {
          dispatch(custom(response, GET_PROMO_BALANCE_DATA));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_PROMO_BALANCE_DATA));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            GET_PROMO_BALANCE_DATA,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
};

const getPromoCodeById = (id) => {
  return (dispatch) => {
    dispatch(
      custom(GET_PROMO_CODE_DATA_By_Id, globalSystemConstants.SPINNER_ACTIVATE)
    );
    configurationsServices
      .getPromoCodeById(id)
      .then(
        (response) => {
          dispatch(custom(response, GET_PROMO_CODE_DATA_By_Id));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_PROMO_CODE_DATA_By_Id));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            GET_PROMO_CODE_DATA_By_Id,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
};

const setSubmitButtonState = (data) => {
  return (dispatch) => {
    dispatch(custom(data, SET_SUBMIT_BUTTON_STATE));
  };
};

const getSmartPhoneDevices = (
  pageSize = 0,
  pageIndex = 0,
  searchExpression = "",
  setData
) => {
  return (dispatch) => {
    dispatch(
      custom(GET_SMARTPHONE_DEVICES, globalSystemConstants.SPINNER_ACTIVATE)
    );
    configurationsServices
      .getSmartPhoneDevices(pageSize, pageIndex, searchExpression)
      .then(
        (response) => {
          if (response) {
            setData(response?.items);
            dispatch(custom(response, GET_SMARTPHONE_DEVICES));
          }
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_SMARTPHONE_DEVICES));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            GET_SMARTPHONE_DEVICES,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
};

const addSmartPhoneDevice = (
  pageSize = 0,
  pageIndex = 0,
  searchExpression = "",
  setData,
  data
) => {
  return (dispatch) => {
    dispatch(
      custom(ADD_SMARTPHONE_DEVICE, globalSystemConstants.SPINNER_ACTIVATE)
    );
    configurationsServices
      .addSmartPhoneDevice(data)
      .then(
        (message) => {
          customToast(i18next.t(message), toast.TYPE.SUCCESS);
          dispatch(
            getSmartPhoneDevices(pageSize, pageIndex, searchExpression, setData)
          );
        },
        (error) => {
          customToast(i18next.t(error[0]), toast.TYPE.ERROR);
          dispatch(failure(error, ADD_SMARTPHONE_DEVICE));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            ADD_SMARTPHONE_DEVICE,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
};

const removeSmartPhoneDevice = (
  pageSize = 0,
  pageIndex = 0,
  searchExpression = "",
  setData,
  data
) => {
  return (dispatch) => {
    dispatch(
      custom(REMOVE_SMARTPHONE_DEVICE, globalSystemConstants.SPINNER_ACTIVATE)
    );
    configurationsServices
      .removeSmartPhoneDevice(data)
      .then(
        (response) => {
          customToast(i18next.t("device_remove_success"), toast.TYPE.SUCCESS);
          dispatch(
            getSmartPhoneDevices(pageSize, pageIndex, searchExpression, setData)
          );
        },
        (error) => {
          customToast(i18next.t(error[0]), toast.TYPE.ERROR);
          dispatch(failure(error, REMOVE_SMARTPHONE_DEVICE));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            REMOVE_SMARTPHONE_DEVICE,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
};

const reorderSmartPhoneDevices = (
  pageSize = 0,
  pageIndex = 0,
  searchExpression = "",
  setData,
  data,
  setDraggedIndex
) => {
  return (dispatch) => {
    dispatch(
      custom(REORDER_SMARTPHONE_DEVICES, globalSystemConstants.SPINNER_ACTIVATE)
    );
    configurationsServices
      .reorderSmartPhoneDevices(data)
      .then(
        (message) => {
          customToast(i18next.t(message), toast.TYPE.SUCCESS);
          setDraggedIndex(null);
          dispatch(
            getSmartPhoneDevices(pageSize, pageIndex, searchExpression, setData)
          );
        },
        (error) => {
          customToast(i18next.t(error[0]), toast.TYPE.ERROR);
          dispatch(failure(error, REORDER_SMARTPHONE_DEVICES));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            REORDER_SMARTPHONE_DEVICES,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
};

const getAllShutterBs = (homepageShutterbs) => {
  return (dispatch) => {
    dispatch(custom(GET_ALL_SHUTTERBS, globalSystemConstants.SPINNER_ACTIVATE));
    shutterBsServices
      .getShutterBs()
      .then(
        (response) => {
          const filteredData = {
            ...response,
            items: response.items.filter(
              (item) =>
                item.status === shutterBsStatusConstants.APPROVED &&
                item.isActive
            ),
          };
          if (
            homepageShutterbs &&
            homepageShutterbs.items &&
            homepageShutterbs.items.length > 0
          ) {
            const homepageShutterbsIds = homepageShutterbs.items.map(
              (item) => item.id
            );

            const filteredItems = filteredData.items.filter(
              (item) => !homepageShutterbsIds.includes(item.id)
            );
            const newFilteredData = {
              ...filteredData,
              items: filteredItems,
            };

            dispatch(custom(newFilteredData, GET_ALL_SHUTTERBS));
          } else {
            dispatch(custom(filteredData, GET_ALL_SHUTTERBS));
          }
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_ALL_SHUTTERBS));
        }
      )
      .finally(() => {
        dispatch(
          custom(GET_ALL_SHUTTERBS, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const getAllShutterBsForReels = (homepageShutterbs) => {
  return (dispatch) => {
    dispatch(custom(GET_ALL_SHUTTERBS, globalSystemConstants.SPINNER_ACTIVATE));
    shutterBsServices
      .getAllShutterBsForReels(homepageShutterbs)
      .then(
        (response) => {
          if (
            homepageShutterbs &&
            homepageShutterbs.items &&
            homepageShutterbs.items.length > 0
          ) {
            const homepageShutterbsIds = homepageShutterbs.items.map(
              (item) => item.id
            );

            const filteredItems = response.items.filter(
              (item) => !homepageShutterbsIds.includes(item.id)
            );
            const newFilteredData = {
              ...response,
              items: filteredItems,
            };

            dispatch(custom(newFilteredData, GET_ALL_SHUTTERBS));
          } else {
            dispatch(custom(response, GET_ALL_SHUTTERBS));
          }
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_ALL_SHUTTERBS));
        }
      )
      .finally(() => {
        dispatch(
          custom(GET_ALL_SHUTTERBS, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const getHomepageShutterbs = () => {
  return (dispatch) => {
    dispatch(
      custom(GET_HOMEPAGE_SHUTTERBS, globalSystemConstants.SPINNER_ACTIVATE)
    );
    configurationsServices
      .getHomepageShutterbs()
      .then(
        (response) => {
          dispatch(getAllShutterBsForReels(response));
          dispatch(custom(response, GET_HOMEPAGE_SHUTTERBS));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_HOMEPAGE_SHUTTERBS));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            GET_HOMEPAGE_SHUTTERBS,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
};

const addShutterBsToHomePage = (data) => {
  return (dispatch) => {
    dispatch(
      custom(INSERT_HOMEPAGE_SHUTTERBS, globalSystemConstants.SPINNER_ACTIVATE)
    );
    configurationsServices
      .addShutterBsToHomePage(data)
      .then(
        (message) => {
          customToast(i18next.t(message), toast.TYPE.SUCCESS);
          dispatch(getHomepageShutterbs());
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, INSERT_HOMEPAGE_SHUTTERBS));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            INSERT_HOMEPAGE_SHUTTERBS,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
};

const removeShutterBFromHomePage = (id) => {
  return (dispatch) => {
    dispatch(
      custom(REMOVE_HOMEPAGE_SHUTTERB, globalSystemConstants.SPINNER_ACTIVATE)
    );
    configurationsServices
      .removeShutterBFromHomePage(id)
      .then(
        (message) => {
          customToast(i18next.t("remove_from_website"), toast.TYPE.SUCCESS);
          dispatch(getHomepageShutterbs());
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, REMOVE_HOMEPAGE_SHUTTERB));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            REMOVE_HOMEPAGE_SHUTTERB,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
};

const activateDeactivateCode = (id, isActive) => {
  return (dispatch) => {
    dispatch(
      custom(
        ACTIVATE_DEACTIVATE_PROMOCODE,
        globalSystemConstants.SPINNER_ACTIVATE
      )
    );
    configurationsServices
      .activateDeactivateCode(id)
      .then(
        (response) => {
          if (response) {
            if (isActive) {
              customToast(
                i18next.t("promocode_deactivated"),
                toast.TYPE.SUCCESS
              );
            } else {
              customToast(i18next.t("promocode_activated"), toast.TYPE.SUCCESS);
            }
            dispatch(custom(response, ACTIVATE_DEACTIVATE_PROMOCODE));
            dispatch(getPromoCode());
          }
        },
        (error) => {
          customToast(i18next.t(error[0]), toast.TYPE.ERROR);
          dispatch(failure(error, ACTIVATE_DEACTIVATE_PROMOCODE));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            ACTIVATE_DEACTIVATE_PROMOCODE,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
};

const getPostalCodes = (pageSize = 0, pageIndex = 0, searchExpression = "") => {
  return (dispatch) => {
    dispatch(custom(GET_POSTAL_CODES, globalSystemConstants.SPINNER_ACTIVATE));
    configurationsServices
      .getPostalCodes(pageSize, pageIndex, searchExpression)
      .then(
        (response) => {
          if (response) {
            dispatch(custom(response, GET_POSTAL_CODES));
          }
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_POSTAL_CODES));
        }
      )
      .finally(() => {
        dispatch(
          custom(GET_POSTAL_CODES, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const addPostalCodes = (
  pageSize = 0,
  pageIndex = 0,
  searchExpression = "",
  data
) => {
  return (dispatch) => {
    dispatch(custom(ADD_POSTAL_CODES, globalSystemConstants.SPINNER_ACTIVATE));
    configurationsServices
      .addPostalCodes(data)
      .then(
        (response) => {
          if (response) {
            customToast(i18next.t(response.items), toast.TYPE.SUCCESS);
          }
          dispatch(getPostalCodes(pageSize, pageIndex, searchExpression));
        },
        (error) => {
          customToast(i18next.t(error[0]), toast.TYPE.ERROR);
          dispatch(failure(error, ADD_POSTAL_CODES));
        }
      )
      .finally(() => {
        dispatch(
          custom(ADD_POSTAL_CODES, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const removePostalCode = (
  pageSize = 0,
  pageIndex = 0,
  searchExpression = "",
  data
) => {
  return (dispatch) => {
    dispatch(
      custom(REMOVE_POSTAL_CODE, globalSystemConstants.SPINNER_ACTIVATE)
    );
    configurationsServices
      .removePostalCode(data)
      .then(
        (response) => {
          customToast(
            i18next.t("postal_code_remove_success"),
            toast.TYPE.SUCCESS
          );
          dispatch(getPostalCodes(pageSize, pageIndex, searchExpression));
        },
        (error) => {
          customToast(i18next.t(error[0]), toast.TYPE.ERROR);
          dispatch(failure(error, REMOVE_POSTAL_CODE));
        }
      )
      .finally(() => {
        dispatch(
          custom(REMOVE_POSTAL_CODE, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const giftCardToggleActivation = (id) => {
  return (dispatch) => {
    dispatch(
      custom(
        ACTIVATE_DEACTIVATE_GIFTCARD,
        globalSystemConstants.SPINNER_ACTIVATE
      )
    );
    configurationsServices
      .giftCardToggleActivation(id)
      .then(
        (response) => {
          if (response) {
            customToast(i18next.t(response), toast.TYPE.SUCCESS);
            dispatch(custom(response, ACTIVATE_DEACTIVATE_GIFTCARD));
            dispatch(getGiftCards());
          }
        },
        (error) => {
          customToast(i18next.t(error[0]), toast.TYPE.ERROR);
          dispatch(failure(error, ACTIVATE_DEACTIVATE_GIFTCARD));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            ACTIVATE_DEACTIVATE_GIFTCARD,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
};

const addGiftCard = (data, setFormClose) => {
  return (dispatch) => {
    dispatch(setSubmitButtonState(true));
    dispatch(custom(ADD_GIFT_CARD, globalSystemConstants.SPINNER_ACTIVATE));
    configurationsServices
      .addGiftCard(data)
      .then(
        (response) => {
          if (response) {
            customToast(i18next.t(response.items), toast.TYPE.SUCCESS);
            dispatch(custom(response.items, ADD_GIFT_CARD));
            setFormClose();
          }
        },
        (error) => {
          customToast(i18next.t(error[0]), toast.TYPE.ERROR);
          dispatch(failure(error, ADD_GIFT_CARD));
        }
      )
      .finally(() => {
        dispatch(setSubmitButtonState(false));
        dispatch(
          custom(ADD_GIFT_CARD, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

export const configurationsActions = {
  addPromoCode,
  getPromoCode,
  setSubmitButtonState,
  getSmartPhoneDevices,
  addSmartPhoneDevice,
  removeSmartPhoneDevice,
  reorderSmartPhoneDevices,
  getAllShutterBs,
  addShutterBsToHomePage,
  getHomepageShutterbs,
  removeShutterBFromHomePage,
  getAllShutterBsForReels,
  activateDeactivateCode,
  getPostalCodes,
  addPostalCodes,
  removePostalCode,
  getPromoCodeById,
  updatePromoCode,
  getPromoBalance,
  getGiftCards,
  giftCardToggleActivation,
  addGiftCard,
};
