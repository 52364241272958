import { updateObject } from "../../shared";
import { globalSystemConstants } from "../../constants";

const {
  SPINNER_ACTIVATE,
  SPINNER_DIACTIVATE,
  OVERLAY_ACTIVATE,
  OVERLAY_DIACTIVATE,
  DOWNLOAD_SPINNER_ACTIVATE,
  DOWNLOAD_SPINNER_DIACTIVATE,
} = globalSystemConstants;

const initialState = {
  spinnerActions: [],
  isOverlayActivate: false,
  isDownloadSpinnerActivate: false,
};

// default reducer i.e. ux manupilation
export const system = (state = initialState, action) => {
  switch (action.type) {
    case SPINNER_ACTIVATE:
      const actionsArr = [...state.spinnerActions];
      actionsArr.push(action.data);
      return updateObject(state, {
        spinnerActions: actionsArr,
      });
    case SPINNER_DIACTIVATE:
      return updateObject(state, {
        spinnerActions: state.spinnerActions.filter((f) => f !== action.data),
      });
    case DOWNLOAD_SPINNER_ACTIVATE:
      return updateObject(state, {
        isDownloadSpinnerActivate: true,
      });
    case DOWNLOAD_SPINNER_DIACTIVATE:
      return updateObject(state, {
        isDownloadSpinnerActivate: false,
      });
    case OVERLAY_ACTIVATE:
      return updateObject(state, {
        isOverlayActivate: true,
      });
    case OVERLAY_DIACTIVATE:
      return updateObject(state, {
        isOverlayActivate: false,
      });
    default:
      return state;
  }
};
