import React, { useMemo } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import LoadingIcon from "mdi-react/LoadingIcon";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { loginConstants } from "../../../constants";
import { loginActions } from "../../../redux/actions";

const ResetPasswordForm = (props) => {
  const { t } = useTranslation("common");

  const loginSpinner = useMemo(
    () => props.spinnerActions.findIndex((f) => f === loginConstants.LOGIN),
    [props.spinnerActions]
  );

  const handleSubmit = (event, values) => {
    props.resetPassword({ userTypeId: 1, ...values });
  };

  return (
    <AvForm
      className="form form_Display_Flex login-form"
      onValidSubmit={handleSubmit}
    >
      <div className="form__form-group pr-0">
        <span className="form__form-group-label">{t("email")}</span>
        <div className="form__form-group-field">
          <div className="input-without-border-radius">
            <AvField
              type="email"
              name="email"
              required
              className="input-without-border-radius"
            />
          </div>
        </div>
      </div>
      <div className="form__form-group pr-0 mt-3">
        <div className="form__form-group-field">
          <div className="account__btns ml-0">
            {loginSpinner > -1 ? (
              <div className="account__btn text-center reset-password login_spinner">
                <LoadingIcon className="loading-spinner" />
              </div>
            ) : (
              <Button className="account__btn" type="submit" color="primary">
                {t("reset_password")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </AvForm>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  spinnerActions: state.system.spinnerActions,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  resetPassword: (email) => dispatch(loginActions.resetPassword(email)),
});

// mapping action and store the function via props
export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm);
