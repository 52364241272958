import Dashboard from "../views/Dashboard/Dashboard";
import ShutterBs from "../views/ShutterBs/ShutterBs";
import Payments from "../views/Payments/Payments";
import Events from "../views/Events/Events";
import PromoCodes from "../views/Discounts/PromoCodes/PromoCodesPage";
import ChangePassword from "../container/Views/ChangePassword";
import TaxAndFee from "../views/TaxAndFee/TaxAndFee";
import SmartPhoneDevices from "../views/Configurations/SmartPhoneDevices/SmartPhoneDevices";
import Customers from "../views/Customers/Customers";
import HomePageShutterbs from "../views/HomePageShutterbs/HomePageShutterbs";
import Reels from "../views/Reels/Reels";
import Reports from "../views/Reports/Reports";
import PostalCodes from "../views/Configurations/PostalCodes/PostalCodes";
import DraftEvents from "../views/DraftEvents/DraftEvents";
import AffiliateLinks from "../views/Marketing/Affiliate/AffiliatePage";
import AffiliateReports from "../views/Marketing/Affiliate/AffiliateReport";
import GiftCard from "../views/Discounts/GiftCard/GiftCardPage";
import ReelTypes from "../views/Reels/ReelTypes";
import MonitoringReport from "../views/MonitoringReport/MonitoringReport";

const routes = [
  {
    screenId: 1,
    path: `/Dashboard`,
    component: Dashboard,
  },
  {
    screenId: 2,
    path: `/Dashboard/ShutterBs/:tab`,
    component: ShutterBs,
  },
  {
    screenId: 3,
    path: `/Dashboard/Events`,
    component: Events,
  },
  {
    screenId: 4,
    path: `/Dashboard/Reports`,
    component: Reports,
  },
  {
    screenId: 5,
    path: `/Dashboard/Payments`,
    component: Payments,
  },
  {
    screenId: 6,
    path: `/Dashboard/PromoCodes`,
    component: PromoCodes,
  },
  {
    screenId: 7,
    path: `/change_password`,
    component: ChangePassword,
  },
  {
    screenId: 8,
    path: `/Dashboard/TaxAndFee`,
    component: TaxAndFee,
  },
  {
    screenId: 9,
    path: `/Dashboard/SmartPhoneDevices`,
    component: SmartPhoneDevices,
  },
  {
    screenId: 10,
    path: `/Dashboard/Customers`,
    component: Customers,
  },
  {
    screenId: 11,
    path: `/Dashboard/HomePageShutterbs`,
    component: HomePageShutterbs,
  },
  {
    screenId: 12,
    path: `/Dashboard/Reels`,
    component: Reels,
  },
  {
    screenId: 13,
    path: `/Dashboard/PostalCodes`,
    component: PostalCodes,
  },
  {
    screenId: 14,
    path: `/Dashboard/DraftEvents`,
    component: DraftEvents,
  },
  {
    screenId: 15,
    path: `/Dashboard/CompletedEvents`,
    component: Events,
  },
  {
    screenId: 16,
    path: `/Dashboard/UpcomingEvents`,
    component: Events,
  },
  {
    screenId: 17,
    path: `/Dashboard/CanceledEvents`,
    component: Events,
  },
  {
    screenId: 18,
    path: `/Dashboard/AffiliateLinks`,
    component: AffiliateLinks,
  },
  {
    screenId: 19,
    path: `/Dashboard/AffiliateReports`,
    component: AffiliateReports,
  },
  // {
  //   screenId: 20,
  //   path: `/Dashboard/PromoBalance`,
  //   component: PromoBalance,
  // },
  {
    screenId: 21,
    path: `/Dashboard/GiftCard`,
    component: GiftCard,
  },
  {
    screenId: 22,
    path: `/Dashboard/ReelTypes`,
    component: ReelTypes,
  },
  {
    screenId: 23,
    path: `/Dashboard/MonitoringReport`,
    component: MonitoringReport,
  },
];

export default routes;
