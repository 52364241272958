import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AvForm } from "availity-reactstrap-validation";
import { reportConstants } from "../../constants";
import DatePickerWrapper from "../../shared/components/DatePicker/DatePickerWrapper";
import {
  convertDateObjectToDate,
  convertDateToEpoch,
  DownloadExcel,
  fileName,
} from "../../shared";
import { connect } from "react-redux";
import { monitoringReportActions } from "../../redux/actions";
import LoadingIcon from "mdi-react/LoadingIcon";

const MonitoringReport = (props) => {
  const { t } = useTranslation("common");

  const {
    reportsData,
    setReportData,
    getEventReportData,
    getShutterBReportData,
    getCustomerReportData,
  } = props;

  const currentDate = new Date(new Date().setDate(new Date().getDate()));

  const {
    REPORT_DATA,
    EVENT_REPORT,
    CUSTOMER_REPORT,
    SHUTTERB_REPORT,
    EVENT_REPORT_NAME,
    CUSTOMER_REPORT_NAME,
    SHUTTERB_REPORT_NAME,
  } = reportConstants;

  const spinner = useCallback(
    (type) => props.spinnerActions.findIndex((f) => f === type),
    [props.spinnerActions]
  );

  const [reportName, setReportName] = useState(null);
  const [isToDateValid, setIsToDateValid] = useState(true);
  const [selectedToDate, setSelectedToDate] = useState(null);
  const [isFromDateValid, setIsFromDateValid] = useState(true);
  const [selectedFromDate, setSelectedFromDate] = useState(null);

  const handleSubmit = (report) => {
    let isValid = validateDate();
    if (!isValid) {
      return;
    }
    if (selectedFromDate && selectedToDate) {
      let dataToSend = {
        fromDate: convertDateToEpoch(
          convertDateObjectToDate(selectedFromDate, true)
        ),
        toDate: convertDateToEpoch(
          convertDateObjectToDate(selectedToDate, false, true)
        ),
      };

      if (report === EVENT_REPORT) {
        setReportName(EVENT_REPORT_NAME);
        getEventReportData(dataToSend);
      } else if (report === CUSTOMER_REPORT) {
        setReportName(CUSTOMER_REPORT_NAME);
        getCustomerReportData(dataToSend);
      } else if (report === SHUTTERB_REPORT) {
        setReportName(SHUTTERB_REPORT_NAME);
        getShutterBReportData(dataToSend);
      }
    }
  };

  const validateDate = () => {
    let isValid = true;
    if (!selectedFromDate) {
      setIsFromDateValid(false);
      isValid = false;
    }
    if (!selectedToDate) {
      setIsToDateValid(false);
      isValid = false;
    }
    if (
      selectedFromDate &&
      selectedToDate &&
      selectedFromDate > selectedToDate
    ) {
      setIsFromDateValid(false);
      setIsToDateValid(false);
      isValid = false;
    }

    return isValid;
  };

  const exportToExcel = async (excelData) => {
    if (excelData.length > 0) {
      const exportData = [...excelData];
      DownloadExcel(exportData, fileName(reportName), true);
      setReportData({ items: [] });
    }
  };

  useEffect(() => {
    setReportData({ items: [] });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (reportsData?.length > 0) {
      exportToExcel(reportsData);
    }
    // eslint-disable-next-line
  }, [reportsData]);

  return (
    <div className="row mr-0">
      <div className="col-md-12">
        <div className="col-md-12 mx-0">
          <div className="tab-content">
            {spinner(REPORT_DATA) ? (
              <div className="tab-pane fade show active">
                <AvForm className="form">
                  <div className="row">
                    <div className="col-md-6 col-12 mb-2">
                      <label htmlFor="start_date">{t("start_date")}</label>
                      <span className="required-class"> *</span>
                      <DatePickerWrapper
                        selectedDate={selectedFromDate}
                        setSelectedDate={(e) => {
                          setSelectedFromDate(e);
                        }}
                        maxDate={currentDate}
                        setIsDateValid={setIsFromDateValid}
                        isDateValid={isFromDateValid}
                      />
                    </div>
                    <div className="col-md-6 col-12 mb-2">
                      <label htmlFor="end_Date">{t("end_date")}</label>
                      <span className="required-class"> *</span>
                      <DatePickerWrapper
                        selectedDate={selectedToDate}
                        setSelectedDate={(e) => {
                          setSelectedToDate(e);
                        }}
                        maxDate={currentDate}
                        setIsDateValid={setIsToDateValid}
                        isDateValid={isToDateValid}
                      />
                    </div>
                    <div className="col-12 mt-3 justify-content-center d-flex">
                      <button
                        type="button"
                        className="btn btn-info"
                        onClick={() => handleSubmit(EVENT_REPORT)}
                      >
                        {t("generate_event_report")}
                      </button>
                      <button
                        type="button"
                        className="btn btn-info"
                        onClick={() => handleSubmit(CUSTOMER_REPORT)}
                      >
                        {t("generate_customer_report")}
                      </button>
                      <button
                        type="button"
                        className="btn btn-info"
                        onClick={() => handleSubmit(SHUTTERB_REPORT)}
                      >
                        {t("generate_shutterB_report")}
                      </button>
                    </div>
                  </div>
                </AvForm>
              </div>
            ) : (
              <div className="text-center mt-4">
                <LoadingIcon className="loading-spinner" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  reportsData: state.monitoringReport.reportsData,
  spinnerActions: state.system.spinnerActions,
});

const mapDispatchToProps = (dispatch) => ({
  setReportData: (data) =>
    dispatch(monitoringReportActions.setReportData(data)),
  getEventReportData: (data) =>
    dispatch(monitoringReportActions.getEventReportData(data)),
  getShutterBReportData: (data) =>
    dispatch(monitoringReportActions.getShutterBReportData(data)),
  getCustomerReportData: (data) =>
    dispatch(monitoringReportActions.getCustomerReportData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MonitoringReport);
