import { getDecryptPayload } from "../system";

export function authHeader() {
  // return authorization header with jwt token
  const payload = getDecryptPayload();

  const userToken = payload.token || "";

  if (userToken && userToken.length > 0) {
    return {
      Authorization: `Bearer ${userToken}`,
    };
  }
  return {};
}
