import React, {useState, useEffect} from "react";
import Modal from "react-modal";
import { messageBoardActions } from "../../../redux/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import MessageBoardView from "../../../shared/components/MessageBoard/MessageBoardView";
import { Row, Col, Nav, NavLink, NavItem } from "reactstrap";

const MessageBoardModal = (props) => {
  const [currentActiveId, setCurrentActiveId] = useState(0);
    const [isChatStarted, setIsChatStarted] = useState(false);
    useEffect(() => {
      props.getshutterbzByEventId(props.currentEvent);
      // eslint-disable-next-line
    }, []);

    const handleSubmit = (sbId) => {
      setIsChatStarted(true);
      setCurrentActiveId(sbId);
      props.getMessages(props.currentEvent, sbId);
      props.setFormState({
        ...props.formState,
        userId: props.data.userId,
        sbUserId: sbId,
        eventId: props.currentEvent,
      });
    };
  return (
    <Modal
      isOpen={props.isModalOpen}
      onRequestClose={() => props.setIsModalOpen(false)}
      className={props.className}
    >
      <span className="close" onClick={() => props.setIsModalOpen(false)}>
        x
      </span>
      <>
        {/* <div className="container-fluid main-con"> */}

        <div className="container-vh">
          <div className="row w-100 m-0">
            <Row className="my-3">
              <Col>
                <Nav tabs style={{ height: "31px" }}>
                  {props.data.sbInfo?.map((item) => {
                    return (
                      <NavItem>
                        <NavLink
                          className={`${
                            currentActiveId === item.id ? "active" : ""
                          }`}
                          onClick={() => {
                            handleSubmit(item.id);
                          }}
                          style={{ padding: "13px" }}
                        >
                          {item.firstName + " " + item.lastName}
                        </NavLink>
                      </NavItem>
                    );
                  })}
                </Nav>
              </Col>
            </Row>
          </div>
          <div className="row w-100 m-0">
            <div className="col-md-12 p-0 my-2">
              {/* <div className="text-center m-3">
              <p className="p-0 m-0 font-12">
                {props.data.eventName}
                <br />
                {convertDateToUTC(props.data?.eventDate)}
                <br />
                <b> Event ID:</b> {props.data?.id}
              </p>
            </div> */}
              {isChatStarted ? (
                <MessageBoardView
                  currentActiveId={currentActiveId}
                  eventId={props.currentEvent}
                />
              ) : (
                <h6 className="d-flex justify-content-center pt-5">
                  See Conversation Between Host & shutterb
                </h6>
              )}
            </div>
          </div>
        </div>
        {/* </div> */}
      </>
    </Modal>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  data: state.messageBoard.data,
  formState: state.messageBoard.formState,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getshutterbzByEventId: (eventId) =>
    dispatch(messageBoardActions.getshutterbByEventId(eventId)),
  setFormState: (data) => dispatch(messageBoardActions.setFormState(data)),
  getMessages: (eventId, sbUserId) =>
    dispatch(messageBoardActions.getMessages(eventId, sbUserId)),
});

// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MessageBoardModal));
