import React, { Fragment } from "react";

const MainWrapper = ({ children }) => {
  return (
    <Fragment>
      <div className="theme-light ltr-support">
        <div className="main-container">{children}</div>
      </div>
    </Fragment>
  );
};

export default MainWrapper;
