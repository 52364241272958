import React, { useEffect, useCallback } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import LoadingIcon from "mdi-react/LoadingIcon";
import { Calendar } from "react-multi-date-picker";
import { shutterBsActions } from "../../redux/actions";
import dummypic from "../../shared/img/dummyPic.jpg";
import { shutterBsConstants, slotsInDay, daysOfWeek } from "../../constants";
import { AvForm, AvField } from "availity-reactstrap-validation";
import DataTable from "react-data-table-component";

const { GET_SHUTTER_B_BY_ID } = shutterBsConstants;
const columns = [
  {
    name: "Date",
    selector: "date",
    sortable: true,
    width: "160px",
    cell: (row) => {
      return row.dateTo !== null
        ? row.dateTo
        : new Date(row.date * 1000).toDateString();
    },
  },
  {
    name: "Start Time",
    selector: "startTime",
    sortable: true,
    width: "170px",
    cell: (row) => {
      return row.startTime === null
        ? "--"
        : new Date(row.startTime * 1000).toLocaleTimeString();
    },
  },
  {
    name: "End Time",
    selector: "endTime",
    sortable: true,
    width: "135px",
    cell: (row) => {
      return row.endTime === null
        ? "--"
        : new Date(row.endTime * 1000).toLocaleTimeString();
    },
  },
];
const customStyles = {
  headCells: {
    style: {
      fontWeight: "bold",
    },
  },
};
const ViewShutterBDetail = (props) => {
  const { t } = useTranslation("common");

  const spinner = useCallback(
    (type) => props.spinnerActions.findIndex((f) => f === type),
    [props.spinnerActions]
  );

  useEffect(() => {
    props.getShutterbById(props.currentSb);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {spinner(GET_SHUTTER_B_BY_ID) ? (
        <div className="tab-pane fade show active form-back-color">
          <div className="event-setup-form-box shutterb-form px-2 px-md-5">
            <h4 className="text-center shutterb-form-header pt-4">
              {t("sb_details_title")}
            </h4>
            <div className="row text-center">
              <div className="col-lg-12">
                <img
                  src={
                    props?.profileImage
                      ? `${process.env.REACT_APP_S3_BASE_URL_PORTFOLIO}/${props?.profileImage}`
                      : dummypic
                  }
                  alt=""
                  className="profile-img-view obj-fit-cover"
                />
              </div>
            </div>
            <div className="row text-center pt-1">
              <div className="col-lg-12">
                <h6>
                  {t("stripe_connected")}
                  {props.shutterbDetails?.isStripeConnected ? (
                    <i
                      className="fa fa-check-circle pl-1"
                      aria-hidden="true"
                      style={{ color: "#1ede21", fontSize: "17px" }}
                    ></i>
                  ) : (
                    <i
                      className="fa fa-times-circle pl-1"
                      aria-hidden="true"
                      style={{ color: "red", fontSize: "17px" }}
                    ></i>
                  )}
                </h6>
              </div>
            </div>
            <hr className="my-4" />
            <AvForm id="event-form">
              <div id="form-content">
                <div className="row">
                  <div className="col-lg-6">
                    <label htmlFor="firstName">{t("first_name")}</label>
                    <AvField
                      name="firstName"
                      type="text"
                      value={props.shutterbDetails?.firstName}
                      readOnly
                    />
                  </div>
                  <div className="col-lg-6">
                    <label htmlFor="lastName">{t("last_name")}</label>
                    <AvField
                      name="lastName"
                      type="text"
                      value={props.shutterbDetails?.lastName}
                      readOnly
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 icon-field-con">
                    <AvField
                      type="text"
                      name="current_address"
                      label={t("current_address")}
                      value={
                        props.shutterbDetails?.address
                          ? props.shutterbDetails?.address[0]?.currentLocation
                          : null
                      }
                      readOnly
                    ></AvField>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 icon-field-con">
                    <AvField
                      type="text"
                      name="distance"
                      label={t("distance_willing_to_travel")}
                      value={
                        props.shutterbDetails?.distance
                          ? props.shutterbDetails?.distance[0]
                              ?.minDistanceToTravel +
                            " " +
                            props.shutterbDetails?.distance[0]?.unit
                          : null
                      }
                      readOnly
                    ></AvField>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <label htmlFor="email">{t("email_address")}</label>
                    <AvField
                      name="email"
                      type="email"
                      readOnly
                      value={props.shutterbDetails?.email}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <label htmlFor="instagramHandle">
                      {t("instagram_handle_form")}
                    </label>
                    <AvField
                      name="instagramHandle"
                      type="text"
                      readOnly
                      value={props.shutterbDetails?.instagramHandle}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <label htmlFor="phoneNumber">
                      {t("phone_number_form")}
                    </label>
                    <AvField
                      name="phoneNumber"
                      type="number"
                      readOnly
                      value={props.shutterbDetails?.phoneNumber}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 icon-field-con">
                    <AvField
                      type="text"
                      name="currentSmartphone"
                      label={t("current_smartphone")}
                      value={props.shutterbDetails?.device?.deviceName}
                      readOnly
                    ></AvField>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <label htmlFor="qType1">{t("shutterb_question_1")}</label>
                    <p className="p-0 m-0 question-des">
                      {t("shutterb_question_1_description")}
                    </p>
                    <AvField
                      name="qType1"
                      type="textarea"
                      readOnly
                      value={
                        props.shutterbDetails?.questioniers
                          ? props.shutterbDetails?.questioniers[0].answer
                          : null
                      }
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <label htmlFor="qType2">{t("shutterb_question_2")}</label>
                    <p className="p-0 m-0 question-des">
                      {t("shutterb_question_2_description")}
                    </p>
                    <AvField
                      name="qType2"
                      type="textarea"
                      readOnly
                      value={
                        props.shutterbDetails?.questioniers
                          ? props.shutterbDetails?.questioniers[1].answer
                          : null
                      }
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <label htmlFor="qType3">{t("shutterb_question_3")}</label>
                    <p className="p-0 m-0 question-des">
                      {t("shutterb_question_3_description")}
                    </p>
                    <AvField
                      name="qType3"
                      type="textarea"
                      readOnly
                      value={
                        props.shutterbDetails?.questioniers
                          ? props.shutterbDetails?.questioniers[2].answer
                          : null
                      }
                    />
                  </div>
                </div>
                {props.shutterbDetails?.questioniers &&
                  props.shutterbDetails?.questioniers.length === 4 && (
                    <div className="row">
                      <div className="col-lg-12">
                        <label htmlFor="qType4">
                          {t("shutterb_question_4")}
                        </label>
                        <p className="p-0 m-0 question-des">
                          {t("shutterb_question_4_description")}
                        </p>
                        <AvField
                          name="qType4"
                          type="textarea"
                          readOnly
                          value={
                            props.shutterbDetails?.questioniers[3]?.answer ??
                            null
                          }
                        />
                      </div>
                    </div>
                  )}

                <div className="row">
                  <div className="col-lg-12">
                    <div className="pr-3 image-preview d-flex flex-wrap">
                      {props.gallery.map((file, index) => (
                        <div
                          key={index}
                          className="col-6 col-md-4 col-lg-3 mb-3 pl-0"
                        >
                          <div className="image-wrapper">
                            <img
                              src={`${process.env.REACT_APP_S3_BASE_URL_PORTFOLIO}/${file.filePath}`}
                              alt={file.id}
                              className="p-1 img-size cursor-pointer"
                              onClick={() =>
                                (window.location.href = `${process.env.REACT_APP_S3_BASE_URL_PORTFOLIO}/${file.filePath}`)
                              }
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="row">
                  {props.shutterbDetails?.hstNumber && (
                    <div className="col-lg-12 icon-field-con">
                      <AvField
                        type="text"
                        name="hstNumber"
                        label={t("hst_number")}
                        value={props.shutterbDetails?.hstNumber}
                        readOnly
                      ></AvField>
                    </div>
                  )}
                </div>
                {props?.workingSchedules &&
                  props?.workingSchedules.length > 0 && (
                    <div className="py-3">
                      <h6 className="sch-sub-heading">
                        {t("working_schedule")}
                      </h6>
                      <div class="check-table-cont pl-2">
                        <table>
                          <thead>
                            <tr>
                              {/* <th></th> */}
                              <th></th>
                              {daysOfWeek.map((day, index) => (
                                <th key={index}>{day}</th>
                              ))}
                            </tr>
                          </thead>
                          {slotsInDay.map((e, key) => (
                            <tr>
                              {/* <td>{timesOfTheDay[key]}</td> */}
                              <td>{e}</td>

                              {props?.workingSchedules &&
                                props?.workingSchedules.map((day, key2) => {
                                  return (
                                    <td>
                                      <div class="form-group">
                                        <div class="checkbox check_custom">
                                          <label>
                                            <input
                                              type="checkbox"
                                              checked={
                                                day?.timeSlots?.[key].isEnable
                                              }
                                            />
                                            <span class="check_box"></span>
                                          </label>
                                        </div>
                                      </div>
                                    </td>
                                  );
                                })}
                            </tr>
                          ))}
                        </table>
                      </div>
                      <div class="table-btm-content">
                        <ul className="d-flex justify-content-center flex-wrap">
                          <li className="p-0 m-0 d-flex justify-content-between align-items-center">
                            <div className="avalaible-sign"></div>
                            <p className="p-0 m-0"> Available </p>
                          </li>

                          <li className="p-0 m-0 ml-3 d-flex justify-content-between align-items-center">
                            <div className="not-avalaible-sign"></div>
                            <p className="p-0 m-0"> Not Available </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}

                <div className="row py-3">
                  <div className="col-md-6">
                    <h6 className="sch-sub-heading">Blackout Days</h6>
                    <Calendar
                      value={props?.blackoutDays?.displayDates}
                      readOnly
                    ></Calendar>
                  </div>
                  <div className="col-md-6">
                    <DataTable
                      columns={columns}
                      data={props?.blackoutDays?.holidayDetails}
                      highlightOnHover
                      responsive
                      customStyles={{
                        ...customStyles,
                        table: "tbl-holiday", // Add your custom class here
                      }}
                      className="sticky-header-class tbl-holiday"
                    />
                  </div>
                </div>

                <div className="mt-4">
                  <div className="row">
                    <div className="col text-center">
                      <p className="font-weight-bold mb-0 p-color-size">
                        {t("average_rating")}
                      </p>
                      <p className="mb-0">
                        <i
                          className="fa fa-star color-custom mb-0"
                          aria-hidden="true"
                        ></i>
                        {props?.shutterbDetails.averageReview
                          ? props?.shutterbDetails.averageReview.toFixed(2)
                          : "N/A"}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="mt-4">
                  <div className="row">
                    {props.shutterbDetails.sbReviews && (
                      <div className="col text-center">
                        <p className="font-weight-bold mb-0 p-color-size">
                          {t("reviews")}
                        </p>
                        <p>
                          {props.shutterbDetails.sbReviews.length
                            ? props.shutterbDetails.sbReviews.length
                            : 0}{" "}
                          hosts have provided reviews
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="col">
                      {props.shutterbDetails.sbReviews &&
                        props.shutterbDetails.sbReviews.length > 0 &&
                        props.shutterbDetails.sbReviews.map((user, index) => (
                          <div>
                            <p className="font-weight-bold mb-0 p-color-size">
                              {user?.user?.firstName +
                                " " +
                                user?.user?.lastName}
                            </p>
                            <div className="stars pointer-events-none">
                              {/* Render star icons based on the selected rating */}
                              {[...Array(user.reviews)].map((_, index) => (
                                <span key={index} className="star active">
                                  &#9733;
                                </span>
                              ))}
                            </div>
                            <p>{user.feedback}</p>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </AvForm>
          </div>

          <div className="col-md-6 justify-content-start d-flex mt-4">
            <button
              type="btn"
              className="btn btn-info"
              onClick={() => props.onClose()}
            >
              Back
            </button>
          </div>
        </div>
      ) : (
        <div className="text-center mt-4">
          <LoadingIcon className="loading-spinner" />
        </div>
      )}
    </>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  spinnerActions: state.system.spinnerActions,
  shutterbDetails: state.shutterBs.shutterbDetails,
  gallery: state.shutterBs.gallery,
  profileImage: state.shutterBs.profileImage,
  blackoutDays: state.shutterBs.blackoutDays,
  workingSchedules: state.shutterBs.workingSchedules,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getShutterbById: (id) => dispatch(shutterBsActions.getShutterbById(id)),
});

// Mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ViewShutterBDetail));
