import i18next from "i18next";
import { toast } from "react-toastify";
import { customToast, getFilterExpression } from "../../shared";
import { custom, failure } from "./utilities";
import { marketingServices } from "../../services";
import { globalSystemConstants, marketingConstants } from "../../constants";

const {
  ADD_PARTNER_CODES,
  GET_PARTNER_CODES,
  Update_PARTNER_CODES,
  GET_PARTNER_CODE_BY_ID,
  TOGGLE_LINK_ACTIVATION,
  GET_REPORTS_DATA,
} = marketingConstants;

const addPartnerCode = (data) => {
  return (dispatch) => {
    dispatch(custom(ADD_PARTNER_CODES, globalSystemConstants.SPINNER_ACTIVATE));
    marketingServices
      .addPartnerCode(data)
      .then(
        (response) => {
          if (response) {
            customToast(i18next.t(response), toast.TYPE.SUCCESS);
            dispatch(getPartnerCodes());

            // dispatch(custom(response, ADD_PARTNER_CODES));
          }
        },
        (error) => {
          customToast(i18next.t(error[0]), toast.TYPE.ERROR);
          dispatch(failure(error, ADD_PARTNER_CODES));
        }
      )
      .finally(() => {
        dispatch(
          custom(ADD_PARTNER_CODES, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const updatePartnerCode = (id, data) => {
  return (dispatch) => {
    dispatch(
      custom(Update_PARTNER_CODES, globalSystemConstants.SPINNER_ACTIVATE)
    );
    marketingServices
      .updatePartnerCode(id, data)
      .then(
        (response) => {
          if (response) {
            customToast("Updated Successfully", toast.TYPE.SUCCESS);
            dispatch(getPartnerCodes());
          }
        },
        (error) => {
          customToast(i18next.t(error[0]), toast.TYPE.ERROR);
          dispatch(failure(error, Update_PARTNER_CODES));
        }
      )
      .finally(() => {
        dispatch(
          custom(Update_PARTNER_CODES, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const getPartnerCodes = () => {
  return (dispatch) => {
    dispatch(custom(GET_PARTNER_CODES, globalSystemConstants.SPINNER_ACTIVATE));
    marketingServices
      .getPartnerCodes()
      .then(
        (response) => {
          dispatch(custom(response, GET_PARTNER_CODES));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(i18next.t(error[0]), toast.TYPE.ERROR);
          dispatch(failure(error, GET_PARTNER_CODES));
        }
      )
      .finally(() => {
        dispatch(
          custom(GET_PARTNER_CODES, globalSystemConstants.SPINNER_DIACTIVATE)
        );
        dispatch(
          custom(GET_PARTNER_CODES, globalSystemConstants.OVERLAY_DIACTIVATE)
        );
      });
  };
};

const toggleLinkActivation = (id) => {
  return (dispatch) => {
    dispatch(
      custom(TOGGLE_LINK_ACTIVATION, globalSystemConstants.SPINNER_ACTIVATE)
    );
    marketingServices
      .toggleLinkActivation(id)
      .then(
        (response) => {
          if (response) {
            customToast(i18next.t(response), toast.TYPE.SUCCESS);
            dispatch(getPartnerCodes());
          }
        },
        (error) => {
          customToast(i18next.t(error[0]), toast.TYPE.ERROR);
          dispatch(failure(error, TOGGLE_LINK_ACTIVATION));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            TOGGLE_LINK_ACTIVATION,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
};

const getPartnerCodeById = (id) => {
  return (dispatch) => {
    dispatch(
      custom(GET_PARTNER_CODE_BY_ID, globalSystemConstants.SPINNER_ACTIVATE)
    );
    marketingServices
      .getPartnerCodeById(id)
      .then(
        (response) => {
          dispatch(custom(response, GET_PARTNER_CODE_BY_ID));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(i18next.t(error[0]), toast.TYPE.ERROR);
          dispatch(failure(error, GET_PARTNER_CODE_BY_ID));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            GET_PARTNER_CODE_BY_ID,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
        dispatch(
          custom(
            GET_PARTNER_CODE_BY_ID,
            globalSystemConstants.OVERLAY_DIACTIVATE
          )
        );
      });
  };
};

const getReports = (
  pageSize = marketingConstants.DEFAULT_PAGINATION_PAGE_SIZE,
  pageIndex = 1,
  filterExpression = getFilterExpression("")
) => {
  return (dispatch) => {
    dispatch(custom(GET_REPORTS_DATA, globalSystemConstants.SPINNER_ACTIVATE));
    marketingServices
      .getReports(pageSize, pageIndex, filterExpression)
      .then(
        (response) => {
          dispatch(custom(response, GET_REPORTS_DATA));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(i18next.t(error[0]), toast.TYPE.ERROR);
          dispatch(failure(error, GET_REPORTS_DATA));
        }
      )
      .finally(() => {
        dispatch(
          custom(GET_REPORTS_DATA, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

// exporting all shutterb dashboard actions
export const marketingActions = {
  addPartnerCode,
  getPartnerCodes,
  updatePartnerCode,
  getPartnerCodeById,
  toggleLinkActivation,
  getReports,
};
