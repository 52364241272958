import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import { customToast } from "../../shared";
import { useTranslation } from "react-i18next";
import LoadingIcon from "mdi-react/LoadingIcon";
import DataTable from "react-data-table-component";
import { configurationsActions } from "../../redux/actions";
import { configurationsConstants } from "../../constants";

const {
  GET_HOMEPAGE_SHUTTERBS,
  INSERT_HOMEPAGE_SHUTTERBS,
  REMOVE_HOMEPAGE_SHUTTERB,
} = configurationsConstants;

const HomePageShutterbs = (props) => {
  const { t } = useTranslation("common");

  const spinner = useCallback(
    (type) => props.spinnerActions.findIndex((f) => f === type),
    [props.spinnerActions]
  );
  const CustomNameCell = ({ data }) => {
    const fullName = `${data.firstName} ${data.lastName}`;

    return <div>{fullName}</div>;
  };

  const columns = [
    {
      name: "Name",
      selector: "fullName",
      sortable: true,
      width: "400px",
      cell: (row) => <CustomNameCell data={row} />,
    },
    {
      name: t("actions"),
      width: "400px",
      cell: (row) => (
        <div>
          <button
            type="button"
            className="payment-button"
            onClick={() => {
              props.removeShutterBFromHomePage(row.id);
            }}
          >
            {t("Remove")}
          </button>
        </div>
      ),
      allowOverflow: true,
      button: true,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
      },
    },
  };

  useEffect(() => {
    //props.getShutterBs();
    props.getHomepageShutterbs();
    // eslint-disable-next-line
  }, []);

  const [selectedUsers, setSelectedUsers] = useState([]);

  // Function to handle the selection of users
  const handleUserSelection = (selectedOptions) => {
    setSelectedUsers(selectedOptions);
  };

  // Function to handle the insertion action
  const performInsertionAction = () => {
    if(selectedUsers.length <= 0)
    {
        customToast(t("field_is_empty"), toast.TYPE.ERROR);
        return;
    }
    if(selectedUsers.length > 7)
    {
        customToast(t("max_7_shutterbs_can_be_selected"), toast.TYPE.ERROR);
        return;
    }
    if((props.homepageShutterbs.length + selectedUsers.length) > 7){
        customToast(t("max_7_shutterbs_can_be_selected"), toast.TYPE.ERROR);
        return;
    }
    const idsArray = selectedUsers.map((item) => item.value);
    const dataToSend = {
      ids: idsArray,
    };
    props.addShutterBsToHomePage(dataToSend);
    setSelectedUsers([]);
  };

  // Convert data array to options for the select dropdown
  const userOptions = props.allShutterbs?.map((user) => ({
    value: user.id,
    label: `${user.firstName} ${user.lastName}`,
  }));

  return (
    <div className="row mr-0">
      <div className="col-md-12">
        <div className="col-lg-12">
          <div className="tab-content">
            {spinner(GET_HOMEPAGE_SHUTTERBS) &&
            spinner(INSERT_HOMEPAGE_SHUTTERBS) &&
            spinner(REMOVE_HOMEPAGE_SHUTTERB) ? (
              <div className="tab-pane fade show active">
                <p className="mb-1 fw-bold custom-p-color">{t("shutterbs")}</p>
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1 pr-2">
                    <Select
                      isMulti
                      options={userOptions}
                      value={selectedUsers}
                      onChange={handleUserSelection}
                    />
                  </div>
                  <button
                    className="btn btn-primary mb-0 btn-padding"
                    onClick={performInsertionAction}
                  >
                    Insert
                  </button>
                </div>
                <div className="mt-4">
                  <DataTable
                    columns={columns}
                    data={props.homepageShutterbs}
                    highlightOnHover
                    responsive
                    customStyles={customStyles}
                    className="sticky-header-class"
                  />
                </div>
              </div>
            ) : (
              <div className="text-center mt-4">
                <LoadingIcon className="loading-spinner"/>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  spinnerActions: state.system.spinnerActions,
  allShutterbs: state.configurations.allShutterbs,
  homepageShutterbs: state.configurations.homepageShutterbs,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getShutterBs: () => dispatch(configurationsActions.getAllShutterBs()),
  getHomepageShutterbs: () =>
    dispatch(configurationsActions.getHomepageShutterbs()),
  addShutterBsToHomePage: (data) =>
    dispatch(configurationsActions.addShutterBsToHomePage(data)),
  removeShutterBFromHomePage: (id) =>
    dispatch(configurationsActions.removeShutterBFromHomePage(id)),
});

// Mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HomePageShutterbs));
