import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { NavigateToPath } from "../../../system";
import { menuUrlConstants } from "../../../constants";

const {
  SB_TAB1,
  SB_TAB2,
  SB_TAB3,
  SB_TAB4,
  CUSTOMERS_TAB,
  COMPLETED_EVENTS_TAB,
  UPCOMING_EVENTS_TAB,
  CANCELED_EVENTS_TAB,
  DRAFT_EVENTS_TAB,
  REELS_TAB,
  PROMO_CODES_TAB,
  POSTAL_CODES_TAB,
  DEVICES_TAB,
  HOME_PAGE_SBS_TAB,
  PAYMENTS_TAB,
  TAX_AND_FEE_TAB,
  AFFILIATE_LINKS_TAB,
  AFFILIATE_REPORTS_TAB,
  GIFT_CARD_TAB,
  REEL_TYPES_TAB,
  //MONITORING_REPORT_TAB,
} = menuUrlConstants;

const Sidebar = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation("common");

  const [isConfigurationsExpanded, setIsConfigurationsExpanded] =
    useState(false);
  const [isBillingExpanded, setIsBillingExpanded] = useState(false);
  const [isShutterbsExpanded, setIsShutterbsExpanded] = useState(false);
  const [isDiscountExpanded, setIsDiscountExpanded] = useState(false);
  const [isReelExpanded, setIsReelExpanded] = useState(false);
  const [isEventExpanded, setIsEventExpanded] = useState(
    location.pathname === "/Dashboard/" + UPCOMING_EVENTS_TAB ? true : false
  );
  const [isMarketingTabExpanded, setIsMarketingExpanded] = useState(false);
  const [activeMenu, setActiveMenu] = useState("");

  const toggleConfigurations = () => {
    setIsConfigurationsExpanded((prevState) => !prevState);
  };

  const toggleBilling = () => {
    setIsBillingExpanded((prevState) => !prevState);
  };

  const toggleShutterbs = () => {
    setIsShutterbsExpanded((prevState) => !prevState);
  };

  const toggleEvent = () => {
    setIsEventExpanded((prevState) => !prevState);
  };

  const toggleMarketingTab = () => {
    setIsMarketingExpanded((prevState) => !prevState);
  };

  const toggleDiscount = () => {
    setIsDiscountExpanded((prevState) => !prevState);
  };

  const toggleReels = () => {
    setIsReelExpanded((prevState) => !prevState);
  };

  useEffect(() => {
    // Extract the pathname from the location
    const pathname = location.pathname;

    // Extract the part of the path that comes after "/Dashboard/"
    const activeMenuPath = pathname.replace("/Dashboard/", "");

    // Set the active menu based on the extracted path
    setActiveMenu(activeMenuPath);
  }, [location]);

  const redirectToLocation = (location) => {
    setActiveMenu(location);
    NavigateToPath(history, `/Dashboard/${location}`);
  };

  return (
    <nav id="sidebar" className={props.isSidebarOpen ? "active" : ""}>
      <div className="sidebar-header">
        <a className="navbar-brand" href="/">
          <img src="/images/logo.png" alt="" />
        </a>
      </div>
      <ul className="list-unstyled components">
        <li>
          <a
            href="#shutterBsSubmenu"
            onClick={toggleShutterbs}
            aria-expanded={isShutterbsExpanded}
            className="btn btn-link text-white mb-0 mr-0 dropdown-toggle"
          >
            {t("shutterbs")}
          </a>
          <ul
            className={`collapse list-unstyled ${
              isShutterbsExpanded ? "show" : ""
            }`}
            id="shutterBsSubmenu"
          >
            <li
              className={
                activeMenu === SB_TAB1 ? "active-menu" : "not-active-submenu"
              }
            >
              {/* eslint-disable-next-line*/}
              <a
                onClick={() => {
                  redirectToLocation(SB_TAB1);
                  props.setIsSidebarOpen(!props.isSidebarOpen);
                }}
                className="btn btn-link text-white mb-0 px-0"
              >
                {t("new_applicants")}
              </a>
            </li>
            <li
              className={
                activeMenu === SB_TAB2 ? "active-menu" : "not-active-submenu"
              }
            >
              {/* eslint-disable-next-line*/}
              <a
                onClick={() => {
                  redirectToLocation(SB_TAB2);
                  props.setIsSidebarOpen(!props.isSidebarOpen);
                }}
                className="btn btn-link text-white mb-0 px-0"
              >
                {t("active_shutterbs")}
              </a>
            </li>
            <li
              className={
                activeMenu === SB_TAB3 ? "active-menu" : "not-active-submenu"
              }
            >
              {/* eslint-disable-next-line*/}
              <a
                onClick={() => {
                  redirectToLocation(SB_TAB3);
                  props.setIsSidebarOpen(!props.isSidebarOpen);
                }}
                className="btn btn-link text-white mb-0 px-0"
              >
                {t("archive")}
              </a>
            </li>
            <li
              className={
                activeMenu === SB_TAB4 ? "active-menu" : "not-active-submenu"
              }
            >
              {/* eslint-disable-next-line*/}
              <a
                onClick={() => {
                  redirectToLocation(SB_TAB4);
                  props.setIsSidebarOpen(!props.isSidebarOpen);
                }}
                className="btn btn-link text-white mb-0 px-0"
              >
                {t("hold")}
              </a>
            </li>
          </ul>
        </li>

        <li className={activeMenu === CUSTOMERS_TAB ? "active-menu" : ""}>
          {/* eslint-disable-next-line*/}
          <a
            onClick={() => {
              redirectToLocation(CUSTOMERS_TAB);
              props.setIsSidebarOpen(!props.isSidebarOpen);
            }}
            className="btn btn-link text-white mb-0"
          >
            {t("customers")}
          </a>
        </li>
        {/* <li className={activeMenu === EVENTS_TAB ? "active-menu" : ""}>
          <a
            eslint-disable-next-line
            onClick={() => {
              redirectToLocation(EVENTS_TAB);
              props.setIsSidebarOpen(!props.isSidebarOpen);
            }}
            className="btn btn-link text-white mb-0"
          >
            {t("events")}
          </a>
        </li> */}

        <li>
          {/* eslint-disable-next-line*/}
          <a
            href="#"
            onClick={toggleEvent}
            aria-expanded={isEventExpanded}
            className="btn btn-link text-white mb-0 mr-0 dropdown-toggle"
          >
            {t("events")}
          </a>
          <ul
            className={`collapse list-unstyled ${
              isEventExpanded ? "show" : ""
            }`}
            id="eventSubmenu"
          >
            <li
              className={
                activeMenu === COMPLETED_EVENTS_TAB ? "active-menu" : ""
              }
            >
              {/* eslint-disable-next-line*/}
              <a
                onClick={() => {
                  redirectToLocation(COMPLETED_EVENTS_TAB);
                  props.setIsSidebarOpen(!props.isSidebarOpen);
                }}
                className="btn btn-link text-white mb-0"
              >
                {t("completed_events")}
              </a>
            </li>
            <li
              className={
                activeMenu === UPCOMING_EVENTS_TAB ? "active-menu" : ""
              }
            >
              {/* eslint-disable-next-line*/}
              <a
                onClick={() => {
                  redirectToLocation(UPCOMING_EVENTS_TAB);
                  props.setIsSidebarOpen(!props.isSidebarOpen);
                }}
                className="btn btn-link text-white mb-0"
              >
                {t("upcoming_events")}
              </a>
            </li>
            <li
              className={
                activeMenu === CANCELED_EVENTS_TAB ? "active-menu" : ""
              }
            >
              {/* eslint-disable-next-line*/}
              <a
                onClick={() => {
                  redirectToLocation(CANCELED_EVENTS_TAB);
                  props.setIsSidebarOpen(!props.isSidebarOpen);
                }}
                className="btn btn-link text-white mb-0"
              >
                {t("canceled_events")}
              </a>
            </li>
          </ul>
        </li>
        <li className={activeMenu === DRAFT_EVENTS_TAB ? "active-menu" : ""}>
          {/* eslint-disable-next-line*/}
          <a
            onClick={() => {
              redirectToLocation(DRAFT_EVENTS_TAB);
              props.setIsSidebarOpen(!props.isSidebarOpen);
            }}
            className="btn btn-link text-white mb-0"
          >
            {t("Draft Events")}
          </a>
        </li>

        <li>
          <a
            href="#reelsSubmenu"
            onClick={toggleReels}
            aria-expanded={isReelExpanded}
            className="btn btn-link text-white mb-0 mr-0 dropdown-toggle"
          >
            {t("reels")}
          </a>
          <ul
            className={`collapse list-unstyled ${isReelExpanded ? "show" : ""}`}
            id="reelsSubmenu"
          >
            <li
              className={
                activeMenu === REELS_TAB ? "active-menu" : "not-active-submenu"
              }
            >
              {/* eslint-disable-next-line*/}
              <a
                onClick={() => {
                  redirectToLocation(REELS_TAB);
                  props.setIsSidebarOpen(!props.isSidebarOpen);
                }}
                className="btn btn-link text-white mb-0 px-0"
              >
                {t("assign_reels")}
              </a>
            </li>
            <li
              className={
                activeMenu === REEL_TYPES_TAB
                  ? "active-menu"
                  : "not-active-submenu"
              }
            >
              {/* eslint-disable-next-line*/}
              <a
                onClick={() => {
                  redirectToLocation(REEL_TYPES_TAB);
                  props.setIsSidebarOpen(!props.isSidebarOpen);
                }}
                className="btn btn-link text-white mb-0 px-0"
              >
                {t("reel_types")}
              </a>
            </li>
          </ul>
        </li>
        {/* <li
          className={
            activeMenu === REELS_TAB ? "active-menu" : "not-active-submenu"
          }
        >
          <a
            onClick={() => {
              redirectToLocation(REELS_TAB);
              props.setIsSidebarOpen(!props.isSidebarOpen);
            }}
            className="btn btn-link text-white mb-0 px-0"
          >
            {t("assign_reels")}
          </a>
        </li> */}
        <li>
          <a
            href="#promoSubmenu"
            onClick={toggleDiscount}
            aria-expanded={isDiscountExpanded}
            className="btn btn-link text-white mb-0 mr-0 dropdown-toggle"
          >
            {t("discount")}
          </a>
          <ul
            className={`collapse list-unstyled ${
              isDiscountExpanded ? "show" : ""
            }`}
            id="promoSubmenu"
          >
            <li
              className={
                activeMenu === PROMO_CODES_TAB
                  ? "active-menu"
                  : "not-active-submenu"
              }
            >
              {/* eslint-disable-next-line*/}
              <a
                onClick={() => {
                  redirectToLocation(PROMO_CODES_TAB);
                  props.setIsSidebarOpen(!props.isSidebarOpen);
                }}
                className="btn btn-link text-white mb-0 px-0"
              >
                {t("promo_codes")}
              </a>
            </li>
            {/* <li
              className={
                activeMenu === PROMO_BALANCE_TAB
                  ? "active-menu"
                  : "not-active-submenu"
              }
            >
              <a
                onClick={() => {
                  redirectToLocation(PROMO_BALANCE_TAB);
                  props.setIsSidebarOpen(!props.isSidebarOpen);
                }}
                className="btn btn-link text-white mb-0 px-0"
              >
                {t("promo_balance")}
              </a>
            </li> */}
            <li
              className={
                activeMenu === GIFT_CARD_TAB
                  ? "active-menu"
                  : "not-active-submenu"
              }
            >
              {/* eslint-disable-next-line*/}
              <a
                onClick={() => {
                  redirectToLocation(GIFT_CARD_TAB);
                  props.setIsSidebarOpen(!props.isSidebarOpen);
                }}
                className="btn btn-link text-white mb-0 px-0"
              >
                {t("gift_card")}
              </a>
            </li>
          </ul>
        </li>
        <li>
          <a
            href="#configurationsSubmenu"
            onClick={toggleConfigurations}
            aria-expanded={isConfigurationsExpanded}
            className="btn btn-link text-white mb-0 mr-0 dropdown-toggle"
          >
            {t("configurations")}
          </a>
          <ul
            className={`collapse list-unstyled ${
              isConfigurationsExpanded ? "show" : ""
            }`}
            id="configurationsSubmenu"
          >
            <li
              className={
                activeMenu === POSTAL_CODES_TAB
                  ? "active-menu"
                  : "not-active-submenu"
              }
            >
              {/* eslint-disable-next-line*/}
              <a
                onClick={() => {
                  redirectToLocation(POSTAL_CODES_TAB);
                  props.setIsSidebarOpen(!props.isSidebarOpen);
                }}
                className="btn btn-link text-white mb-0 px-0"
              >
                {t("postal_codes")}
              </a>
            </li>
            <li
              className={
                activeMenu === DEVICES_TAB
                  ? "active-menu"
                  : "not-active-submenu"
              }
            >
              {/* eslint-disable-next-line*/}
              <a
                onClick={() => {
                  redirectToLocation(DEVICES_TAB);
                  props.setIsSidebarOpen(!props.isSidebarOpen);
                }}
                className="btn btn-link text-white mb-0 px-0"
              >
                {t("smartphone_devices")}
              </a>
            </li>
            <li
              className={
                activeMenu === HOME_PAGE_SBS_TAB
                  ? "active-menu"
                  : "not-active-submenu"
              }
            >
              {/* eslint-disable-next-line*/}
              <a
                onClick={() => {
                  redirectToLocation(HOME_PAGE_SBS_TAB);
                  props.setIsSidebarOpen(!props.isSidebarOpen);
                }}
                className="btn btn-link text-white mb-0 px-0"
              >
                {t("home_page_shutterbs")}
              </a>
            </li>
          </ul>
        </li>

        <li>
          <a
            href="#billingSubmenu"
            onClick={toggleBilling}
            aria-expanded={isBillingExpanded}
            className="btn btn-link text-white mb-0 mr-0 dropdown-toggle"
          >
            {t("billing")}
          </a>
          <ul
            className={`collapse list-unstyled ${
              isBillingExpanded ? "show" : ""
            }`}
            id="billingSubmenu"
          >
            <li
              className={
                activeMenu === PAYMENTS_TAB
                  ? "active-menu"
                  : "not-active-submenu"
              }
            >
              {/* eslint-disable-next-line*/}
              <a
                onClick={() => {
                  redirectToLocation(PAYMENTS_TAB);
                  props.setIsSidebarOpen(!props.isSidebarOpen);
                }}
                className="btn btn-link text-white mb-0 px-0"
              >
                {t("payments")}
              </a>
            </li>
            <li
              className={
                activeMenu === TAX_AND_FEE_TAB
                  ? "active-menu"
                  : "not-active-submenu"
              }
            >
              {/* eslint-disable-next-line*/}
              <a
                onClick={() => {
                  redirectToLocation(TAX_AND_FEE_TAB);
                  props.setIsSidebarOpen(!props.isSidebarOpen);
                }}
                className="btn btn-link text-white mb-0 px-0"
              >
                {t("tax_and_fee")}
              </a>
            </li>
          </ul>
        </li>
        <li>
          {/* eslint-disable-next-line*/}
          <a
            href="#"
            onClick={toggleMarketingTab}
            aria-expanded={isMarketingTabExpanded}
            className="btn btn-link text-white mb-0 mr-0 dropdown-toggle"
          >
            {t("marketing")}
          </a>
          <ul
            className={`collapse list-unstyled ${
              isMarketingTabExpanded ? "show" : ""
            }`}
            id="marketingSubmenu"
          >
            <li
              className={
                activeMenu === AFFILIATE_LINKS_TAB ? "active-menu" : ""
              }
            >
              {/* eslint-disable-next-line*/}
              <a
                onClick={() => {
                  redirectToLocation(AFFILIATE_LINKS_TAB);
                  props.setIsSidebarOpen(!props.isSidebarOpen);
                }}
                className="btn btn-link text-white mb-0 px-0"
              >
                {t("affiliate_links")}
              </a>
            </li>
            <li
              className={
                activeMenu === AFFILIATE_REPORTS_TAB ? "active-menu" : ""
              }
            >
              {/* eslint-disable-next-line*/}
              <a
                onClick={() => {
                  redirectToLocation(AFFILIATE_REPORTS_TAB);
                  props.setIsSidebarOpen(!props.isSidebarOpen);
                }}
                className="btn btn-link text-white mb-0 px-0"
              >
                {t("report")}
              </a>
            </li>
          </ul>
        </li>
        {/* uncomment to see monitoring report screen */}
        {/* <li
          className={activeMenu === MONITORING_REPORT_TAB ? "active-menu" : ""}
        >
          
          <a
            onClick={() => {
              redirectToLocation(MONITORING_REPORT_TAB);
              props.setIsSidebarOpen(!props.isSidebarOpen);
            }}
            className="btn btn-link text-white mb-0"
          >
            {t("monitoring_report")}
          </a>
        </li> */}
      </ul>
    </nav>
  );
};

export default Sidebar;
