import { connect } from "react-redux";
import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router";
import LoadingIcon from "mdi-react/LoadingIcon";
import { useTranslation } from "react-i18next";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { paymentsActions } from "../../redux/actions";
import { paymentsConstants } from "../../constants";
const { GET_TAX_AND_FEE, UPDATE_TAX_AND_FEE } = paymentsConstants;

const TaxAndFee = (props) => {
  const { t } = useTranslation("common");
  const [tax, setTax] = useState("");
  const [serviceFee, setServiceFee] = useState("");
  const [platformFee, setPlatformFee] = useState("%");
  const [reelPlatformFee, setReelPlatformFee] = useState("%");

  const spinner = useCallback(
    (type) => props.spinnerActions.findIndex((f) => f === type),
    [props.spinnerActions]
  );

  const handleSubmit = (event, values, errors) => {
    const dataToSend = {
      tax: values.taxValue,
      serviceFee: values.serviceFeeValue,
      platformFee: values.platformFeeValue,
      reelPlatformFee: values.reelPlatformFeeValue,
      id: props.taxAndFeeId,
    };
    props.updateTaxAndFee(dataToSend);
  };

  const validateNonNegativeValue = (value) => {
    if (value <= 0) {
      return "Value should be greater than 0";
    }
    return true;
  };

  useEffect(() => {
    props.getTaxAndFee();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTax(props.tax);
    setServiceFee(props.serviceFee);
    setPlatformFee(props.platFormFee);
    setReelPlatformFee(props.reelPlatFormFee);
    // eslint-disable-next-line
  }, [props.tax]);

  return (
    <div className="row mr-0">
      <div className="col-md-12">
        <div className="col-lg-11">
          <div className="tab-content">
            <div className="tab-pane fade show active">
              {spinner(GET_TAX_AND_FEE) && spinner(UPDATE_TAX_AND_FEE) ? (
                <div>
                  <AvForm onValidSubmit={handleSubmit}>
                    <div className="custom-label-color">
                      <label htmlFor="taxValue">{t("tax")}</label>
                      <div className="input-group">
                        <AvField
                          name="taxValue"
                          type="number"
                          value={tax}
                          onChange={(e) => setTax(e.target.value)}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Value is required",
                            },
                            custom: validateNonNegativeValue,
                            max: {
                              value: 100,
                              errorMessage: "Maximum allowed value is 100",
                            },
                          }}
                        />
                        <div className="input-group-append ml-3">
                          <button
                            type="button"
                            className="btn btn-secondary disabled-btn"
                          >
                            %
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="custom-label-color">
                      <label htmlFor="serviceFeeValue">
                        {t("service_fee")}
                      </label>
                      <div className="input-group">
                        <AvField
                          name="serviceFeeValue"
                          type="number"
                          value={serviceFee}
                          onChange={(e) => setServiceFee(e.target.value)}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Value is required",
                            },
                            custom: validateNonNegativeValue,
                            max: {
                              value: 100,
                              errorMessage: "Maximum allowed value is 100",
                            },
                          }}
                        />
                        <div className="input-group-append ml-3">
                          <button
                            type="button"
                            className="btn btn-secondary disabled-btn"
                          >
                            %
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="custom-label-color">
                      <label htmlFor="platformFeeValue">
                        {t("sb_platform_fee")}
                      </label>
                      <div className="input-group">
                        <AvField
                          name="platformFeeValue"
                          type="number"
                          value={platformFee}
                          onChange={(e) => setPlatformFee(e.target.value)}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Value is required",
                            },
                            custom: validateNonNegativeValue,
                            max: {
                              value: 100,
                              errorMessage: "Maximum allowed value is 100",
                            },
                          }}
                        />
                        <div className="input-group-append ml-3">
                          <button
                            type="button"
                            className="btn btn-secondary disabled-btn"
                          >
                            %
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="custom-label-color">
                      <label htmlFor="platformFeeValue">
                        {t("reel_platform_fee")}
                      </label>
                      <div className="input-group">
                        <AvField
                          name="reelPlatformFeeValue"
                          type="number"
                          value={reelPlatformFee}
                          onChange={(e) => setReelPlatformFee(e.target.value)}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Value is required",
                            },
                            custom: validateNonNegativeValue,
                            max: {
                              value: 100,
                              errorMessage: "Maximum allowed value is 100",
                            },
                          }}
                        />
                        <div className="input-group-append ml-3">
                          <button
                            type="button"
                            className="btn btn-secondary disabled-btn"
                          >
                            %
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className=" justify-content-start d-flex mt-4">
                      <button type="submit" className="btn btn-info">
                        {t("update")}
                      </button>
                    </div>
                  </AvForm>
                </div>
              ) : (
                <div className="text-center mt-4">
                  <LoadingIcon className="loading-spinner" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  tax: state.payments.tax,
  serviceFee: state.payments.serviceFee,
  platFormFee: state.payments.platFormFee,
  reelPlatFormFee: state.payments.reelPlatFormFee,
  taxAndFeeId: state.payments.taxAndFeeId,
  spinnerActions: state.system.spinnerActions,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getTaxAndFee: () => dispatch(paymentsActions.getTaxAndFee()),
  updateTaxAndFee: (data) => dispatch(paymentsActions.updateTaxAndFee(data)),
});

// Mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TaxAndFee));
