import { connect } from "react-redux";
import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import LoadingIcon from "mdi-react/LoadingIcon";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { configurationsConstants } from "../../../constants";
import { configurationsActions } from "../../../redux/actions";

const { GET_POSTAL_CODES, ADD_POSTAL_CODES, REMOVE_POSTAL_CODE } =
  configurationsConstants;

const PostalCodes = (props) => {
  // eslint-disable-next-line
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({
    rowsPerPage: configurationsConstants.DEFAULT_PAGINATION_PAGE_SIZE,
    currentPage: 1,
    totalRows: 0,
  });
  const [inputValue, setInputValue] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");

  const { t } = useTranslation("common");

  const spinner = useCallback(
    (type) => props.spinnerActions.findIndex((f) => f === type),
    [props.spinnerActions]
  );

  const columns = [
    {
      name: "Postal Code",
      selector: "code",
      sortable: true,
      width: "400px",
    },
    {
      name: "Description",
      selector: "description",
      sortable: true,
      width: "400px",
    },
    // {
    //   name: "Description",
    //   sortable: true,
    //   width: "400px",
    //   cell: (row) => (
    //     <div>
    //       <p className="">{new Date(row.updatedAt).toUTCString()}</p>
    //     </div>
    //   ),
    // },
    {
      name: t("actions"),
      width: "400px",
      cell: (row) => (
        <div>
          <button
            type="button"
            className="payment-button"
            onClick={() => {
              props.removePostalCode(
                pagination.rowsPerPage,
                pagination.currentPage,
                searchText,
                row.id
              );
            }}
          >
            {t("Remove")}
          </button>
        </div>
      ),
      allowOverflow: true,
      button: true,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
      },
    },
  };

  useEffect(() => {
    props.getPostalCodes(
      pagination.rowsPerPage,
      pagination.currentPage,
      searchText
    );
    setPagination((prevPagination) => ({
      ...prevPagination,
      totalRows: props.configurations.total,
    }));
    // eslint-disable-next-line
  }, [pagination.currentPage, pagination.rowsPerPage]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    setDescriptionValue(event.target.value);
  };

  const handleButtonClick = () => {
    const postalCodesArray = inputValue.split(",").map((code) => code.trim());
    const postalCodesDescriptionArray = descriptionValue
      .split(",")
      .map((code) => code.trim());
    if (postalCodesArray.length !== postalCodesDescriptionArray.length) {
      alert("Number of Codes and Number of Descriptions should be identical");
      return;
    }
    let reqBody = {
      postalCode: [],
    };
    for (let i = 0; i < postalCodesArray.length; i++) {
      reqBody.postalCode.push({
        code: postalCodesArray[i],
        description: postalCodesDescriptionArray[i],
      });
    }
    props.addPostalCodes(
      pagination.rowsPerPage,
      pagination.currentPage,
      searchText,
      reqBody
    );
    setInputValue("");
    setDescriptionValue("");
  };

  return (
    <div className="row mr-0">
      <div className="col-md-12">
        <div className="col-lg-12">
          <div className="tab-content">
            {spinner(GET_POSTAL_CODES) &&
            spinner(ADD_POSTAL_CODES) &&
            spinner(REMOVE_POSTAL_CODE) ? (
              <div className="tab-pane fade show active">
                <AvForm onValidSubmit={handleButtonClick}>
                  <div className="custom-label-color">
                    <label htmlFor="taxValue">Postal Code</label>
                    <div className="input-group col-12 px-0">
                      <div className="col-3 px-0">
                        <AvField
                          name="postalCode"
                          type="text"
                          maxLength="50"
                          className="form-control mr-2"
                          placeholder="Enter first 3 characters of postal codes"
                          value={inputValue}
                          onChange={handleInputChange}
                          pattern="^([0-9A-Za-z ./*_-]{3},)*[0-9A-Za-z ./*_-]{3}$"
                          errorMessage="This field is invalid"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Value is required",
                            },
                          }}
                        />
                      </div>
                      <div className="col-4 px-0 ml-2">
                        <AvField
                          name="postalCodeDescription"
                          type="text"
                          required
                          maxLength="2000"
                          className="form-control mr-2"
                          placeholder="Enter description"
                          value={descriptionValue}
                          onChange={handleDescriptionChange}
                          errorMessage="This field is invalid"
                        />
                      </div>
                      <div className="input-group-append ml-2 col-3 px-0">
                        <button
                          type="submit"
                          className="btn btn-info d-flex align-items-center justify-content-center border-radius-5"
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </AvForm>
                <DataTable
                  columns={columns}
                  data={props?.availablePostalCodes}
                  highlightOnHover
                  responsive
                  customStyles={customStyles}
                  className="sticky-header-class"
                />
              </div>
            ) : (
              <div className="text-center mt-4">
                <LoadingIcon className="loading-spinner" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  configurations: state.configurations,
  availablePostalCodes: state.configurations.availablePostalCodes,
  spinnerActions: state.system.spinnerActions,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getPostalCodes: (pageSize, pageIndex, searchExpression) =>
    dispatch(
      configurationsActions.getPostalCodes(
        pageSize,
        pageIndex,
        searchExpression
      )
    ),
  addPostalCodes: (pageSize, pageIndex, searchExpression, dataToSend) =>
    dispatch(
      configurationsActions.addPostalCodes(
        pageSize,
        pageIndex,
        searchExpression,
        dataToSend
      )
    ),
  removePostalCode: (pageSize, pageIndex, searchExpression, dataToSend) =>
    dispatch(
      configurationsActions.removePostalCode(
        pageSize,
        pageIndex,
        searchExpression,
        dataToSend
      )
    ),
});

// Mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PostalCodes));
