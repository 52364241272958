import { updateObject } from "../../shared/utility";
import { messageBoardConstants } from "../../constants";

// getting the default actions name from defined constants via decomposition
const {
  GET_SHUTTERB_BY_EVENT_ID,
  FORM_STATE_OF_MESSAGE_BOARD,
  GET_MESSAGES,
  RECEIVE_MESSAGES,
  GET_SB_BY_ID,
} = messageBoardConstants;

// default state
const initialState = {
  data: {},
  messages:[],
  formState: {
    userId: 0,
    sbUserId: 0,
    email: "",
    hostCurrentTabEmail:"",
    eventId: "",
    message: "",
    reaction: "",
    status: "",
    sender: "",
    isActive: true,
  },
};

// manupilating the state based on actions and returning the state
export const messageBoard = (state = initialState, action) => {
  switch (action.type) {
    case GET_SHUTTERB_BY_EVENT_ID:
      return updateObject(state, {
        data: action.data.items,
      });
    case FORM_STATE_OF_MESSAGE_BOARD:
      return updateObject(state, { formState: action.data });
    case GET_MESSAGES:
      return updateObject(state, {
        messages: action.data.items,
      });
    case RECEIVE_MESSAGES:
      return {
        ...state,
        messages: [...state.messages, action.data],
      };
    case GET_SB_BY_ID:
      return updateObject(state, {
        data: action.data.items,
      });
    default:
      return state;
  }
};
