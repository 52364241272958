export const configurationsConstants = {
  ADD_PROMO_CODES: "ADD_PROMO_CODES",
  GET_PROMO_CODES_DATA: "GET_PROMO_CODES_DATA",
  SET_SUBMIT_BUTTON_STATE: "SET_SUBMIT_BUTTON_STATE",
  GET_SMARTPHONE_DEVICES: "GET_SMARTPHONE_DEVICES",
  ADD_SMARTPHONE_DEVICE: "ADD_SMARTPHONE_DEVICE",
  REMOVE_SMARTPHONE_DEVICE: "REMOVE_SMARTPHONE_DEVICE",
  REORDER_SMARTPHONE_DEVICES: "REORDER_SMARTPHONE_DEVICES",
  GET_ALL_SHUTTERBS: "GET_ALL_SHUTTERBS",
  GET_HOMEPAGE_SHUTTERBS: "GET_HOMEPAGE_SHUTTERBS",
  INSERT_HOMEPAGE_SHUTTERBS: "INSERT_HOMEPAGE_SHUTTERBS",
  REMOVE_HOMEPAGE_SHUTTERB: "REMOVE_HOMEPAGE_SHUTTERB",
  ACTIVATE_DEACTIVATE_PROMOCODE: "ACTIVATE_DEACTIVATE_PROMOCODE",
  GET_POSTAL_CODES: "GET_POSTAL_CODES",
  ADD_POSTAL_CODES: "ADD_POSTAL_CODES",
  REMOVE_POSTAL_CODE: "REMOVE_POSTAL_CODE",
  GET_PROMO_CODE_DATA_By_Id:"GET_PROMO_CODE_DATA_By_Id",
  UPDATE_PROMO_CODE:"UPDATE_PROMO_CODE",
  GET_PROMO_BALANCE_DATA:"GET_PROMO_BALANCE_DATA",
  GET_GIFT_CARD_DATA:"GET_GIFT_CARD_DATA",
  ACTIVATE_DEACTIVATE_GIFTCARD : "ACTIVATE_DEACTIVATE_GIFTCARD",
  ADD_GIFT_CARD : "ADD_GIFT_CARD",
  DEFAULT_PAGINATION_PAGE_SIZE: 20,
};
