import { updateObject } from "../../shared/utility";
import { draftEventsConstants } from "../../constants";

// getting the default actions name from defined constants via decomposition
const { GET_DRAFT_EVENTS, VIEW_DRAFT_EVENT_SHUTTERBS } = draftEventsConstants;

// default state
const initialState = {
  data: [],
  eventShutterbs: [],
};

// manupilating the state based on actions and returning the state
export const draftEvents = (state = initialState, action) => {
  switch (action.type) {
    case GET_DRAFT_EVENTS:
      return updateObject(state, {
        data: action.data,
      });
    case VIEW_DRAFT_EVENT_SHUTTERBS:
      return updateObject(state, {
        eventShutterbs: action.data?.items,
      });
    default:
      return state;
  }
};
