import React from "react";
import LogInForm from "./LoginForm";

const LogIn = () => {
  return (
    <div className="account account--not-photo">
      <div className="account__wrapper">
        <div className="account__card b-clr-grey">
          <div className="account__head d-flex justify-content-center">
            <h3 className="account__title">
              <span className="account__logo d-flex justify-content-center">
                <img src="/images/logo.png" alt="" />
              </span>
            </h3>
          </div>
          <LogInForm />
        </div>
      </div>
    </div>
  );
};

export default LogIn;
