import React, { useEffect, useCallback } from "react";
import Modal from "react-modal";

import LoadingIcon from "mdi-react/LoadingIcon";
import { reelsActions } from "../../redux/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { reelsConstants } from "../../constants";

const ReelDetailModal = (props) => {
  const spinner = useCallback(
    (type) => props.spinnerActions.findIndex((f) => f === type),
    [props.spinnerActions]
  );

  useEffect(() => {
    props?.reelDetailModalEventId &&
      props.getReelDetails(props.reelDetailModalEventId);
    // eslint-disable-next-line
  }, [props?.reelDetailModalEventId]);

  return (
    <Modal
      isOpen={props.isReelDetailModalOpen}
      onRequestClose={() => props.setIsReelDetailModalOpen(false)}
      className={"reel-detail-con"}
    >
      <span
        className="close"
        onClick={() => props.setIsReelDetailModalOpen(false)}
      >
        x
      </span>
      {spinner(reelsConstants.GET_REEL_DETAILS) ? (
        <>
          <div className="container-fluid main-con">
            <div className="row">
              <table>
                <thead>
                  <tr>
                    <td colSpan="12">Recap Brief </td>
                  </tr>
                  <tr>
                    <td colSpan="12">{props?.reelDetailModalEventId ?? 0}</td>
                  </tr>
                </thead>
                <tbody>
                  {props.reelDetail?.reelDetails[0]?.logoUrl && (
                    <tr>
                      <th>Logo Url:</th>
                      <td>
                        {process.env.REACT_APP_S3_BASE_URL_EVENTS_GALLERY +
                          "/" +
                          props.reelDetail?.reelDetails[0]?.logoUrl}
                      </td>
                    </tr>
                  )}
                  {props.reelDetail?.reelDetails[0]?.musicFilePath && (
                    <tr>
                      <th>Music File Url:</th>
                      <td>
                        {process.env.REACT_APP_S3_BASE_URL_EVENTS_GALLERY +
                          "/" +
                          props.reelDetail?.reelDetails[0]?.musicFilePath}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <th>Music:</th>
                    <td>{props.reelDetail?.reelDetails[0]?.music}</td>
                  </tr>
                  <tr>
                    <th>Orientation:</th>
                    <td>{props.reelDetail?.reelDetails[0]?.orientation}</td>
                  </tr>
                  <tr>
                    <th>Recap Type:</th>
                    <td>{props.reelDetail?.reelDetails[0]?.reelType}</td>
                  </tr>
                  {/* <tr>
                    <th>What are you using this reel for?</th>
                    <td>{props.reelDetail?.reelQuestionAnswers[0]?.answer}</td>
                  </tr> */}
                  <tr>
                    <th>Vision for the recap:</th>
                    <td>{props.reelDetail?.reelQuestionAnswers[0]?.answer}</td>
                  </tr>
                  {/* <tr>
                    <th>Special moments you are hoping to feature:</th>
                    <td>{props.reelDetail?.reelQuestionAnswers[2]?.answer}</td>
                  </tr>
                  <tr>
                    <th>Guests they want to focus on:</th>
                    <td>{props.reelDetail?.reelQuestionAnswers[3]?.answer}</td>
                  </tr>
                  <tr>
                    <th>"Guests" who should not be included:</th>
                    <td>{props.reelDetail?.reelQuestionAnswers[4]?.answer}</td>
                  </tr>
                  {props.reelDetail?.reelQuestionAnswers[5]?.answer && (
                    <tr>
                      <th>Brand guidelines & Legal Requirements</th>
                      <td>
                        {props.reelDetail?.reelQuestionAnswers[5]?.answer}
                      </td>
                    </tr>
                  )} */}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <div className="text-center mt-4">
          <LoadingIcon className="loading-spinner" />
        </div>
      )}
    </Modal>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  spinnerActions: state.system.spinnerActions,
  reelDetail: state.reels.reelDetail,
  isReelDetailModalOpen: state.reels.isReelDetailModalOpen,
  reelDetailModalEventId: state.reels.reelDetailModalEventId,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getReelDetails: (eventId) => dispatch(reelsActions.getReelDetails(eventId)),
  setIsReelDetailModalOpen: () =>
    dispatch({ type: reelsConstants.SET_IS_REEL_DETAIL_MODAL_OPEN }),
  setIsReelDetailModalEventId: () =>
    dispatch({ type: reelsConstants.SET_REEL_DETAIL_MODAL_EVENT_ID }),
});

// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ReelDetailModal));
