// eslint-disable-next-line import/no-cycle
import responseHandler from "../system/server-response-handler";
import { getRequest, deleteRequest } from "../system/server-call";

// get shutter Bs list
const getCustomers = (pageSize, pageNumber, filterExpression) => {
  return getRequest(
    `/api/v1/admin/User?PageSize=${pageSize}&PageNumber=${pageNumber}&FilterExpression=${filterExpression}`,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const activateDeactivateCustomer = (id) => {
  return deleteRequest(`/api/v1/admin/User/${id}`, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

// exporting functions for other files
export const customersServices = {
  getCustomers,
  activateDeactivateCustomer,
};
