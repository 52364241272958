import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { loginActions } from "../../../redux/actions";

const Topbar = (props) => {
  const { t } = useTranslation("common");

  const toggleSidebar = () => {
    props.setIsSidebarOpen(!props.isSidebarOpen);
  };

  const logoutHandler = () => {
    props.logout();
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <button type="button" onClick={toggleSidebar} className="btn btn-info">
          <i className="fas fa-align-left"></i>
        </button>
        <button
          className="btn btn-dark d-inline-block d-lg-none ml-auto"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fas fa-align-justify"></i>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="nav navbar-nav ml-auto">
            <li className="nav-item">
              {/* eslint-disable-next-line */}
              <a className="nav-link" onClick={logoutHandler}>
                {t("logout")}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
  };
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(loginActions.logout()),
});
// mapping action and store the function via props
export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
