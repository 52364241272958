export * from "./login.constants";
export * from "./shutterBs.constants";
export * from "./events.constants";
export * from "./payments.constants";
export * from "./configurations.constants";
export * from "./customers.constants";
export * from "./reels.constants";
export * from "./draftEvents.constants";
export * from "./marketing.constants";
export * from "./messageBoard.constants";
export * from "./gallery.constants";
export * from "./reports.constants";

export const globalConstants = {
  REQUEST: "_REQUEST",
  SUCCESS: "_SUCCESS",
  FAILURE: "_FAILURE",
};

export const globalSystemConstants = {
  SPINNER_ACTIVATE: "SPINNER_ACTIVATE",
  SPINNER_DIACTIVATE: "SPINNER_DIACTIVATE",
  OVERLAY_ACTIVATE: "OVERLAY_ACTIVATE",
  OVERLAY_DIACTIVATE: "OVERLAY_DIACTIVATE",
  DOWNLOAD_SPINNER_ACTIVATE: "DOWNLOAD_SPINNER_ACTIVATE",
  DOWNLOAD_SPINNER_DIACTIVATE: "DOWNLOAD_SPINNER_DIACTIVATE",
};

export const paginationConstants = {
  PAGINATION_FILTER_TYPE: {
    ACTIVE: 1,
    ALL: 2,
    CONTAINS: 3,
    SHUTTERB_NEW_APPLICANT_TAB: 4,
    SHUTTERB_ACTIVE_TAB: 5,
    SHUTTERB_ARCHIVE_TAB: 6,
    SHUTTERB_HOLD_TAB: 7,
    DATE_RANGE: 8,
    LIST_DATE_RANGE: 9,
  },
};

export const userType = {
  ADMIN: "1",
};

export const menuUrlConstants = {
  SB_TAB1: "ShutterBs/:tab=1",
  SB_TAB2: "ShutterBs/:tab=2",
  SB_TAB3: "ShutterBs/:tab=3",
  SB_TAB4: "ShutterBs/:tab=4",
  CUSTOMERS_TAB: "Customers",
  EVENTS_TAB: "Events",
  UPCOMING_EVENTS_TAB: "UpcomingEvents",
  COMPLETED_EVENTS_TAB: "CompletedEvents",
  CANCELED_EVENTS_TAB: "CanceledEvents",
  DRAFT_EVENTS_TAB: "DraftEvents",
  REELS_TAB: "Reels",
  PROMO_CODES_TAB: "PromoCodes",
  POSTAL_CODES_TAB: "PostalCodes",
  DEVICES_TAB: "SmartPhoneDevices",
  HOME_PAGE_SBS_TAB: "HomePageShutterbs",
  PAYMENTS_TAB: "Payments",
  TAX_AND_FEE_TAB: "TaxAndFee",
  AFFILIATE_LINKS_TAB: "AffiliateLinks",
  AFFILIATE_REPORTS_TAB: "AffiliateReports",
  PROMO_BALANCE_TAB: "PromoBalance",
  GIFT_CARD_TAB: "GiftCard",
  REEL_TYPES_TAB: "ReelTypes",
  MONITORING_REPORT_TAB: "MonitoringReport",
};

export const sharedConstants = {
  KB_TO_MB_BYTES: 1048576, //1024 * 1024 = 1048576
};
