export const eventsConstants = {
  GET_EVENTS: "GET_EVENTS",
  CANCEL_EVENT: "CANCEL_EVENT",
  VIEW_EVENT_SHUTTERBS: "VIEW_EVENT_SHUTTERBS",
  REASSIGN_SHUTTERBS: "REASSIGN_SHUTTERBS",
  GET_EVENT_GALLERY: "GET_EVENT_GALLERY",
  DOWNLOAD_GALLERY_DATA: "DOWNLOAD_GALLERY_DATA",
  DELETE_FROM_GALLERY: "DELETE_FROM_GALLERY",
  ADD_TO_GALLERY: "ADD_TO_GALLERY",
  GET_EVENT_DETAIL_BY_ID: "GET_EVENT_DETAIL_BY_ID",
};

export const eventsStatusConstants = {
  PENDING: "pending",
  CREATED: "created",
  ACCEPTED: "accepted",
  CHECKOUT: "checkOut",
};
