import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const Overlay = (props) => {
  return (
    <>
      {props.isOverlayActivate && (
        <div className="my-events-sb-gallery-overlay"></div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isOverlayActivate: state.system.isOverlayActivate,
  };
};

export default withRouter(connect(mapStateToProps)(Overlay));
