import { updateObject } from "../../shared/utility";
import { customersConstants } from "../../constants";

// getting the default actions name from defined constants via decomposition
const { GET_CUSTOMERS } = customersConstants;

// default state
const initialState = {
  data: {},
};

// manupilating the state based on actions and returning the state
export const customers = (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMERS:
      return updateObject(state, {
        data: action.data,
      });
    default:
      return state;
  }
};
