import CryptoJS from "crypto-js";
import {
  clearStorage,
  getItemFromStorage,
  removeItemFromStorage,
} from "./storage";

export const encryptObject = (object) => {
  var ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(object),
    "my-secret-key@123"
  ).toString();

  return ciphertext;
};

export const decryptObject = (encryptedString) => {
  try {
    if (encryptedString !== "") {
      var bytes = CryptoJS.AES.decrypt(encryptedString, "my-secret-key@123");
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return decryptedData;
    } else {
      return {};
    }
  } catch (e) {
    removeItemFromStorage(process.env.REACT_APP_PAYLOAD);
    clearStorage();
    window.location.reload();
  }
};

// ------------------- Decrypt Payload
export const getDecryptPayload = () => {
  const encryptedPayload =
    getItemFromStorage(process.env.REACT_APP_PAYLOAD) || "";
  const payload = decryptObject(encryptedPayload);
  return payload;
};
