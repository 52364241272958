import { updateObject } from "../../shared/utility";
import { configurationsConstants } from "../../constants";

// getting the default actions name from defined constants via decomposition
const {
  GET_PROMO_CODES_DATA,
  SET_SUBMIT_BUTTON_STATE,
  GET_SMARTPHONE_DEVICES,
  GET_ALL_SHUTTERBS,
  GET_HOMEPAGE_SHUTTERBS,
  GET_POSTAL_CODES,
  GET_PROMO_CODE_DATA_By_Id,
  GET_PROMO_BALANCE_DATA,
  GET_GIFT_CARD_DATA,
} = configurationsConstants;

// default state
const initialState = {
  data: {},
  numberOfPages: 2,
  total: 7,
  currentPage: 1,
  pageSize: 5,
  isSubmitButtonDisabled: false,
  availableSmartPhoneDevices: [],
  allShutterbs: [],
  homepageShutterbs: [],
  availablePostalCodes: [],
  availablePostalCodeById:{},
  promoBalance:[],
  giftCards : []
};

// manupilating the state based on actions and returning the state
export const configurations = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROMO_CODES_DATA:
      return updateObject(state, {
        data: action?.data,
      });
    case SET_SUBMIT_BUTTON_STATE:
      return updateObject(state, {
        isSubmitButtonDisabled: action?.data,
      });
    case GET_SMARTPHONE_DEVICES:
      return updateObject(state, {
        availableSmartPhoneDevices: action?.data?.items,
      });
    case GET_ALL_SHUTTERBS:
      return updateObject(state, {
        allShutterbs: action.data.items,
      });
    case GET_HOMEPAGE_SHUTTERBS:
      return updateObject(state, {
        homepageShutterbs: action.data.items,
      });
    case GET_POSTAL_CODES:
      return updateObject(state, {
        availablePostalCodes: action?.data?.items,
      });
    case GET_PROMO_CODE_DATA_By_Id:
      return updateObject(state, {
        availablePostalCodeById: action?.data?.items,
      });
    case GET_PROMO_BALANCE_DATA:
      return updateObject(state, {
        promoBalance: action?.data,
      });
    case GET_GIFT_CARD_DATA:
      return updateObject(state, {
        giftCards: action?.data,
      });
    default:
      return state;
  }
};
