import React, { Suspense } from "react";
import i18n from "i18next";
import { createBrowserHistory } from "history";
import { I18nextProvider } from "react-i18next";
import { Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "../../scss/app.scss";
import RouterWrapper from "./Router";
import { config as i18nextConfig } from "../../translations";

i18n.init(i18nextConfig);

const History = createBrowserHistory();

const App = () => {
  return (
    <Suspense fallback={null}>
      <Router history={History}>
        <I18nextProvider i18n={i18n}>
          <RouterWrapper />
        </I18nextProvider>
      </Router>
    </Suspense>
  );
};

export default App;
