// eslint-disable-next-line import/no-cycle
import responseHandler from "../system/server-response-handler";
import {
  getRequest,
  deleteRequest,
  putRequest,
  postRequest,
  deleteRequestWithPayload,
  postRequestWithProgress,
} from "../system/server-call";

const getCompletedEvents = (pageSize, pageNumber, filterExpression) => {
  return getRequest(
    `/api/v1/admin/event/GetCompletedEvents?PageSize=${pageSize}&PageNumber=${pageNumber}&FilterExpression=${filterExpression}&language=en&`,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};
const getUpcomingEvents = (pageSize, pageNumber, filterExpression) => {
  return getRequest(
    `/api/v1/admin/event/GetUpcomingEvents?PageSize=${pageSize}&PageNumber=${pageNumber}&FilterExpression=${filterExpression}&language=en&`,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};
const getCanceledEvents = (pageSize, pageNumber, filterExpression) => {
  return getRequest(
    `/api/v1/admin/event/GetCanceledEvents?PageSize=${pageSize}&PageNumber=${pageNumber}&FilterExpression=${filterExpression}&language=en&`,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const cancelEvent = (id) => {
  return deleteRequest(`/api/v1/admin/Event/${id}`, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const viewEventShutterbs = (id) => {
  return getRequest(`/api/v1/admin/ShutterB/GetSBForEvent/${id}`, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const resAssignShutterbs = (eventId, data) => {
  return putRequest(
    `/api/v1/admin/ShutterB/ReassignSBForEvent/${eventId}`,
    data,
    true
  ).then(
    (response) => responseHandler.handleServerMessageResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

// get shared gallery images from server
const getEventGallery = (eventId, isActive) => {
  return getRequest(
    `/api/v1/User/EventGallery/SharedGalleryFiles/${eventId}/${isActive}`,
    true
  ).then(
    (response) => {
      return responseHandler.handleServerResponse(response);
    },
    (error) => {
      return responseHandler.handleHTTPResponses(error.response);
    }
  );
};

const downloadSelectedFiles = (eventIdsArr) => {
  return postRequest(
    `/api/v1/ShutterB/Event/DownloadEventFiles`,
    eventIdsArr,
    true
  ).then(
    (response) => responseHandler.handleServerMessageResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const deleteFromGallery = (dataObj) => {
  return deleteRequestWithPayload(
    `/api/v1/User/EventGallery`,
    dataObj,
    true
  ).then(
    (response) => {
      return responseHandler.handleServerResponse(response);
    },
    (error) => {
      return responseHandler.handleHTTPResponses(error.response);
    }
  );
};

const addToGallery = (data, options) => {
  return postRequestWithProgress(
    `/api/v1/admin/Event/AddEventFiles`,
    data,
    true,
    options
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const getEventDetailByEventId = (eventId) => {
  return getRequest(
    `/api/v1/admin/event/GetEventDetailByEventId/${eventId}`,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

// exporting functions for other files
export const eventsServices = {
  getCompletedEvents,
  getUpcomingEvents,
  getCanceledEvents,
  cancelEvent,
  viewEventShutterbs,
  resAssignShutterbs,
  getEventGallery,
  downloadSelectedFiles,
  deleteFromGallery,
  addToGallery,
  getEventDetailByEventId,
};
