import moment from "moment";
import i18next from "i18next";
import { toast, Slide } from "react-toastify";
import { paginationConstants, sharedConstants } from "../constants";
import XLSX from "sheetjs-style";
import FileSaver from "file-saver";

export const updateObject = (oldObject, updatedProperties) => ({
  ...oldObject,
  ...updatedProperties,
});

export const customToast = (
  AllErrorMessages,
  type = toast.TYPE.INFO,
  position = toast.POSITION.TOP_RIGHT,
  time = 4000
) => {
  const options = {
    autoClose: time,
    type,
    hideProgressBar: true,
    position,
    pauseOnHover: true,
    transition: Slide,
    className: "primary",
  };
  if (Array.isArray(AllErrorMessages)) {
    AllErrorMessages.forEach((message) => {
      if (message) {
        toast(message, options);
      }
    });
  } else {
    toast(i18next.t(AllErrorMessages), options);
  }
};

export const standardDateTimeFormat = (date) => {
  return moment(date).format("YYYY/MM/DD HH:mm");
};

export const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const getCurrentDateTime = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  const hours = String(today.getHours()).padStart(2, "0");
  const minutes = String(today.getMinutes()).padStart(2, "0");
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const filterPastTimes = (time) => {
  const currentTime = new Date();
  return time.getTime() > currentTime.getTime();
};

export const getNextValidTime = (time, intervals) => {
  const timeMs = time.getTime();
  const intervalMs = intervals * 60 * 1000;
  const nextValidTimeMs = Math.ceil(timeMs / intervalMs) * intervalMs;
  return new Date(nextValidTimeMs);
};

// --------------------------
export var Base64 = {
  _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",

  encode: function (input) {
    var output = "";
    var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    var i = 0;

    input = Base64._utf8_encode(input);

    while (i < input.length) {
      chr1 = input.charCodeAt(i++);
      chr2 = input.charCodeAt(i++);
      chr3 = input.charCodeAt(i++);

      enc1 = chr1 >> 2;
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
      enc4 = chr3 & 63;

      if (isNaN(chr2)) {
        enc3 = enc4 = 64;
      } else if (isNaN(chr3)) {
        enc4 = 64;
      }

      output =
        output +
        this._keyStr.charAt(enc1) +
        this._keyStr.charAt(enc2) +
        this._keyStr.charAt(enc3) +
        this._keyStr.charAt(enc4);
    }

    return output;
  },

  _utf8_encode: function (string) {
    string = string.replace(/\r\n/g, "\n");
    var utftext = "";

    for (var n = 0; n < string.length; n++) {
      var c = string.charCodeAt(n);

      if (c < 128) {
        utftext += String.fromCharCode(c);
      } else if (c > 127 && c < 2048) {
        utftext += String.fromCharCode((c >> 6) | 192);
        utftext += String.fromCharCode((c & 63) | 128);
      } else {
        utftext += String.fromCharCode((c >> 12) | 224);
        utftext += String.fromCharCode(((c >> 6) & 63) | 128);
        utftext += String.fromCharCode((c & 63) | 128);
      }
    }

    return utftext;
  },
};

export const getFilterExpression = (
  filterType,
  queryExpression,
  columnName,
  multiDateRangeArr
) => {
  let expression = "";
  switch (parseInt(filterType)) {
    case paginationConstants.PAGINATION_FILTER_TYPE.ACTIVE:
      expression = queryExpression
        ? Base64.encode(`(${queryExpression})`.concat("&&isActive==true"))
        : "aXNBY3RpdmU9PXRydWU=";
      return expression;
    case paginationConstants.PAGINATION_FILTER_TYPE.ALL:
      expression = queryExpression
        ? Base64.encode(
            `(${queryExpression})`.concat(
              "&&(isActive==true || isActive==false)"
            )
          )
        : "KGlzQWN0aXZlID09IHRydWUgfHwgaXNBY3RpdmUgPT0gZmFsc2Up";
      return expression;
    case paginationConstants.PAGINATION_FILTER_TYPE.CONTAINS:
      expression = queryExpression
        ? Base64.encode(
            `${columnName}.CONTAINS("${queryExpression}")`.concat(
              "&&(isActive==true || isActive==false)"
            )
          )
        : "KGlzQWN0aXZlID09IHRydWUgfHwgaXNBY3RpdmUgPT0gZmFsc2Up";
      return expression;
    case paginationConstants.PAGINATION_FILTER_TYPE.SHUTTERB_NEW_APPLICANT_TAB:
      expression = queryExpression
        ? Base64.encode(
            `(status == "created" && isActive == false && ${columnName}.CONTAINS("${queryExpression}"))`
          )
        : Base64.encode(`(status == "created" && isActive == false)`);
      return expression;
    case paginationConstants.PAGINATION_FILTER_TYPE.SHUTTERB_ACTIVE_TAB:
      expression = queryExpression
        ? Base64.encode(
            `(status == "approved" && isActive = true && ${columnName}.CONTAINS("${queryExpression}"))`
          )
        : Base64.encode(`(status == "approved" && isActive = true)`);
      return expression;
    case paginationConstants.PAGINATION_FILTER_TYPE.SHUTTERB_ARCHIVE_TAB:
      expression = queryExpression
        ? Base64.encode(
            `(isActive == false && (status == "rejected" || status == "approved") && ${columnName}.CONTAINS("${queryExpression}"))`
          )
        : Base64.encode(
            `(isActive == false && (status == "rejected" || status == "approved"))`
          );
      return expression;
    case paginationConstants.PAGINATION_FILTER_TYPE.SHUTTERB_HOLD_TAB:
      expression = queryExpression
        ? Base64.encode(
            `(status == "hold" && isActive == false && ${columnName}.CONTAINS("${queryExpression}"))`
          )
        : Base64.encode(`(status == "hold" && isActive == false)`);
      return expression;
    case paginationConstants.PAGINATION_FILTER_TYPE.DATE_RANGE:
      expression = queryExpression
        ? Base64.encode(
            `( (isActive == true || isActive == false) && (${columnName} >= "${
              Number(queryExpression) - 24 * 3600
            }" && ${columnName} < "${queryExpression}" ) )`
          )
        : Base64.encode(`(status == "hold" && isActive == false)`);
      return expression;
    case paginationConstants.PAGINATION_FILTER_TYPE.LIST_DATE_RANGE:
      var startEpochSeconds = multiDateRangeArr[0]?.unix
        ? Math.floor(
            (Math.floor((multiDateRangeArr[0]?.unix * 1000) / 86400) * 86400) /
              1000
          )
        : multiDateRangeArr[0]
        ? Math.floor((Math.floor(multiDateRangeArr[0] / 86400) * 86400) / 1000)
        : "";
      var endEpochSeconds = multiDateRangeArr[1]?.unix
        ? Math.floor(
            (Math.ceil((multiDateRangeArr[1]?.unix * 1000) / 86400) * 86400) /
              1000
          ) + 86400
        : multiDateRangeArr[1]
        ? Math.floor((Math.ceil(multiDateRangeArr[1] / 86400) * 86400) / 1000) +
          86400
        : "";
      expression =
        queryExpression && multiDateRangeArr.length
          ? Base64.encode(
              `( (isActive == true || isActive == false) && (${"createdAt"} > "${startEpochSeconds}" && ${"createdAt"} < "${
                endEpochSeconds !== "" ? endEpochSeconds : startEpochSeconds
              }" ) &&  ${columnName}.CONTAINS("${queryExpression}") )`
            )
          : Base64.encode(
              `(isActive == true || isActive == false) && (${"createdAt"} > "${startEpochSeconds}" && ${"createdAt"} < "${
                endEpochSeconds !== "" ? endEpochSeconds : startEpochSeconds
              }" )`
            );
      return expression;
    default:
      return "KGlzQWN0aXZlID09IHRydWUgfHwgaXNBY3RpdmUgPT0gZmFsc2Up";
  }
};

export const convertDateToUTC = (date) => {
  const epochTimestamp = date;
  const getDate = new Date(epochTimestamp * 1000);

  const year = getDate.getUTCFullYear();
  const month = getDate.getUTCMonth() + 1; // Adding 1 since months are 0-indexed
  const day = getDate.getUTCDate();

  return `${month}/${day}/${year}`;
};

export const calculateTotalFileSizeInMbs = (filesArr) => {
  let tempArr = filesArr;
  if (!Array.isArray(filesArr)) {
    tempArr = [...Array.from(filesArr)];
  }
  return (
    tempArr.reduce((accomulator, currentValue) => {
      return accomulator + currentValue.size;
    }, 0) / sharedConstants.KB_TO_MB_BYTES
  );
};

export const convertBytesToMbs = (fileSize) => {
  let size = fileSize;
  if (fileSize instanceof File) {
    size = fileSize.size;
  }
  return size / sharedConstants.KB_TO_MB_BYTES;
};

export const DownloadExcel = (exportData, fileName, applyStyling = false) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheet.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const capitalizedHeadersData = exportData.map((row) => {
    const newRow = {};
    for (const key in row) {
      if (row.hasOwnProperty(key)) {
        const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
        newRow[capitalizedKey] = row[key];
      }
    }
    return newRow;
  });

  const ws = XLSX.utils.json_to_sheet(capitalizedHeadersData);

  if (applyStyling) {
    // Define the header styles
    const headerStyle = {
      font: { bold: true, color: { rgb: "FFFFFF" } },
      fill: { fgColor: { rgb: "17a2b8" } }, // Background color
      alignment: { horizontal: "center", vertical: "center" },
    };

    // Apply styles to headers and calculate column widths
    const range = XLSX.utils.decode_range(ws["!ref"]);
    const colWidths = [];
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cell_address = { c: C, r: 0 }; // Modify the row index for headers (usually row 0)
      const cell_ref = XLSX.utils.encode_cell(cell_address);
      if (ws[cell_ref]) {
        ws[cell_ref].s = headerStyle;
      }

      // Calculate the max width for the column
      let maxWidth = ws[cell_ref] ? ws[cell_ref].v.length : 10; // Default width if header is empty
      for (let R = range.s.r + 1; R <= range.e.r; ++R) {
        const cell_address = { c: C, r: R };
        const cell_ref = XLSX.utils.encode_cell(cell_address);
        const cell = ws[cell_ref];
        if (cell && cell.v) {
          const cellValue = cell.v.toString();
          if (cellValue.length > maxWidth) {
            maxWidth = cellValue.length;
          }
        }
      }
      colWidths.push({ wch: maxWidth + 1 }); // Add some padding to the width
    }

    ws["!cols"] = colWidths; // Set column widths
  }

  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export const getCurrentMonthStartAndEndEpochInMilliseconds = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  // Set the date to the 1st of the current month
  const startDate = new Date(currentYear, currentMonth, 1);

  // Set the date to the 1st of the next month and subtract one day to get the last day of the current month
  const endDate = new Date(currentYear, currentMonth + 1, 0);
  // Convert to epoch timestamps (milliseconds since January 1, 1970)
  const startEpoch = startDate.getTime();
  const endEpoch = endDate.getTime();
  return [startEpoch, endEpoch];
};

export const convertDateObjectToDate = (dateObj, starOfDay, endOfDay) => {
  const date = new Date(dateObj);
  if (date) {
    if (starOfDay) {
      date.setHours(0, 0, 0, 0);
    } else if (endOfDay) {
      date.setHours(23, 59, 59, 999);
    }
  }

  return date;
};

export const convertDateToEpoch = (date) => {
  const epoch = date.getTime() / 1000;
  return parseInt(epoch);
};

export const fileName = (name) => {
  const today = new Date();
  const fileName = `${name}_${today.getFullYear()}-${
    today.getMonth() + 1 < 10 ? "0" : ""
  }${today.getMonth() + 1}-${
    today.getDate() < 10 ? "0" : ""
  }${today.getDate()}_${today.getHours() < 10 ? "0" : ""}${today.getHours()}-${
    today.getMinutes() < 10 ? "0" : ""
  }${today.getMinutes()}`;

  return fileName;
};
