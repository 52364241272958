import React from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "react-multi-date-picker";

import { getFilterExpression } from "../../../shared";
import { paginationConstants } from "../../../constants";

// for filteration pass these as props
// const [filterOptionValue, setFilterOptionValue] = useState("");
// const filterOptions = ["eventName", "eventDate", "city"];
const PaginationComponent = ({
  pagination,
  setPagination,
  searchText,
  setSearchText,
  date,
  setDate,
  listDateRange = false,
  ...props
}) => {
  const { t } = useTranslation("common");
  const { pageSize, pageNumber, totalRows } = pagination;
  const { filterOptionValue, setFilterOptionValue } = props;
  const pageSizeOptions = [5, 10, 20, 30];

  const handleSearch = (event) => {
    setSearchText(event.target.value);
    if (!listDateRange) {
      setDate && setDate();
    }
  };

  const handleDateSelect = (event) => {
    setDate(event);
    if (!listDateRange) {
      setSearchText();
    }
  };

  const handleFilterOptionValue = (event) => {
    setFilterOptionValue(event.target.value);
  };

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    if (listDateRange) {
      props.getTableData(
        pageSize,
        1,
        getFilterExpression(
          paginationConstants.PAGINATION_FILTER_TYPE.LIST_DATE_RANGE,
          searchText,
          filterOptionValue,
          date
        )
      );
    } else {
      date
        ? props.getTableData(
            pageSize,
            1,
            getFilterExpression(
              paginationConstants.PAGINATION_FILTER_TYPE.DATE_RANGE,
              date.unix,
              filterOptionValue
            )
          )
        : props.getTableData(
            pageSize,
            1,
            getFilterExpression(
              paginationConstants.PAGINATION_FILTER_TYPE.CONTAINS,
              searchText,
              filterOptionValue
            )
          );
    }
  };

  const handlePageChange = (page) => {
    if (listDateRange) {
      props.getTableData(
        pageSize,
        page,
        getFilterExpression(
          paginationConstants.PAGINATION_FILTER_TYPE.LIST_DATE_RANGE,
          searchText,
          filterOptionValue,
          date
        )
      );
    } else {
      date
        ? props.getTableData(
            pageSize,
            page,
            getFilterExpression(
              paginationConstants.PAGINATION_FILTER_TYPE.DATE_RANGE,
              date.unix,
              filterOptionValue
            )
          )
        : props.getTableData(
            pageSize,
            page,
            getFilterExpression(
              paginationConstants.PAGINATION_FILTER_TYPE.CONTAINS,
              searchText,
              filterOptionValue
            )
          );
    }
  };

  const handlepageSizeChange = (event) => {
    const perPage = parseInt(event.target.value, 10);
    if (listDateRange) {
      props.getTableData(
        perPage,
        1,
        getFilterExpression(
          paginationConstants.PAGINATION_FILTER_TYPE.LIST_DATE_RANGE,
          searchText,
          filterOptionValue,
          date
        )
      );
    } else {
      date
        ? props.getTableData(
            perPage,
            1,
            getFilterExpression(
              paginationConstants.PAGINATION_FILTER_TYPE.DATE_RANGE,
              date.unix,
              filterOptionValue
            )
          )
        : props.getTableData(
            perPage,
            1,
            getFilterExpression(
              paginationConstants.PAGINATION_FILTER_TYPE.CONTAINS,
              searchText,
              filterOptionValue
            )
          );
    }
  };

  return (
    <div
      className={`back-color pagination-con ${
        props.isTable ? "p-lr-8" : "p-lr-22"
      }`}
    >
      {totalRows > 0 && (
        <div className="d-flex mt-3 justify-content-between p-2">
          <div className="pagination-controls">
            <span className="showing-info mr-4">
              {t("showing")} {pageNumber * pageSize - pageSize + 1} {t("to")}{" "}
              {Math.min(pageNumber * pageSize, totalRows)} of {totalRows}
            </span>
          </div>
          <div className="rows-per-page">
            <button
              type="button"
              className="border-0 bg-transparent"
              onClick={() => handlePageChange(1)}
              disabled={pageNumber === 1}
            >
              <i className="fas fa-angle-double-left"></i>
            </button>
            <button
              type="button"
              className="border-0 bg-transparent"
              onClick={() => handlePageChange(pageNumber - 1)}
              disabled={pageNumber === 1}
            >
              <i className="fas fa-angle-left"></i>
            </button>
            <span className="current-page">
              {t("page")} {pageNumber} {t("of")}{" "}
              {Math.ceil(totalRows / pageSize)}
            </span>
            <button
              type="button"
              className="border-0 bg-transparent"
              onClick={() => handlePageChange(pageNumber + 1)}
              disabled={
                pageNumber === Math.ceil(totalRows / pageSize) ||
                totalRows === 0
              }
            >
              <i className="fas fa-angle-right"></i>
            </button>
            <button
              type="button"
              className="border-0 bg-transparent"
              onClick={() => handlePageChange(Math.ceil(totalRows / pageSize))}
              disabled={
                pageNumber === Math.ceil(totalRows / pageSize) ||
                totalRows === 0
              }
            >
              <i className="fas fa-angle-double-right"></i>
            </button>
          </div>
        </div>
      )}
      <br />
      <div className="d-flex justify-content-between p-2">
        <div className="rows-per-page d-flex ">
          <span className="mr-2 mt-1">{t("show")}</span>
          <select
            className="form-control mb-1 drop-down-style"
            value={pageSize}
            onChange={handlepageSizeChange}
          >
            {pageSizeOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <span className="ml-2 mt-1">{t("entries")}</span>
        </div>

        {props.filterOptions && (
          <form
            className="rows-per-page d-flex w-50"
            onSubmit={handleFilterSubmit}
          >
            <select
              className="form-control mr-1 w-100 h-100 drop-down-style"
              onChange={handleFilterOptionValue}
            >
              <option selected disabled value="" key={0}>
                Select Filter
              </option>
              {props.filterOptions.map((option) => {
                return filterOptionValue === option ? (
                  <option key={option} value={option} selected>
                    {option}
                  </option>
                ) : (
                  <option key={option} value={option}>
                    {option}
                  </option>
                );
              })}
            </select>

            {props.isDateIncluded &&
            (filterOptionValue.includes("date") ||
              filterOptionValue.includes("Date")) ? (
              <div className="form-control search">
                <DatePicker
                  value={date}
                  onChange={(e) => {
                    handleDateSelect(e);
                  }}
                />
              </div>
            ) : props.listDateRange ? (
              <DatePicker
                value={date}
                range={true}
                onChange={(e) => {
                  handleDateSelect(e);
                }}
              />
            ) : (
              <input
                type="text"
                placeholder="Search..."
                className="form-control search"
                style={{ height: "35px" }}
                maxLength={50}
                value={searchText}
                onChange={handleSearch}
              />
            )}
            {listDateRange && (
              <DatePicker
                value={date}
                range={true}
                rangeHover={true}
                highlightToday={false}
                style={{ marginLeft: "1%", marginRight: "2%", height: "34px" }}
                onChange={(e) => {
                  handleDateSelect(e);
                }}
              />
            )}
            <button className="pagination-search-btn">Search</button>
          </form>
        )}
      </div>
    </div>
  );
};

export default PaginationComponent;
