import i18next from "i18next";
import { toast } from "react-toastify";
import { customToast } from "../../shared";
import { custom, failure } from "./utilities";
import { monitoringReportService } from "../../services";
import { globalSystemConstants, reportConstants } from "../../constants";

const { REPORT_DATA } = reportConstants;

const getEventReportData = (data) => {
  return (dispatch) => {
    dispatch(custom(REPORT_DATA, globalSystemConstants.SPINNER_ACTIVATE));
    monitoringReportService
      .getEventReportData(data.fromDate, data.toDate)
      .then(
        (response) => {
          if (response.items.length === 0) {
            customToast("No Data Exists", toast.TYPE.ERROR);
          } else {
            dispatch(custom(response, REPORT_DATA));
          }
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, REPORT_DATA));
        }
      )
      .finally(() => {
        dispatch(custom(REPORT_DATA, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
};

const getShutterBReportData = (data) => {
  return (dispatch) => {
    dispatch(custom(REPORT_DATA, globalSystemConstants.SPINNER_ACTIVATE));
    monitoringReportService
      .getShutterBReportData(data.fromDate, data.toDate)
      .then(
        (response) => {
          if (response.items.length === 0) {
            customToast("No Data Exists", toast.TYPE.ERROR);
          } else {
            dispatch(custom(response, REPORT_DATA));
          }
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, REPORT_DATA));
        }
      )
      .finally(() => {
        dispatch(custom(REPORT_DATA, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
};

const getCustomerReportData = (data) => {
  return (dispatch) => {
    dispatch(custom(REPORT_DATA, globalSystemConstants.SPINNER_ACTIVATE));
    monitoringReportService
      .getCustomerReportData(data.fromDate, data.toDate)
      .then(
        (response) => {
          if (response.items.length === 0) {
            customToast("No Data Exists", toast.TYPE.ERROR);
          } else {
            dispatch(custom(response, REPORT_DATA));
          }
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, REPORT_DATA));
        }
      )
      .finally(() => {
        dispatch(custom(REPORT_DATA, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
};

const setReportData = (data) => {
  return (dispatch) => {
    dispatch(custom(data, REPORT_DATA));
  };
};

export const monitoringReportActions = {
  setReportData,
  getEventReportData,
  getShutterBReportData,
  getCustomerReportData,
};
