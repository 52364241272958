import responseHandler from "../system/server-response-handler";
import { getRequest } from "../system/server-call";

const getEventReportData = (startDate, endDate) => {
  return getRequest(
    `/api/v1/admin/Report/EventReport?StartDate=${startDate}&EndDate=${endDate}`,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const getShutterBReportData = (startDate, endDate) => {
  return getRequest(
    `/api/v1/admin/Report/ShutterBReport?StartDate=${startDate}&EndDate=${endDate}`,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const getCustomerReportData = (startDate, endDate) => {
  return getRequest(
    `/api/v1/admin/Report/CustomerReport?StartDate=${startDate}&EndDate=${endDate}`,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

export const monitoringReportService = {
  getEventReportData,
  getShutterBReportData,
  getCustomerReportData,
};
