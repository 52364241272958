import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { customToast } from "../../shared";
import { useTranslation } from "react-i18next";
import LoadingIcon from "mdi-react/LoadingIcon";
import DataTable from "react-data-table-component";
import Modal from "react-modal";
import { reelsActions } from "../../redux/actions";
import { reelsConstants } from "../../constants";

const { ADD_REEL_TYPE, GET_REEL_TYPE_DETAILS, REMOVE_REEL_TYPE } =
  reelsConstants;

const Reels = (props) => {
  const { t } = useTranslation("common");
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    featuredImage: null,
    sampleFile: null,
  });

  const spinner = useCallback(
    (type) => props.spinnerActions.findIndex((f) => f === type),
    [props.spinnerActions]
  );

  const columns = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      width: "350px",
    },
    {
      name: t("actions"),
      width: "300px",
      cell: (row) => (
        <div>
          <button
            type="button"
            className="payment-button"
            onClick={() => {
              props.removeReelType(row.id);
            }}
          >
            {t("Remove")}
          </button>
        </div>
      ),
      allowOverflow: true,
      button: true,
    },
  ];
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
      },
    },
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({ ...formData, [name]: files[0] });
  };

  // Function to show/hide the modal
  const openConfirmModal = () => {
    setConfirmModalIsOpen(true);
  };

  const closeConfirmModal = () => {
    setConfirmModalIsOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !formData.sampleFile.type.includes("mp4") &&
      !formData.sampleFile.type.includes("mov")
    ) {
      customToast(t("invalid_sample_file_type"), toast.TYPE.ERROR);
      return;
    } else {
      const formDataToSend = new FormData();
      formDataToSend.append("name", formData.name);
      formDataToSend.append("featuredImage", formData.featuredImage);
      formDataToSend.append("sampleFile", formData.sampleFile);
      props.addReelType(formDataToSend);
      closeConfirmModal();
    }
  };

  useEffect(() => {
    props.getReelTypes();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className="row mr-0">
        <div className="col-md-12">
          <div className="col-lg-12">
            <div className="tab-content">
              {spinner(ADD_REEL_TYPE) &&
              spinner(GET_REEL_TYPE_DETAILS) &&
              spinner(REMOVE_REEL_TYPE) ? (
                <div className="tab-pane fade show active">
                  <div className="d-flex align-items-center">
                    <button
                      className="btn btn-primary mb-0 btn-pos"
                      //   disabled={!selectedEvent.value}
                      onClick={() => {
                        openConfirmModal();
                      }}
                    >
                      Add Recap Type
                    </button>
                  </div>
                  <div className="mt-4">
                    <DataTable
                      columns={columns}
                      data={props.reelTypes}
                      highlightOnHover
                      responsive
                      customStyles={customStyles}
                      className="sticky-header-class"
                    />
                  </div>
                </div>
              ) : (
                <div className="text-center mt-4">
                  <LoadingIcon className="loading-spinner" />
                </div>
              )}
            </div>
          </div>
        </div>
        {/*Confirmation modal */}
        <Modal
          isOpen={confirmModalIsOpen}
          onRequestClose={closeConfirmModal}
          contentLabel="Confirm Modal"
          className="custom-confirmation-modaal"
          style={{
            content: {
              width: "300px",
              height: "200px",
              margin: "auto",
            },
          }}
        >
          <div className="modal-content">
            <form encType="multipart/form-data" onSubmit={handleSubmit}>
              <div className="row w-100">
                <div className="col-md-12 p-2">
                  <label>Name</label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div className="row w-100">
                <div className="col-md-12 p-2">
                  <label>Feature Image</label>
                  <input
                    type="file"
                    name="featuredImage"
                    className="form-control"
                    onChange={handleFileChange}
                    required
                  />
                </div>
              </div>
              <div className="row w-100">
                <div className="col-md-12 p-2">
                  <label>Sample File</label>
                  <input
                    type="file"
                    name="sampleFile"
                    className="form-control"
                    onChange={handleFileChange}
                    accept="video/*"
                    required
                  />
                </div>
              </div>
              <div className="row w-100">
                <div className="col-md-12 p-2 text-center">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </div>
            </form>
            <button onClick={closeConfirmModal} className="modal-close">
              <img
                src="/images/simpleCrossWhite.svg"
                alt="crossIcon"
                className="modal-close-img"
              />
            </button>
          </div>
        </Modal>
      </div>
    </>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  spinnerActions: state.system.spinnerActions,
  reelTypes: state.reels.reelTypes,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getReelTypes: () => dispatch(reelsActions.getReelTypes()),
  addReelType: (data) => dispatch(reelsActions.addReelType(data)),
  removeReelType: (id) => dispatch(reelsActions.removeReelType(id)),
});

// Mapping action and store the function via props
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Reels));
