import i18next from "i18next";
import { toast } from "react-toastify";
import { customToast, getFilterExpression } from "../../shared";
import { custom, failure } from "./utilities";
import { reelsServices } from "../../services";
import { globalSystemConstants, reelsConstants } from "../../constants";

const {
  ASSIGN_REEL_EVENT,
  GET_ASSIGNED_REEL_EVENTS,
  GET_UNASSIGNED_REEL_EVENTS,
  REMOVE_ASSIGNED_REEL_SHUTTERB,
  GET_REEL_DETAILS,
  GET_REEL_TYPE_DETAILS,
  GET_REEL_TYPE_BY_ID,
  ADD_REEL_TYPE,
  UPDATE_REEL_TYPE,
} = reelsConstants;

const getUnassignedReelEvents = () => {
  return (dispatch) => {
    dispatch(
      custom(GET_UNASSIGNED_REEL_EVENTS, globalSystemConstants.SPINNER_ACTIVATE)
    );
    reelsServices
      .getUnassignedReelEvents()
      .then(
        (response) => {
          dispatch(custom(response, GET_UNASSIGNED_REEL_EVENTS));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_UNASSIGNED_REEL_EVENTS));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            GET_UNASSIGNED_REEL_EVENTS,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
};

const getAssignedReelEvents = (
  pageSize = reelsConstants.DEFAULT_PAGINATION_PAGE_SIZE,
  pageIndex = 1,
  filterExpression = getFilterExpression("")
) => {
  return (dispatch) => {
    dispatch(
      custom(GET_ASSIGNED_REEL_EVENTS, globalSystemConstants.SPINNER_ACTIVATE)
    );
    reelsServices
      .getAssignedReelEvents(pageSize, pageIndex, filterExpression)
      .then(
        (response) => {
          dispatch(custom(response, GET_ASSIGNED_REEL_EVENTS));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_ASSIGNED_REEL_EVENTS));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            GET_ASSIGNED_REEL_EVENTS,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
};

const assignReelEventToShutterb = (data) => {
  return (dispatch) => {
    dispatch(custom(ASSIGN_REEL_EVENT, globalSystemConstants.SPINNER_ACTIVATE));
    reelsServices
      .assignReelEventToShutterb(data)
      .then(
        (response) => {
          customToast(i18next.t("assigned_successfully"), toast.TYPE.SUCCESS);
          dispatch(getAssignedReelEvents());
          dispatch(getUnassignedReelEvents());
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, ASSIGN_REEL_EVENT));
        }
      )
      .finally(() => {
        dispatch(
          custom(ASSIGN_REEL_EVENT, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const removeAssignedReelShutterb = (
  id,
  pageSize,
  pageNumber,
  filterExpression
) => {
  return (dispatch) => {
    dispatch(
      custom(
        REMOVE_ASSIGNED_REEL_SHUTTERB,
        globalSystemConstants.SPINNER_ACTIVATE
      )
    );
    reelsServices
      .removeAssignedReelShutterb(id)
      .then(
        (response) => {
          customToast(i18next.t("removed_successfully"), toast.TYPE.SUCCESS);
          dispatch(
            getAssignedReelEvents(pageSize, pageNumber, filterExpression)
          );
          dispatch(getUnassignedReelEvents());
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, REMOVE_ASSIGNED_REEL_SHUTTERB));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            REMOVE_ASSIGNED_REEL_SHUTTERB,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
};

const getReelDetails = (eventId) => {
  return (dispatch) => {
    dispatch(custom(GET_REEL_DETAILS, globalSystemConstants.SPINNER_ACTIVATE));
    reelsServices
      .getReelDetails(eventId)
      .then(
        (response) => {
          // in case of reel
          dispatch(custom(response, GET_REEL_DETAILS));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
        }
      )
      .finally(() => {
        dispatch(
          custom(GET_REEL_DETAILS, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const getReelTypes = () => {
  return (dispatch) => {
    dispatch(
      custom(GET_REEL_TYPE_DETAILS, globalSystemConstants.SPINNER_ACTIVATE)
    );
    reelsServices
      .getReelTypes()
      .then(
        (response) => {
          // in case of reel
          dispatch(custom(response, GET_REEL_TYPE_DETAILS));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
        }
      )
      .finally(() => {
        dispatch(
          custom(
            GET_REEL_TYPE_DETAILS,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
};

const getReelTypeById = (id) => {
  return (dispatch) => {
    dispatch(
      custom(GET_REEL_TYPE_BY_ID, globalSystemConstants.SPINNER_ACTIVATE)
    );
    reelsServices
      .getReelTypeById(id)
      .then(
        (response) => {
          // in case of reel
          dispatch(custom(response, GET_REEL_TYPE_BY_ID));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
        }
      )
      .finally(() => {
        dispatch(
          custom(GET_REEL_TYPE_BY_ID, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const addReelType = (data) => {
  return (dispatch) => {
    dispatch(custom(ADD_REEL_TYPE, globalSystemConstants.SPINNER_ACTIVATE));
    reelsServices
      .addReelType(data)
      .then(
        (response) => {
          customToast(i18next.t("done_successfully"), toast.TYPE.SUCCESS);
          dispatch(getReelTypes());
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
        }
      )
      .finally(() => {
        dispatch(
          custom(ADD_REEL_TYPE, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const updateReelType = (data) => {
  return (dispatch) => {
    dispatch(custom(UPDATE_REEL_TYPE, globalSystemConstants.SPINNER_ACTIVATE));
    reelsServices
      .updateReelType(data)
      .then(
        (response) => {
          // in case of reel
          // dispatch(custom(response, ADD_REEL_TYPE));
          // dispatch(custom(response, ADD_REEL_TYPE));
          getReelTypes();
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
        }
      )
      .finally(() => {
        dispatch(
          custom(UPDATE_REEL_TYPE, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const removeReelType = (id) => {
  return (dispatch) => {
    dispatch(custom(UPDATE_REEL_TYPE, globalSystemConstants.SPINNER_ACTIVATE));
    reelsServices
      .removeReelType(id)
      .then(
        (response) => {
          customToast(i18next.t("remove_successfully"), toast.TYPE.SUCCESS);
          dispatch(getReelTypes());
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
        }
      )
      .finally(() => {
        dispatch(
          custom(UPDATE_REEL_TYPE, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

export const reelsActions = {
  getAssignedReelEvents,
  getUnassignedReelEvents,
  assignReelEventToShutterb,
  removeAssignedReelShutterb,
  getReelDetails,
  getReelTypes,
  getReelTypeById,
  addReelType,
  updateReelType,
  removeReelType,
};
