import React, { useEffect } from "react";
import { connect } from "react-redux";
import EmailIcon from "mdi-react/EmailIcon";
import LoadingIcon from "mdi-react/LoadingIcon";
import { useLocation } from "react-router-dom";
import { Card, CardBody, Col, ButtonToolbar } from "reactstrap";
import { loginActions } from "../../../../redux/actions";

import { NavigateToPathWithGo } from "../../../../system";
import { useHistory } from "react-router-dom";

let serverCall = true;
const useQuery = () => new URLSearchParams(useLocation().search);

const EmailConfirmationCard = (props) => {
  const query = useQuery();
  const queryParam = query.toString();
  const history = useHistory();

  useEffect(() => {
    if (serverCall) {
      props.emailVerification(queryParam);
      serverCall = false;
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Col md={12}>
      <Card className="border-0">
        <CardBody className="m--auto">
          {props.isEmailedVerified ? (
            <div className="email-confirmation">
              <div className="email-confirmation__icon d-flex justify-content-center">
                <EmailIcon className="email-confirmation__mail d-flex email-icon-height email-icon-color" />
              </div>
              <h3 className="email-confirmation__title d-flex justify-content-center">
                {props.isAuthenticated
                  ? "Your e-mail address has been successfully verified"
                  : "Verifing your Email...."}
              </h3>
              <ButtonToolbar className="email-confirmation__buttons d-flex justify-content-center">
                {props.isAuthenticated ? (
                  // eslint-disable-next-line
                  <a
                    className="btn btn-info"
                    onClick={() =>
                      NavigateToPathWithGo(history, "/change_password")
                    }
                  >
                    Create Password
                  </a>
                ) : (
                  <div className="spinner pl-2">
                    <LoadingIcon className="loading-spinner"/>
                  </div>
                )}
              </ButtonToolbar>
            </div>
          ) : (
            <div className="email-confirmation">
              <div className="email-confirmation__icon d-flex justify-content-center">
                <EmailIcon className="email-confirmation__mail d-flex email-icon-height" />
              </div>
              <h3 className="email-confirmation__title d-flex justify-content-center">
                {" "}
                Your Verification Email Link is Expired.
              </h3>
            </div>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  isAuthenticated: state.authentication.isAuthenticated,
  isEmailedVerified: state.authentication.isEmailedVerified,
});

const mapDispatchToProps = (dispatch) => ({
  emailVerification: (queryParam) =>
    dispatch(loginActions.emailVerification(queryParam)),
});

// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailConfirmationCard);
