import i18next from "i18next";
import { toast } from "react-toastify";
import { customToast } from "../../shared";
import { custom, failure } from "./utilities";
import { messageBoardService } from "../../services";
import { globalSystemConstants, messageBoardConstants } from "../../constants";

const {
  GET_SHUTTERB_BY_EVENT_ID,
  GET_SB_BY_ID,
  FORM_STATE_OF_MESSAGE_BOARD,
  GET_MESSAGES,
} = messageBoardConstants;

const getshutterbByEventId = (eventId) => {
  return (dispatch) => {
    dispatch(
      custom(GET_SHUTTERB_BY_EVENT_ID, globalSystemConstants.SPINNER_ACTIVATE)
    );
    messageBoardService
      .getshutterbByEventId(eventId)
      .then(
        (response) => {
          if (response) {
              dispatch(custom(response, GET_SHUTTERB_BY_EVENT_ID));
          }
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_SHUTTERB_BY_EVENT_ID));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            GET_SHUTTERB_BY_EVENT_ID,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
};

const getMessages = (eventId, sbUserId) => {
  return (dispatch) => {
    dispatch(
      custom(GET_MESSAGES, globalSystemConstants.SPINNER_ACTIVATE)
    );
    messageBoardService
      .getMessages(eventId, sbUserId)
      .then(
        (response) => {
          if (response) {
            dispatch(custom(response, GET_MESSAGES));
          }
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_MESSAGES));
        }
      )
      .finally(() => {
        dispatch(
          custom(GET_MESSAGES, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const getSBById = (eventId, sbUserId) => {
  return (dispatch) => {
    dispatch(custom(GET_MESSAGES, globalSystemConstants.SPINNER_ACTIVATE));
    messageBoardService
      .getSBById(eventId, sbUserId)
      .then(
        (response) => {
          if (response) {
            dispatch(custom(response, GET_SB_BY_ID));
          }
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_SB_BY_ID));
        }
      )
      .finally(() => {
        dispatch(
          custom(GET_SB_BY_ID, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

function setFormState(data) {
  return (dispatch) => {
    dispatch(custom(data, FORM_STATE_OF_MESSAGE_BOARD));
  };
}


export const messageBoardActions = {
  getshutterbByEventId,
  setFormState,
  getMessages,
  getSBById,
};
