import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import App from "./container/App/App";
import store from "./system/store";
import customHttpsRedirect from "./container/App/customHttpsRedirect";
import ErrorBoundary from "./shared/components/ErrorBoundary/ErrorBoundary";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN_KEY,
  integrations: [new Sentry.BrowserTracing({}), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.3, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const HttpsRedirectedApp = customHttpsRedirect(App);

ReactDOM.render(
  <React.Fragment>
    <ToastContainer />
    {/* Main Redux Store */}
    <Provider store={store}>
      <ErrorBoundary>
        <HttpsRedirectedApp />
      </ErrorBoundary>
    </Provider>
  </React.Fragment>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
