import i18next from "i18next";
import { toast } from "react-toastify";
import { customToast, getFilterExpression } from "../../shared";
import { custom, failure } from "./utilities";
import { customersServices } from "../../services";
import { customersConstants, globalSystemConstants } from "../../constants";

const { GET_CUSTOMERS, ACTIVATE_DEACTIVATE_CUSTOMER } = customersConstants;

const getCustomers = (
  pageSize = 5,
  pageNumber = 1,
  filterExpression = getFilterExpression("")
) => {
  return (dispatch) => {
    dispatch(custom(GET_CUSTOMERS, globalSystemConstants.SPINNER_ACTIVATE));
    customersServices
      .getCustomers(pageSize, pageNumber, filterExpression)
      .then(
        (response) => {
          dispatch(custom(response, GET_CUSTOMERS));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_CUSTOMERS));
        }
      )
      .finally(() => {
        dispatch(
          custom(GET_CUSTOMERS, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const activateDeactivateCustomer = (data) => {
  return (dispatch) => {
    dispatch(
      custom(
        ACTIVATE_DEACTIVATE_CUSTOMER,
        globalSystemConstants.SPINNER_ACTIVATE
      )
    );
    customersServices
      .activateDeactivateCustomer(data)
      .then(
        (response) => {
          if (response) {
            if (response.items.isActive) {
              customToast(i18next.t("customer_activated"), toast.TYPE.SUCCESS);
            } else {
              customToast(
                i18next.t("customer_deactivated"),
                toast.TYPE.SUCCESS
              );
            }
            dispatch(custom(response, ACTIVATE_DEACTIVATE_CUSTOMER));
            dispatch(getCustomers());
          }
        },
        (error) => {
          customToast(i18next.t(error[0]), toast.TYPE.ERROR);
          dispatch(failure(error, ACTIVATE_DEACTIVATE_CUSTOMER));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            ACTIVATE_DEACTIVATE_CUSTOMER,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
};

export const customersActions = {
  getCustomers,
  activateDeactivateCustomer,
};
