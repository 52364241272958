import { connect } from "react-redux";
import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import LoadingIcon from "mdi-react/LoadingIcon";
import { marketingConstants, paginationConstants } from "../../../constants";
import { marketingActions } from "../../../redux/actions";
import {
  getFilterExpression,
  DownloadExcel,
  getCurrentMonthStartAndEndEpochInMilliseconds,
  customToast,
  convertDateToUTC,
} from "../../../shared";
import PaginationComponent from "../../../shared/components/PaginationComponent/PaginationComponent";
// import jsPDF from "jspdf";
import "jspdf-autotable";
import { toast } from "react-toastify";

const { GET_REPORTS_DATA } = marketingConstants;

const Reports = (props) => {
  // eslint-disable-next-line
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({
    pageSize: marketingActions.DEFAULT_PAGINATION_PAGE_SIZE,
    currentPage: 1,
    totalRows: 0,
  });
  const [date, setDate] = useState(
    getCurrentMonthStartAndEndEpochInMilliseconds()
  );
  const [filterOptionValue, setFilterOptionValue] = useState("");
  const filterOptions = [
    "Code",
    "PartnerName",
    "PartnerEmail",
    "EventId",
    "EventName",
    "EventHostEmail",
  ];

  const spinner = useCallback(
    (type) => props.spinnerActions.findIndex((f) => f === type),
    [props.spinnerActions]
  );

  const { t } = useTranslation("common");
  const columns = [
    {
      name: t("code"),
      selector: "code",
      sortable: true,
      width: "150px",
      cell: (row) => <div className="word-wrap">{row.code}</div>,
    },
    {
      name: t("partner_name"),
      selector: "partnerName",
      sortable: true,
      width: "200px",
      cell: (row) => <div className="word-wrap">{row.partnerName}</div>,
    },
    {
      name: t("Partner Email Id"),
      selector: "partnerEmail",
      sortable: true,
      width: "200px",
      cell: (row) => <div className="word-wrap">{row.partnerEmail}</div>,
    },
    {
      name: t("Event Id"),
      selector: "eventId",
      sortable: true,
      width: "130px",
    },
    {
      name: t("event_date"),
      selector: "eventDate",
      sortable: true,
      width: "150px",
      cell: (row) => {
        return convertDateToUTC(row?.eventDate);
      },
    },
    {
      name: t("Created At"),
      selector: "createdAt",
      sortable: true,
      width: "150px",
      cell: (row) => {
        return convertDateToUTC(row?.createdAt);
      },
    },
    {
      name: t("event_name"),
      selector: "eventName",
      sortable: true,
      width: "150px",
      cell: (row) => <div className="word-wrap">{row.eventName}</div>,
    },
    {
      name: t("event_host_email"),
      selector: "eventHostEmail",
      sortable: true,
      width: "250px",
      cell: (row) => <div className="word-wrap">{row.eventHostEmail}</div>,
    },
    {
      name: t("shutterbHours"),
      selector: "shutterbHours",
      sortable: true,
      width: "150px",
    },
    {
      name: t("Hour Cost"),
      selector: "hourCost",
      sortable: true,
      width: "250px",
    },
    {
      name: t("Hours Subtotal"),
      selector: "hourSubtotal",
      sortable: true,
      width: "150px",
    },
    {
      name: t("Recap(z)"),
      selector: "reels",
      sortable: true,
      width: "150px",
    },
    {
      name: t("Recap Cost"),
      selector: "reelCost",
      sortable: true,
      width: "150px",
    },
    {
      name: t("Recap Subtotal"),
      selector: "reelSubtotal",
      sortable: true,
      width: "150px",
    },
    {
      name: t("Promo Code Applied"),
      selector: "promoCodeApplied",
      sortable: true,
      width: "170px",
    },
    {
      name: t("Applied Discount"),
      selector: "appliedDiscount",
      sortable: true,
      width: "150px",
    },
    {
      name: t("Total Basket"),
      selector: "totalBasket",
      sortable: true,
      width: "150px",
      cell: (row) => {
        return "$ " + row.totalBasket;
      },
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
      },
    },
  };

  useEffect(() => {
    props.getReports(
      pagination.pageSize,
      pagination.pageNumber,
      getFilterExpression(
        paginationConstants.PAGINATION_FILTER_TYPE.LIST_DATE_RANGE,
        searchText,
        filterOptionValue,
        date
      )
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPagination({
      pageSize: props.reportsData?.pageSize,
      pageNumber: props.reportsData?.currentPage,
      totalRows: props.reportsData?.total,
    });
  }, [props.reportsData]);

  const ExportToExcel = async (excelData) => {
    if (excelData.length > 0) {
      const exportData = [];
      for (let index = 0; index < excelData.length; index++) {
        const element = excelData[index];

        // Create a new object for each iteration
        const newData = {
          Code: element.code,
          PartnerName: element.partnerName,
          PartnerEmailId: element.partnerEmail,
          EventId: element.eventId,
          EventDate: convertDateToUTC(element.eventDate),
          CreatedAt: convertDateToUTC(element.createdAt),
          EventName: element.eventName,
          EventHostEmail: element.eventHostEmail,
          shutterbHours: element.shutterbHours,
          HourCost: element.hourCost,
          HoursSubtotal: element.hourSubtotal,
          Reels: element.reels,
          ReelCost: element.reelCost,
          ReelSubtotal: element.reelSubtotal,
          PromoCodeApplied: element.promoCodeApplied,
          AppliedDiscount: element.appliedDiscount,
          BasketTotal: "$ " + element.totalBasket,
        };

        // Push the new object into the exportData array
        exportData.push(newData);
      }
      DownloadExcel(exportData, "AffiliateMarketingReport");
    } else {
      customToast("No Data Exists", toast.TYPE.ERROR);
    }
  };
  // const generatePdf = () => {
  //   if (props?.reportsData?.items.length > 0) {
  //     var doc = new jsPDF("p", "pt");
  //     const logoUrl = "/images/logo.png"; // Replace with the path to your logo image
  //     const logoWidth = 0; // Adjust the width of the logo as needed
  //     const logoHeight = 30; // Adjust the height of the logo as needed
  //     // const centerX = (doc.internal.pageSize.width - logoWidth) / 2;
  //     doc.setFontSize(12);
  //     doc.addImage(logoUrl, "PNG", 200, 30 , logoWidth, logoHeight);
  //     doc.addFont("calibri", "bold");
  //     doc.text(40, 75, "Marketing Report");
  //     doc.text(40, 100, convertDateToUTC(date[0]/1000)+" - "+convertDateToUTC(date[1]/1000));
  //     const columns = [
  //       "Code",
  //       "Partner Name",
  //       "Event Id",
  //       "Event Date",
  //       "Created At",
  //       "Event Name",
  //       "Event Host Email",
  //       "shutterb Hours",
  //       "Reels",
  //     ];
  //     const rows =
  //       props?.reportsData?.items.map((item) => [
  //         item.code || "", // Replace with the actual property names from your data
  //         item.partnerName || "",
  //         item.eventId || "",
  //         convertDateToUTC(item.eventDate) || "",
  //         convertDateToUTC(item.createdAt) || "",
  //         item.eventName || "",
  //         item.eventHostEmail || "",
  //         item.totalShutterBHours || 0,
  //         item.reelCount || 0,
  //       ]) || [];

  //     // Set the table options
  //     const options = {
  //       startY: 110, // Vertical position to start the table (in mm)
  //       styles: {
  //         fontSize: 7,
  //       },
  //     };

  //     // Generate the table
  //     doc.autoTable(columns, rows, options);
  //     doc.save("AffiliateMarketingReport.pdf");
  //   } else {
  //     customToast("No Data Exists", toast.TYPE.ERROR);
  //   }
  // };
  return (
    <div className="row mr-0">
      <div className="col-md-12">
        <div className="col-md-12 mx-0">
          <div className="tab-content">
            {spinner(GET_REPORTS_DATA) ? (
              <div className="tab-pane fade show active">
                <div className="pagination-controls">
                  <button
                    type="button"
                    className="btn btn-info"
                    onClick={() => ExportToExcel(props?.reportsData?.items)}
                  >
                    Download Excel
                  </button>
                  {/* <button
                    type="button"
                    className="btn btn-info"
                    onClick={() => generatePdf()}
                  >
                    Download PDF
                  </button> */}
                  <PaginationComponent
                    pagination={pagination}
                    setPagination={setPagination}
                    searchText={searchText}
                    setSearchText={setSearchText}
                    getTableData={props.getReports}
                    filterOptionValue={filterOptionValue}
                    setFilterOptionValue={setFilterOptionValue}
                    filterOptions={filterOptions}
                    listDateRange={true}
                    isDateIncluded
                    date={date}
                    setDate={setDate}
                  />
                </div>
                <DataTable
                  columns={columns}
                  data={props.reportsData.items}
                  highlightOnHover
                  responsive
                  customStyles={customStyles}
                  className="sticky-header-class"
                />
              </div>
            ) : (
              <div className="text-center mt-4">
                <LoadingIcon className="loading-spinner" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  reportsData: state.marketing.reportsData,
  spinnerActions: state.system.spinnerActions,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getReports: (pageSize, pageIndex, filterExpression) =>
    dispatch(
      marketingActions.getReports(pageSize, pageIndex, filterExpression)
    ),
});

// Mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Reports));
