import i18next from "i18next";
import { toast } from "react-toastify";
import { customToast, getFilterExpression } from "../../shared";
import { custom, failure } from "./utilities";
import { shutterBsServices } from "../../services";
import {
  globalSystemConstants,
  paginationConstants,
  shutterBsConstants,
  shutterBsStatusConstants,
} from "../../constants";

const {
  GET_SHUTTER_BS,
  APPROVE_SHUTTER_B,
  ENABLE_DISABLE_SHUTTER_B,
  UPDATE_HOURLY_RATE,
  GET_SHUTTER_B_BY_ID,
  GET_SHUTTER_B_PORTFOLIO
} = shutterBsConstants;

const getShutterBs = (
  tab,
  pageSize = 5,
  pageNumber = 1,
  searchText = "",
  filterOptionValue = ""
) => {
  return (dispatch) => {
    dispatch(custom(GET_SHUTTER_BS, globalSystemConstants.SPINNER_ACTIVATE));
    const tabNumber = tab?.startsWith(":tab=") ? tab.replace(":tab=", "") : tab;
    const filteredExpressionAsPerTab =
      tabNumber === "1"
        ? getFilterExpression(
            paginationConstants.PAGINATION_FILTER_TYPE
              .SHUTTERB_NEW_APPLICANT_TAB,
            searchText,
            filterOptionValue
          )
        : tabNumber === "2"
        ? getFilterExpression(
            paginationConstants.PAGINATION_FILTER_TYPE.SHUTTERB_ACTIVE_TAB,
            searchText,
            filterOptionValue
          )
        : tabNumber === "3"
        ? getFilterExpression(
            paginationConstants.PAGINATION_FILTER_TYPE.SHUTTERB_ARCHIVE_TAB,
            searchText,
            filterOptionValue
          )
        : tabNumber === "4"
        ? getFilterExpression(
            paginationConstants.PAGINATION_FILTER_TYPE.SHUTTERB_HOLD_TAB,
            searchText,
            filterOptionValue
          )
        : getFilterExpression("");

    shutterBsServices
      .getShutterBs(pageSize, pageNumber, filteredExpressionAsPerTab)
      .then(
        (response) => {
          dispatch(custom(response, GET_SHUTTER_BS));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_SHUTTER_BS));
        }
      )
      .finally(() => {
        dispatch(
          custom(GET_SHUTTER_BS, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const approveShutterB = (data, tab, status) => {
  return (dispatch) => {
    dispatch(custom(APPROVE_SHUTTER_B, globalSystemConstants.SPINNER_ACTIVATE));
    shutterBsServices
      .approveShutterB(data)
      .then(
        (response) => {
          if (response) {
            if (status === shutterBsStatusConstants.APPROVED) {
              const message = i18next.t("email_send");
              customToast(message, toast.TYPE.SUCCESS);
            } else if (status === shutterBsStatusConstants.REJECTED) {
              const message = i18next.t("shutterb_rejected");
              customToast(message, toast.TYPE.SUCCESS);
            } else if (status === shutterBsStatusConstants.CREATED) {
              const message = i18next.t("shutterb_approved");
              customToast(message, toast.TYPE.SUCCESS);
            }
            else if (status === shutterBsStatusConstants.HOLD) {
              const message = i18next.t("updated_successfully");
              customToast(message, toast.TYPE.SUCCESS);
            }
            dispatch(custom(response, APPROVE_SHUTTER_B));
            dispatch(getShutterBs(tab));
          }
        },
        (error) => {
          customToast(i18next.t(error[0]), toast.TYPE.ERROR);
          dispatch(failure(error, APPROVE_SHUTTER_B));
        }
      )
      .finally(() => {
        dispatch(
          custom(APPROVE_SHUTTER_B, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const enableDisableShutterB = (data, tab) => {
  return (dispatch) => {
    dispatch(
      custom(ENABLE_DISABLE_SHUTTER_B, globalSystemConstants.SPINNER_ACTIVATE)
    );
    shutterBsServices
      .enableDisableShutterB(data)
      .then(
        (response) => {
          if (response) {
            if (response.items.isActive) {
              customToast(i18next.t("shutterb_activated"), toast.TYPE.SUCCESS);
            } else {
              customToast(
                i18next.t("shutterb_diactivated"),
                toast.TYPE.SUCCESS
              );
            }
            dispatch(custom(response, ENABLE_DISABLE_SHUTTER_B));
            dispatch(getShutterBs(tab));
          }
        },
        (error) => {
          customToast(i18next.t(error[0]), toast.TYPE.ERROR);
          dispatch(failure(error, ENABLE_DISABLE_SHUTTER_B));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            ENABLE_DISABLE_SHUTTER_B,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
};

const updateHourlyRate = (data, setModalIsOpen, tab) => {
  return (dispatch) => {
    dispatch(
      custom(UPDATE_HOURLY_RATE, globalSystemConstants.SPINNER_ACTIVATE)
    );
    shutterBsServices
      .updateHourlyRate(data)
      .then(
        (response) => {
          customToast(i18next.t("hourly_rate_updated"), toast.TYPE.SUCCESS);
          dispatch(custom(response, UPDATE_HOURLY_RATE));
          setModalIsOpen(false);
          dispatch(getShutterBs(tab));
        },
        (error) => {
          customToast(i18next.t(error[0]), toast.TYPE.ERROR);
          dispatch(failure(error, UPDATE_HOURLY_RATE));
        }
      )
      .finally(() => {
        dispatch(
          custom(UPDATE_HOURLY_RATE, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const getShutterbById = (id) => {
  return (dispatch) => {
    dispatch(
      custom(GET_SHUTTER_B_BY_ID, globalSystemConstants.SPINNER_ACTIVATE)
    );
    shutterBsServices
      .getShutterbById(id)
      .then(
        (response) => {
          dispatch(custom(response, GET_SHUTTER_B_BY_ID));
          dispatch(getShutterbPortfolio(id));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_SHUTTER_B_BY_ID));
        }
      )
      .finally(() => {
        dispatch(
          custom(GET_SHUTTER_B_BY_ID, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const getShutterbPortfolio = (id) => {
  return (dispatch) => {
    dispatch(
      custom(GET_SHUTTER_B_PORTFOLIO, globalSystemConstants.SPINNER_ACTIVATE)
    );
    shutterBsServices
      .getShutterbPortfolio(id)
      .then(
        (response) => {
          dispatch(custom(response, GET_SHUTTER_B_PORTFOLIO));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_SHUTTER_B_PORTFOLIO));
        }
      )
      .finally(() => {
        dispatch(
          custom(GET_SHUTTER_B_PORTFOLIO, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

export const shutterBsActions = {
  getShutterBs,
  approveShutterB,
  updateHourlyRate,
  enableDisableShutterB,
  getShutterbById,
  getShutterbPortfolio
};
