import { HubConnectionBuilder } from "@microsoft/signalr";
import { getDecryptPayload } from "../system";

const startConnection = async (userId, sbUserId, eventId) => {
  const connection = new HubConnectionBuilder()
    .withUrl(
      `${window.config.serverBaseURL}messageBoardHub?userId=${userId}&sbUserId=${sbUserId}&eventId=${eventId}`,
      {
        accessTokenFactory: () => {
          const token = getDecryptPayload().token;
          return token;
        },
      }
    )
    .withAutomaticReconnect()
    .build();
  try {
    await connection.start();
  } catch (err) {
    console.error(err);
  }

  return connection;
};

const sendMessage = async (connection, data) => {
  try {
    await connection.invoke("SendMessage", data);
  } catch (err) {
    console.error(err);
  }
};

const recieveMessage = async (connection) => {
  try {
    await connection.on("ReceiveMessage", (data) => {
      return data;
    });
  } catch (err) {
    console.error(err);
  }
};
export { startConnection, sendMessage, recieveMessage };
