// eslint-disable-next-line import/no-cycle
import responseHandler from "../system/server-response-handler";
import { getRequest, putRequest, postRequest } from "../system/server-call";

const getUnassignedReelEvents = () => {
  return getRequest("/api/v1/admin/reel/GetEventReels", true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const getAssignedReelEvents = (pageSize, pageIndex, filterExpression) => {
  return getRequest(
    `/api/v1/admin/reel/?PageSize=${pageSize}&PageNumber=${pageIndex}&FilterExpression=${filterExpression}`,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const assignReelEventToShutterb = (data) => {
  return putRequest(`/api/v1/admin/reel/${data.id}`, data).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const removeAssignedReelShutterb = (id) => {
  return putRequest(
    `/api/v1/admin/reel/RemoveReelAssignee/${id}`,
    id,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const getReelDetails = (eventId) => {
  return getRequest(`/api/v1/admin/reel/GetReelBrief/${eventId}`, true).then(
    (response) => {
      return responseHandler.handleServerResponse(response);
    },
    (error) => {
      return responseHandler.handleHTTPResponses(error.response);
    }
  );
};

const getReelTypes = () => {
  return getRequest(`/api/v1/admin/reel/GetReelTemplates`, true).then(
    (response) => {
      return responseHandler.handleServerResponse(response);
    },
    (error) => {
      return responseHandler.handleHTTPResponses(error.response);
    }
  );
};

const getReelTypeById = (id) => {
  return getRequest(`/api/v1/admin/reel/GetReelTypeById/${id}`, true).then(
    (response) => {
      return responseHandler.handleServerResponse(response);
    },
    (error) => {
      return responseHandler.handleHTTPResponses(error.response);
    }
  );
};

const addReelType = (data) => {
  return postRequest(
    `/api/v1/admin/reel/AddReelType`,
    data,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const updateReelType = (id, data) => {
  return putRequest(`/api/v1/admin/reel/UpdateReelType/${id}`, data, true).then(
    (response) => responseHandler.handleServerMessageResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};


const removeReelType = (id) => {
  return putRequest(
    `/api/v1/admin/reel/RemoveReelType/${id}`,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

// exporting functions for other files
export const reelsServices = {
  getAssignedReelEvents,
  getUnassignedReelEvents,
  assignReelEventToShutterb,
  removeAssignedReelShutterb,
  getReelDetails,
  getReelTypes,
  getReelTypeById,
  addReelType,
  updateReelType,
  removeReelType
};
