import React, { useEffect, useCallback, useState, useRef } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import LoadingIcon from "mdi-react/LoadingIcon";
import { eventsActions } from "../../redux/actions";
import {
  eventsConstants,
  sharedConstants,
  globalSystemConstants,
} from "../../constants";
import ViewGallery from "../../shared/components/ViewGallery/ViewGallery";
import Spinner from "../../shared/components/Spinner/Spinner";
import {
  calculateTotalFileSizeInMbs,
  customToast,
  convertBytesToMbs,
} from "../../shared/utility";
import { Row, Col, Nav, NavLink, NavItem } from "reactstrap";

const { GET_EVENT_GALLERY, DELETE_FROM_GALLERY } = eventsConstants;

const ViewEventDetail = (props) => {
  const { t } = useTranslation("common");
  const fileInputRef = useRef(null);
  const galleryUrlRef = useRef(null);

  const [files, setFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [downloadedFiles, setDownloadedFiles] = useState(0);
  const [totalDownloadedFiles, setTotalDownloadedFiles] = useState(0);
  const currentFilePrevUploadedSizeRef = useRef(0);
  const [filesAdded, setFilesAdded] = useState(false);
  const [currentFileName, setCurrentFileName] = useState("");
  const [currentFileSize, setCurrentFileSize] = useState("");
  const [isFileTypeReel, setIsFileTypeReel] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [counterFilesUploaded, setCounterFilesUploaded] = useState(1);
  const [totalUploadedFileSize, setTotalUploadedFileSize] = useState(0);
  const [totalFileSizeToUpload, setTotalFileSizeToUpload] = useState(0);
  const [currentFileUploadedSize, setCurrentFileUploadedSize] = useState(0);
  const [counterTotalFilesToUpload, setCounterTotalFilesToUpload] = useState(0);
  const [activeTab, setActiveTab] = useState("activeTab");
  let isActive = activeTab === "activeTab" ? true : false;
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const spinner = useCallback(
    (type) => props.spinnerActions.findIndex((f) => f === type),
    [props.spinnerActions]
  );

  useEffect(() => {
    props.getEventGallery(props.currentEvent, true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFiles(props.eventGalleryData);
    // eslint-disable-next-line
  }, [props.eventGalleryData]);

  const handleDelete = async () => {
    const filesToDeleteFromServer = selectedFiles.filter((file) => file.id);
    const filesToRemoveLocally = selectedFiles.filter((file) => !file.id);

    if (filesToDeleteFromServer.length > 0) {
      const idArray = filesToDeleteFromServer.map((file) => file.id);
      props.deleteFromGallery(
        { ids: idArray },
        props.currentEvent,
        files,
        setFiles,
        selectedFiles,
        setSelectedFiles,
        isActive
      );
    }

    if (filesToRemoveLocally.length > 0) {
      setFiles((prevFiles) =>
        prevFiles.filter((file) => !filesToRemoveLocally.includes(file))
      );
    }

    if (
      filesToRemoveLocally.length > 0 &&
      filesToDeleteFromServer.length === 0
    ) {
      setSelectedFiles([]);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*, video/*, .heic, .heif",
    onDrop: (acceptedFiles) => {
      setFiles((prevFiles) =>
        prevFiles.concat(
          acceptedFiles.map((file) => ({
            file,
            url: URL.createObjectURL(file),
            selected: false,
          }))
        )
      );
    },
  });

  const handleButtonClick = (value) => {
    fileInputRef.current.click();
    setIsFileTypeReel(value);
  };

  const handleFileInputChange = async (event) => {
    const selectedFiles = event.target.files;
    if (selectedFiles.length > 0) {
      props.activateOverlay();
    }
    // size limit: total payload size limit
    let totalSelectedFilesArr = Array.from(selectedFiles);
    let totalPayloadSize = calculateTotalFileSizeInMbs(totalSelectedFilesArr);
    if (totalPayloadSize > process.env.REACT_APP_GALLERY_UPLOAD_PAYLOAD_LIMIT) {
      customToast(
        `Upto ${process.env.REACT_APP_GALLERY_UPLOAD_PAYLOAD_LIMIT} MBs are allowed in an attempt`,
        toast.TYPE.ERROR
      );
      props.diActivateOverlay();
      return null;
    }
    // size limit: each file size limit
    let fileWithInvalidSizeArr = totalSelectedFilesArr.filter((file) => {
      return (
        file.size / sharedConstants.KB_TO_MB_BYTES >=
        process.env.REACT_APP_GALLERY_UPLOAD_FILE_SIZE_LIMIT
      );
    });
    if (fileWithInvalidSizeArr.length > 0) {
      customToast(
        `Each File size should be under ${process.env.REACT_APP_GALLERY_UPLOAD_FILE_SIZE_LIMIT}MB.`,
        toast.TYPE.ERROR
      );
      props.diActivateOverlay();
      return null;
    }
    const convertedFiles = Array.from(selectedFiles).map((file) => {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (
        !file.type.includes("image") &&
        !file.type.includes("video") &&
        fileExtension !== "heic" &&
        fileExtension !== "heif"
      ) {
        customToast("File format not supported", toast.TYPE.ERROR);
        props.diActivateOverlay();
        return null;
      } else if (isFileTypeReel && !file.type.includes("video")) {
        customToast("Only Videos Supported In Reels", toast.TYPE.ERROR);
        props.diActivateOverlay();
        return null;
      }

      return {
        file,
        url: sharedConstants.IS_GALLERY_UPLOAD_PREVIEW,
        selected: false,
      };
    });

    // Filter out any null entries from unsupported formats
    const validFiles = convertedFiles.filter((file) => file !== null);

    setFiles((prevFiles) => {
      setFilesAdded(true); // Set the flag to true when files are added
      return [...prevFiles, ...validFiles];
    });
  };

  const handleAddToGallery = () => {
    let formdata = new FormData();
    const filesToSend = files.filter((file) => !file.id);
    filesToSend.forEach((file) => {
      formdata.append("file", file.file);
    });
    formdata.append("eventId", props.currentEvent);
    formdata.append("SbUserId", 0);
    formdata.append("UserId", props.userId);
    if (isFileTypeReel) {
      formdata.append("contentType", "reel");
    } else {
      formdata.append("contentType", "file");
    }
    if (filesToSend.length > 0) {
      const options = (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent <= 100) {
          setCurrentFileUploadedSize(loaded);
        }
      };

      props.addToGallery(
        formdata,
        options,
        null,
        counterFilesUploaded,
        setCounterFilesUploaded,
        totalUploadedFileSize,
        setTotalFileSizeToUpload,
        currentFilePrevUploadedSizeRef,
        setCurrentFileName,
        setCurrentFileSize,
        isActive
      );
      setIsFileTypeReel(false);
      setCounterFilesUploaded(1);
    } else {
      //customToast(t("files_already_uploaded"), toast.TYPE.ERROR);
    }
    setSelectedFiles([]);
  };

  useEffect(() => {
    setTotalUploadedFileSize(
      totalUploadedFileSize +
        convertBytesToMbs(currentFileUploadedSize) -
        convertBytesToMbs(currentFilePrevUploadedSizeRef.current)
    );
    // keeping percentage in float to pass this condition progress > 0 and to show it as soon as uploading starts
    let updatedPercentage = (
      ((totalUploadedFileSize +
        convertBytesToMbs(currentFileUploadedSize) -
        convertBytesToMbs(currentFilePrevUploadedSizeRef.current)) /
        totalFileSizeToUpload) *
      100
    ).toFixed(2);
    updatedPercentage <= 100 && updatedPercentage > 0
      ? setUploadPercentage(updatedPercentage)
      : setUploadPercentage(100);

    currentFilePrevUploadedSizeRef.current = currentFileUploadedSize;
    // eslint-disable-next-line
  }, [currentFileUploadedSize]);

  useEffect(() => {
    if (filesAdded) {
      setFilesAdded(false);
      // Call handleAddToGallery only if files have been added
      handleAddToGallery();
    }
    // eslint-disable-next-line
  }, [files]);

  useEffect(() => {
    setUploadPercentage(0);
    setTotalFileSizeToUpload(0);
    setTotalUploadedFileSize(0);
    currentFilePrevUploadedSizeRef.current = 0;
  }, [props.eventGalleryData]);

  function copyToClipboard(e) {
    galleryUrlRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    alert("Link Copied");
  }

  return (
    <>
      {spinner(GET_EVENT_GALLERY) && spinner(DELETE_FROM_GALLERY) ? (
        <>
          <div className="tab-pane fade show active">
            <div className="row d-flex justify-content-center">
              <div className="col-md-5 w-100 d-flex justify-content-center">
                <div {...getRootProps()} className="dropzone-content">
                  <input
                    {...getInputProps()}
                    ref={fileInputRef}
                    disabled={uploadPercentage > 0}
                    onChange={handleFileInputChange}
                  />
                  <button
                    className="gallery-btn mr-md-4 mb-2 mw-175"
                    disabled={uploadPercentage > 0}
                    onClick={() => handleButtonClick(false)}
                  >
                    Upload files
                  </button>
                  <button
                    className="gallery-btn mr-md-4 mb-2 mw-175"
                    disabled={uploadPercentage > 0}
                    onClick={() => handleButtonClick(true)}
                  >
                    Upload Reels
                  </button>
                </div>
              </div>
            </div>
            {/* <p className="p-0 d-flex justify-content-center m-0 mb-2">
              Share this gallery with your guests
            </p> */}
            <div className="row d-flex justify-content-center">
              <div className="col-md-5 w-100 d-flex justify-content-center">
                <input
                  type="text"
                  readonly="true"
                  name="galleryLink"
                  className="w-100 p-2 my-2"
                  ref={galleryUrlRef}
                  // style={{width:"100%"}}
                  value={`${window.config.sharedGalleryUrl}${props.currentEvent}`}
                />
              </div>
              <div className="col-md-1 w-100 d-flex justify-content-center">
                <button
                  className="gallery-btn my-2 mr-md-4 h-75 mb-3 mw-175"
                  onClick={copyToClipboard}
                >
                  COPY
                </button>
              </div>
            </div>
            <Row className="my-3">
              <Col>
                <Nav tabs style={{ height: "31px" }}>
                  <NavItem>
                    <NavLink
                      className={`${activeTab === "activeTab" ? "active" : ""}`}
                      onClick={() => {
                        toggle("activeTab");
                        props.getEventGallery(props.currentEvent, true);
                      }}
                      style={{ padding: "13px" }}
                    >
                      Active Gallery
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={` ${
                        activeTab === "inActiveTab" ? "active" : ""
                      }`}
                      onClick={() => {
                        toggle("inActiveTab");
                        props.getEventGallery(props.currentEvent, false);
                      }}
                      style={{ padding: "13px" }}
                    >
                      Deleted Items
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
            <div className="">
              {files?.length > 0 ? (
                <ViewGallery
                  files={files}
                  isModalAllowed
                  isAllowDownload
                  isShareAllowed
                  isAsyncUploadCall
                  setFiles={setFiles}
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                  downloadSelectedFiles={props.downloadSelectedFiles}
                  handleDelete={handleDelete}
                  downloadedFiles={downloadedFiles}
                  setDownloadedFiles={setDownloadedFiles}
                  setTotalDownloadedFiles={setTotalDownloadedFiles}
                  progress={uploadPercentage}
                  counterFilesUploaded={counterFilesUploaded}
                  counterTotalFilesToUpload={counterTotalFilesToUpload}
                  setCounterTotalFilesToUpload={setCounterTotalFilesToUpload}
                  setCounterFilesUploaded={setCounterFilesUploaded}
                  currentFileName={currentFileName}
                  setCurrentFileName={setCurrentFileName}
                  currentFileSize={currentFileSize}
                  setCurrentFileSize={setCurrentFileSize}
                  activeFlg={activeTab}
                  isTabsDisplay
                />
              ) : (
                <div className="d-flex justify-content-center align-items-center">
                  <p>
                    {activeTab === "activeTab"
                      ? t("no_images")
                      : t("no_deleted_files")}
                  </p>
                </div>
              )}
            </div>
            <div className="col-md-6 justify-content-start d-flex mt-4 px-0">
              <button
                type="btn"
                className="btn btn-info"
                onClick={() => props.onClose()}
              >
                Back
              </button>
            </div>
          </div>
          <Spinner
            downloadedFiles={downloadedFiles}
            totalFiles={totalDownloadedFiles}
          />
        </>
      ) : (
        <div className="text-center mt-4">
          <LoadingIcon className="loading-spinner" />
        </div>
      )}
    </>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  userId: state.authentication.userId,
  spinnerActions: state.system.spinnerActions,
  eventGalleryData: state.events.eventGalleryData,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getEventGallery: (id, isActive) =>
    dispatch(eventsActions.getEventGallery(id, isActive)),
  downloadSelectedFiles: (eventIdsArr, setDownloadedFiles) =>
    dispatch(
      eventsActions.downloadSelectedFiles(eventIdsArr, setDownloadedFiles)
    ),
  deleteFromGallery: (
    dataObj,
    eventId,
    files,
    setFiles,
    selectedFiles,
    setSelectedFiles,
    isActive
  ) =>
    dispatch(
      eventsActions.deleteFromGallery(
        dataObj,
        eventId,
        files,
        setFiles,
        selectedFiles,
        setSelectedFiles,
        isActive
      )
    ),
  addToGallery: (
    data,
    options,
    dummy,
    counterFilesUploaded,
    setCounterFilesUploaded,
    totalUploadedFileSize,
    setTotalFileSizeToUpload,
    currentFilePrevUploadedSizeRef,
    setCurrentFileName,
    setCurrentFileSize,
    isActive
  ) =>
    dispatch(
      eventsActions.addToGallery(
        data,
        options,
        dummy,
        counterFilesUploaded,
        setCounterFilesUploaded,
        totalUploadedFileSize,
        setTotalFileSizeToUpload,
        currentFilePrevUploadedSizeRef,
        setCurrentFileName,
        setCurrentFileSize,
        isActive
      )
    ),
  activateOverlay: () =>
    dispatch({
      type: globalSystemConstants.OVERLAY_ACTIVATE,
      data: "ADD_TO_GALLERY",
    }),
  diActivateOverlay: () =>
    dispatch({
      type: globalSystemConstants.OVERLAY_DIACTIVATE,
      data: "ADD_TO_GALLERY",
    }),
});

// Mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ViewEventDetail));
