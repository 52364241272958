import React, { useState } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import MainWrapper from "../MainWrapper";
import PrivateRoute from "./PrivateRoute";
import LogIn from "../../Views/Login/index";
import routes from "../../../system/routes";
import Topbar from "../../Layout/topbar/Topbar";
import Sidebar from "../../Layout/sidebar/Sidebar";
import ResetPassword from "../../Views/ResetPassword";
import EmailConfirmation from "../../Views/EmailConfimation";
import ChangePassword from "../../Views/ChangePassword";
import Overlay from "../../../shared/components/Overlay/Overlay";
import { userType } from "../../../constants";
import ReelDetailModal from "../../../views/Reels/ReelDetailModal";

const RouterWrapper = (props) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <>
      <MainWrapper>
        <main>
          <Switch>
            <Route exact path="/" component={() => <LogIn />} />
            <Route path="/log_in" component={() => <LogIn />} />
            <Route path="/reset_password" component={ResetPassword} />
            <Route path="/change_password" component={ChangePassword} />
            <Route
              path="/email_confirmation/:queryparam"
              component={EmailConfirmation}
            />
            <>
              <div className="wrapper">
                <Sidebar
                  isSidebarOpen={isSidebarOpen}
                  setIsSidebarOpen={setIsSidebarOpen}
                />
                <div id="content" className="overflow-x-hidden">
                  <Topbar
                    setIsSidebarOpen={setIsSidebarOpen}
                    isSidebarOpen={isSidebarOpen}
                  />
                  {props.userType === userType.ADMIN ? (
                    routes.map((prop, key) => (
                      <PrivateRoute
                        key={key}
                        path={prop.path}
                        component={prop.component}
                        {...props}
                      />
                    ))
                  ) : (
                    <Redirect
                      to={{ pathname: "/", state: { from: props.location } }}
                    />
                  )}
                  <ReelDetailModal />
                </div>
              </div>
            </>
          </Switch>
        </main>
      </MainWrapper>
      <Overlay />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.authentication.isAuthenticated,
    userType: state.authentication.userType,
  };
};

export default connect(mapStateToProps)(React.memo(RouterWrapper));
