import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import LoadingIcon from "mdi-react/LoadingIcon";
import { draftEventsActions, configurationsActions } from "../../redux/actions";
import { configurationsConstants, draftEventsConstants } from "../../constants";
import Modal from "react-modal";
import Select from "react-select";
import DataTable from "react-data-table-component";
import PaginationComponent from "../../shared/components/PaginationComponent/PaginationComponent";
import { toast } from "react-toastify";
import {
  customToast,
  getFilterExpression,
  convertDateToUTC,
} from "../../shared";
const {
  GET_DRAFT_EVENTS,
  CANCEL_DRAFT_EVENT,
  VIEW_DRAFT_EVENT_SHUTTERBS,
  ASSIGN_SHUTTERBS_TO_DRAFT_EVENT,
  UNASSIGN_SHUTTERBS_TO_DRAFT_EVENT,
  SEND_CART_LINK_TO_CUSTOMER,
} = draftEventsConstants;

const DraftEvents = (props) => {
  const spinner = useCallback(
    (type) => props.spinnerActions.findIndex((f) => f === type),
    [props.spinnerActions]
  );
  const { t } = useTranslation("common");
  const [expandedCard, setExpandedCard] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [eventId, setEventId] = useState(null);
  const [pagination, setPagination] = useState({
    pageSize: configurationsConstants.DEFAULT_PAGINATION_PAGE_SIZE,
    pageNumber: 1,
    totalRows: 0,
  });
  const [filterOptionValue, setFilterOptionValue] = useState("");
  const filterOptions = ["EventName", "Id", "EventDate"];
  const [date, setDate] = useState();
  const [numRows, setNumRows] = useState(1);

  const handleIncrementRows = () => {
    // Increase the number of rows by 1
    if (numRows <= selectedUsers.length) {
      setNumRows(numRows + 1);
    } else {
      customToast(t("Select shutterb first"), toast.TYPE.ERROR);
    }
  };

  const handleDeleteRow = (index) => {
    if (selectedUsers && selectedUsers.length > 0 && selectedUsers[index]) {
      const matchingEventShutterb = props.eventShutterbs.find(
        (shutterb) => shutterb.sbuserId === selectedUsers[index].value
      );
      if (matchingEventShutterb) {
        const matchingEventShutterbId = matchingEventShutterb.id;
        //will hit endpoint here
        props.unAssignShutterbToDraftEvent(
          matchingEventShutterbId,
          pagination.pageSize,
          pagination.pageNumber,
          getFilterExpression(searchText),
          numRows,
          setNumRows
        );
      }
      const updatedSelectedUsers = [...selectedUsers];
      updatedSelectedUsers.splice(index, 1);
      setSelectedUsers(updatedSelectedUsers);
      setNumRows(numRows - 1);
    } else if (numRows > 1 && selectedUsers.length <= numRows) {
      setNumRows(numRows - 1);
    }
  };

  const handleCollapseToggle = (cardId) => {
    setExpandedCard(cardId === expandedCard ? null : cardId);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const columns = [
    {
      width: "100%",
      cell: (row) => (
        <React.Fragment key={row.id}>
          <div className="card card-style pagination-card-width mb-2">
            <div className="card-header back-color">
              <p className="mb-0">
                <a
                  href={`#${row.id}`}
                  className="collapsed d-flex justify-content-between a-style"
                  onClick={() => handleCollapseToggle(row.id)}
                >
                  <div className="p-0 m-0">
                    <strong>{`${t("event_name")}:`} </strong> {row.eventName}
                    <strong>{` | ${t("event_host")}`} </strong> {row.eventHost}
                    <strong>{` | ${t("event_date")}`} </strong>
                    {convertDateToUTC(row.eventDate)}
                  </div>
                  <strong> {`${t("id")} ${row.id}`}</strong>
                </a>
              </p>
            </div>
            <div
              id={`collapse${row.id}`}
              className={`collapse ${expandedCard === row.id ? "show" : ""}`}
            >
              <div className="card-body">
                {[
                  { label: t("event_host"), value: row.eventHost },
                  {
                    label: t("event_date"),
                    value: (() => {
                      const epochTimestamp = row.eventDate;
                      const date = new Date(epochTimestamp * 1000);

                      const year = date.getUTCFullYear();
                      const month = date.getUTCMonth() + 1; // Adding 1 since months are 0-indexed
                      const day = date.getUTCDate();

                      return `${month}/${day}/${year}`;
                    })(),
                  },
                  {
                    label: t("event_start_time"),
                    value: new Date(
                      row.eventStartTime * 1000
                    ).toLocaleTimeString(),
                  },
                  {
                    label: t("event_location"),
                    value: row.eventLocation[0]?.currentLocation,
                  },
                  {
                    label: t("duration"),
                    value: row.duration,
                  },
                  {
                    label: t("sb_start_time"),
                    value: new Date(
                      row.sbstartTime * 1000
                    ).toLocaleTimeString(),
                  },
                  {
                    label: t("event_type"),
                    value: row.eventType.name,
                  },
                  {
                    label: t("appartment"),
                    value: row.apartment,
                  },
                  {
                    label: t("additional_notes"),
                    value: row.additionalNotes,
                  },
                  row.contactName &&
                    row.contactName !== "" && {
                      label: t("contact_name"),
                      value: row.contactName,
                    },
                  row.phoneNumber &&
                    row.phoneNumber !== "" && {
                      label: t("contact_number"),
                      value: row.phoneNumber,
                    },
                  {
                    label: t("Assignee"),
                    value: row.sbList
                      ? row.sbList.trim().endsWith(",")
                        ? row.sbList.trim().slice(0, -1)
                        : row.sbList.trim()
                      : "",
                  },
                ]
                  .filter(Boolean)
                  .map(({ label, value }) => (
                    <div className="d-flex" key={label}>
                      <p className="text-left w-25 fw-bold">{label}</p>
                      <p className="w-75">{value}</p>
                    </div>
                  ))}
                {/*<button
                  disabled={!row.isActive}
                  type="submit"
                  className="btn btn-info"
                  onClick={() =>
                    props.cancelDraftEvent(
                      row.id,
                      pagination.pageSize,
                      pagination.pageNumber,
                      getFilterExpression(searchText)
                    )
                  }
                >
                  {t("cancel_event")}
                </button>*/}
                <button
                  disabled={!row.isActive}
                  type="submit"
                  className="btn btn-info"
                  onClick={() => {
                    openModal();
                    props.viewDraftEventShutterbs(row.id);
                    setEventId(row.id);
                  }}
                >
                  {t("Assign shutterb")}
                </button>
                <button
                  type="submit"
                  disabled={row.sbList === "" || row.sbList === null}
                  className="btn btn-info"
                  onClick={() =>
                    props.sendCartLinkToCustomer(
                      row.id,
                      pagination.pageSize,
                      pagination.pageNumber,
                      getFilterExpression(searchText)
                    )
                  }
                >
                  {t("Finish")}
                </button>
              </div>
            </div>
          </div>
        </React.Fragment>
      ),
      allowOverflow: true,
      button: true,
    },
  ];
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
      },
    },
    rows: {
      style: {
        "&:not(:last-of-type)": {
          borderBottomWidth: "0px",
        },
      },
    },
  };

  useEffect(() => {
    props.getDraftEvents(
      pagination.pageSize,
      pagination.pageNumber,
      getFilterExpression(searchText)
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPagination({
      pageSize: props.data.pageSize,
      pageNumber: props.data.currentPage,
      totalRows: props.data.total,
    });
  }, [props.data]);

  const userOptions = props.allShutterbs?.map((user) => ({
    value: user.id,
    label: `${user.firstName} ${user.lastName} - ${user.email}`,
  }));

  const handleUserSelection = (selectedOptions, index) => {
    setSelectedUsers((prevSelectedUsers) => {
      const updatedSelectedUsers = [...prevSelectedUsers];
      updatedSelectedUsers[index] = selectedOptions;
      return updatedSelectedUsers;
    });
  };

  useEffect(() => {
    const defaultSelectedShutterb = props.eventShutterbs.flatMap(
      (shutterb) => ({
        value: shutterb.sbuserId,
        label: `${shutterb.sbName} - ${shutterb.email}`,
      })
    );
    setSelectedUsers(defaultSelectedShutterb);
    // eslint-disable-next-line
  }, [props.eventShutterbs]);

  useEffect(() => {
    if (props.eventShutterbs && props.eventShutterbs.length > 1) {
      setNumRows(props.eventShutterbs.length);
    } else {
      setNumRows(1);
    }
    // eslint-disable-next-line
  }, [props.eventShutterbs]);

  const handleAssignShutterB = () => {
    const dataToSend = props.eventShutterbs.map((shutterb, index) => {
      const selectedUser = selectedUsers[index];

      return {
        eventId: eventId,
        oldSBUserId: shutterb.sbuserId,
        newSBUserId: selectedUser ? selectedUser.value : shutterb.newSBUserId,
      };
    });

    const filteredDataToSend = dataToSend.filter(
      (item) => item.newSBUserId && item.oldSBUserId !== item.newSBUserId
    );

    const newShutterBsAdded = selectedUsers
      .filter(
        (user) =>
          !dataToSend.some(
            (data) =>
              data.oldSBUserId === user.value || data.newSBUserId === user.value
          )
      )
      .map((user) => ({
        newSBUserId: user.value,
        oldSBUserId: 0,
        eventId: eventId,
      }));

    const combinedData = [...filteredDataToSend, ...newShutterBsAdded];

    if (combinedData.length > 0) {
      props.assignShutterbsToDraftEvent(
        eventId,
        { suggestedSBz: combinedData },
        pagination.pageSize,
        pagination.pageNumber,
        getFilterExpression(searchText),
        closeModal
      );
    } else {
      closeModal();
    }
  };

  return (
    <div className="row mr-0">
      <div className="col-md-12">
        <div className="col-lg-12">
          <div className="tab-content">
            <div className="tab-pane fade show active">
              {spinner(GET_DRAFT_EVENTS) &&
              spinner(SEND_CART_LINK_TO_CUSTOMER) &&
              spinner(CANCEL_DRAFT_EVENT) ? (
                <div>
                  <PaginationComponent
                    pagination={pagination}
                    setPagination={setPagination}
                    searchText={searchText}
                    setSearchText={setSearchText}
                    getTableData={props.getDraftEvents}
                    filterOptionValue={filterOptionValue}
                    setFilterOptionValue={setFilterOptionValue}
                    filterOptions={filterOptions}
                    isDateIncluded
                    date={date}
                    setDate={setDate}
                  />
                  <DataTable
                    columns={columns}
                    data={props.data.items}
                    responsive
                    customStyles={customStyles}
                    className="no-scroll"
                  />
                </div>
              ) : (
                <div className="text-center mt-4">
                  <LoadingIcon className="loading-spinner" />
                </div>
              )}
              <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Set Shutterbs Modal"
                className="image-modal"
                style={{
                  content: {
                    width: "auto",
                    height: "auto",
                    margin: "auto",
                  },
                }}
              >
                <div className="modal-content">
                  <h3 className="d-flex justify-content-center">
                    {t("Assign shutterbs")}
                  </h3>
                  {spinner(VIEW_DRAFT_EVENT_SHUTTERBS) &&
                  spinner(UNASSIGN_SHUTTERBS_TO_DRAFT_EVENT) &&
                  spinner(ASSIGN_SHUTTERBS_TO_DRAFT_EVENT) ? (
                    <>
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">Assignee</th>
                            <th scope="col">
                              <div onClick={handleIncrementRows}>
                                <i
                                  className="fa fa-plus cursor-pointer"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {Array.from({ length: numRows }).map((_, index) => (
                            <tr key={index}>
                              <td>
                                <Select
                                  options={userOptions}
                                  value={
                                    selectedUsers && selectedUsers.length > 0
                                      ? selectedUsers[index]
                                      : null
                                  }
                                  onChange={(selectedOptions) => {
                                    const selectedValue =
                                      selectedOptions?.value;
                                    const matchingUserIndex =
                                      selectedUsers.findIndex(
                                        (user) => user.value === selectedValue
                                      );
                                    if (matchingUserIndex === -1) {
                                      handleUserSelection(
                                        selectedOptions,
                                        index
                                      );
                                    } else if (matchingUserIndex !== index) {
                                      customToast(
                                        t(
                                          "shutterb already assigned for this event"
                                        ),
                                        toast.TYPE.ERROR
                                      );
                                      setSelectedUsers((prevSelectedUsers) => {
                                        const updatedSelectedUsers = [
                                          ...prevSelectedUsers,
                                        ];
                                        updatedSelectedUsers[index] = null;
                                        return updatedSelectedUsers;
                                      });
                                      const filteredSelectedUsers =
                                        selectedUsers.filter(
                                          (user) => user !== null
                                        );
                                      setSelectedUsers(filteredSelectedUsers);
                                    }
                                  }}
                                />
                              </td>
                              <td className="pt-custom">
                                <div onClick={() => handleDeleteRow(index)}>
                                  <i
                                    className="fa fa-minus cursor-pointer"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <button
                        type="submit"
                        className="btn btn-primary w-100"
                        onClick={handleAssignShutterB}
                      >
                        {t("Assign")}
                      </button>
                      <button onClick={closeModal} className="modal-close">
                        <img
                          src="/images/simpleCrossWhite.svg"
                          alt="crossIcon"
                          className="modal-close-img"
                        />
                      </button>
                    </>
                  ) : (
                    <div className="text-center mt-4 h-150">
                      <LoadingIcon className="loading-spinner" />
                    </div>
                  )}
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  data: state.draftEvents.data,
  spinnerActions: state.system.spinnerActions,
  allShutterbs: state.configurations.allShutterbs,
  eventShutterbs: state.draftEvents.eventShutterbs,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getDraftEvents: (pageSize, pageNumber, filterExpression) =>
    dispatch(
      draftEventsActions.getDraftEvents(pageSize, pageNumber, filterExpression)
    ),
  cancelDraftEvent: (id, pageSize, pageNumber, filterExpression) =>
    dispatch(
      draftEventsActions.cancelDraftEvent(
        id,
        pageSize,
        pageNumber,
        filterExpression
      )
    ),
  getAllShutterBsForReels: () =>
    dispatch(configurationsActions.getAllShutterBsForReels()),
  viewDraftEventShutterbs: (id) =>
    dispatch(draftEventsActions.viewDraftEventShutterbs(id)),
  assignShutterbsToDraftEvent: (
    eventId,
    data,
    pageSize,
    pageNumber,
    filterExpression,
    closeModal
  ) =>
    dispatch(
      draftEventsActions.assignShutterbsToDraftEvent(
        eventId,
        data,
        pageSize,
        pageNumber,
        filterExpression,
        closeModal
      )
    ),
  unAssignShutterbToDraftEvent: (
    id,
    pageSize,
    pageNumber,
    filterExpression,
    numRows,
    setNumRows
  ) =>
    dispatch(
      draftEventsActions.unAssignShutterbToDraftEvent(
        id,
        pageSize,
        pageNumber,
        filterExpression,
        numRows,
        setNumRows
      )
    ),
  sendCartLinkToCustomer: (id, pageSize, pageNumber, filterExpression) =>
    dispatch(
      draftEventsActions.sendCartLinkToCustomer(
        id,
        pageSize,
        pageNumber,
        filterExpression
      )
    ),
});

// Mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DraftEvents));
