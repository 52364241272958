export const GLOBAL_STATUS = {
  SUCCESS: 0,
  ERROR: 1,
  UNAUTHORIZED: 2,
};

export const SERVER_TRANSLATION_KEYS = [
  "invalid_login",
  "unable_to_access_server",
  "internal_server_error",
];
