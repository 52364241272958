import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import LoadingIcon from "mdi-react/LoadingIcon";
import { customersActions } from "../../redux/actions";
import { configurationsConstants, customersConstants } from "../../constants";
import DataTable from "react-data-table-component";

import PaginationComponent from "../../shared/components/PaginationComponent/PaginationComponent";
import { getFilterExpression } from "../../shared";

const { GET_CUSTOMERS, ACTIVATE_DEACTIVATE_CUSTOMER } = customersConstants;
const Customers = (props) => {
  const { t } = useTranslation("common");
  const [expandedCard, setExpandedCard] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({
    pageSize: configurationsConstants.DEFAULT_PAGINATION_PAGE_SIZE,
    pageNumber: 1,
    totalRows: 0,
  });
  const [filterOptionValue, setFilterOptionValue] = useState("");
  const filterOptions = ["email", "firstName"];

  const spinner = useCallback(
    (type) => props.spinnerActions.findIndex((f) => f === type),
    [props.spinnerActions]
  );

  const handleCollapseToggle = (cardId) => {
    setExpandedCard(cardId === expandedCard ? null : cardId);
  };

  const columns = [
    {
      width: "100%",
      cell: (row) => (
        <React.Fragment key={row.id}>
          <div className="card card-style pagination-card-width mb-2">
            <div className="card-header back-color">
              <p className="mb-0">
                <a
                  href={`#${row.id}`}
                  className="collapsed d-flex justify-content-between a-style"
                  onClick={() => handleCollapseToggle(row.id)}
                >
                  {`${t("event_host")} ${row.firstName} ${row.lastName}`}
                  <strong> {`${t("id")} ${row.id}`}</strong>
                </a>
              </p>
            </div>
            <div
              id={`collapse${row.id}`}
              className={`collapse ${expandedCard === row.id ? "show" : ""}`}
            >
              <div className="card-body">
                {[
                  {
                    label: t("event_host"),
                    value: row.firstName + " " + row.lastName,
                  },
                  {
                    label: t("email"),
                    value: row.userName,
                  },
                ].map(({ label, value }) => (
                  <div className="d-flex" key={label}>
                    <p className="text-left w-25 fw-bold">{label}</p>
                    <p className="w-75">{value}</p>
                  </div>
                ))}
                <div className="mt-n3 pb-3 d-flex justify-content-end">
                  <label
                    className="toggle-btn ml-0 pt-6 pb-6 ml-n3"
                    htmlFor={`activateDeactivateCustomer_toggle_${row.id}`}
                  >
                    <input
                      className="toggle-btn__input ml-minus"
                      type="checkbox"
                      name={`activateDeactivateCustomer_toggle_${row.id}`}
                      id={`activateDeactivateCustomer_toggle_${row.id}`}
                      checked={row.isActive}
                      onChange={() => props.activateDeactivateCustomer(row.id)}
                    />
                    <span className="toggle-btn__input-label switch__button ml-3 mr-3"></span>
                    <span className="toggle__label mr-0 custom-toggle-label">
                      {row.isActive ? t("deactivate") : t("activate")}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      ),
      allowOverflow: true,
      button: true,
    },
  ];
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
      },
    },
    rows: {
      style: {
        "&:not(:last-of-type)": {
          borderBottomWidth: "0px",
        },
      },
    },
  };

  useEffect(() => {
    props.getCustomers(
      pagination.pageSize,
      pagination.pageNumber,
      getFilterExpression(searchText)
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPagination({
      pageSize: props.data.pageSize,
      pageNumber: props.data.currentPage,
      totalRows: props.data.total,
    });
  }, [props.data]);

  return (
    <div className="row mr-0">
      <div className="col-md-12">
        <div className="col-lg-12">
          <div className="tab-content">
            <div className="tab-pane fade show active">
              {spinner(GET_CUSTOMERS) &&
              spinner(ACTIVATE_DEACTIVATE_CUSTOMER) ? (
                <div>
                  <PaginationComponent
                    pagination={pagination}
                    setPagination={setPagination}
                    searchText={searchText}
                    setSearchText={setSearchText}
                    getTableData={props.getCustomers}
                    filterOptionValue={filterOptionValue}
                    setFilterOptionValue={setFilterOptionValue}
                    filterOptions={filterOptions}
                  />
                  <DataTable
                    columns={columns}
                    data={props.data?.items}
                    responsive
                    customStyles={customStyles}
                    className="no-scroll"
                  />
                </div>
              ) : (
                <div className="text-center mt-4">
                  <LoadingIcon className="loading-spinner" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  data: state.customers.data,
  spinnerActions: state.system.spinnerActions,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getCustomers: (pageSize, pageNumber, filterExpression) =>
    dispatch(
      customersActions.getCustomers(pageSize, pageNumber, filterExpression)
    ),
  activateDeactivateCustomer: (id) =>
    dispatch(customersActions.activateDeactivateCustomer(id)),
});

// Mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Customers));
